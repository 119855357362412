import axiosInstance from './axiosinstance';
import { createContext, useContext, useState, useCallback } from 'react';
import { ipapi } from '../url';
import { handleApiError } from './common';
import WithToast from './withtoast'; // Assuming WithToast.js is properly defined

const ipapiurl = ipapi();
const IpContext = createContext();

export const useIpContext = () => {
  return useContext(IpContext);
};

export const IpProvider = WithToast(({ children, showErrorToast }) => { // Passing showErrorToast directly to IpProvider
  const [ipCountryCode, setIpCountryCode] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLoading4, setIsLoading4] = useState(false);

  const getUserIpCountryCode = useCallback(async () => {
    setIsLoading4(true);
    try {
      const response = await axiosInstance.get(ipapiurl);
      if (response.status === 200) {
        const countryCode = response.data.data.country;
        const loc = response.data.data.loc;
        const splitloc = loc.split(',');
        setLatitude(parseFloat(splitloc[0]));
        setLongitude(parseFloat(splitloc[1]));
        if (countryCode) {
          setIpCountryCode(countryCode);
        } else {
          setIpCountryCode('UG');
        }
      } else {
        setIpCountryCode('UG');
      }
    } catch (error) {
      setIpCountryCode('UG');
      handleApiError(error, setIsLoading4, null, false, showErrorToast);
    } finally {
      setIsLoading4(false);
    }
  }, [showErrorToast]);

  const value = {
    ipCountryCode,
    latitude,
    longitude,
    getUserIpCountryCode, // Exposing the function through context
  };

  return <IpContext.Provider value={value}>{children}</IpContext.Provider>;
});

export default IpProvider;
