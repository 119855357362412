import React from "react";
import edit from '../images/edit_1-01.png';
function Walletgeneralid(){
    return(
        <>
         <div className="walletprofileblue mt-4 p-4">
            <div className="d-flex justify-content-end align-items-center">
              <img src={edit} width='19.5px'className="me-2" height='19.5px'></img>
              <p className="wallletprofileeditp mb-0">Edit</p>
            </div>
            <div className="mt-4">
                        <div className="row">
                         <div className="col-6">
                            <label>Recovery Email</label>
                            <div className="box">
                            <p className="walletprofile-boxp">Stalin@gmail.com</p>
                            </div>
                         </div>
                         <div className="col-6">
                         <label>Recovery Mobile Number</label>
                         <div className="box">
                         <p className="walletprofile-boxp">+256 7558196453</p>
                         </div>
                         </div>
                        </div>
                      </div>
            </div>
        </>
    )
}
export default Walletgeneralid;