import React, { memo,useState,useCallback,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import WithToast from '../../api/commonfunct.js/withtoast';
import closeicon from '../../images/times-circle-01.png';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { handleApiError, handleKeyDown, handleKeyPress } from '../../api/commonfunct.js/common';
import Loader from '../../loader';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import { updatecardsapi } from '../../api/url';
const updatecardsapiurl = updatecardsapi();
const Editcardmodal = memo(({show,handleClose,getcardall,mapvalues,showErrorToast})=> {
    const [formValues,setFormValues] = useState({cardnumber:'',expireMonth:'',expireYear:'',cvv:'',cardholdername:'',cardType: 'debit',dateofissue:'',id:''});
    const [enabled,setEnabled] = useState(false);
    const [isLoading4,setIsLoading4] = useState(false);
    const [error422,setError422] = useState('');
    useEffect(()=>{
        if(mapvalues){
          setFormValues((prev)=>({
            ...prev,
            cardnumber:mapvalues.card_number || '',
            cardholdername:mapvalues.cardholder_name || '',
            id:mapvalues.id || '',
            cardType:mapvalues.card_type || '',
            cvv:mapvalues.cvv || '',
            expireMonth:mapvalues.expired_month || '',
            expireYear:mapvalues.expired_year || '',
            dateofissue: mapvalues.expired_month && mapvalues.expired_year ? dayjs(`${mapvalues.expired_year}-${mapvalues.expired_month}`) : '',
          }))
        }
    },[mapvalues])
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setError422('');
    };
    const handleRadioChange = (e) => {
      setFormValues((prevValues) => ({
        ...prevValues,
        cardType: e.target.value, 
      }));
      setError422('');
    };
    const handlepaste = (e) => {
      const pastedText = e.clipboardData.getData('text/plain');
      const isNumeric = /^\d*$/.test(pastedText);
      if (!isNumeric) {
        e.preventDefault();
      } else {
        setFormValues((prev) => ({
          ...prev, cvv: pastedText
        }));
      }
      setError422('');
    };
    const handleDateChange = (date) => {
      setFormValues((prev)=>({
          ...prev,dateofissue:date
      }));
      setError422('');
      if (date) {
          const month = dayjs(date).format('MM');
          const year = dayjs(date).format('YYYY');
        setFormValues((prev) => ({
          ...prev,
          expireMonth: month,
          expireYear: year,
        }));
      }
    };
  
    const resetFormValues = () => {
      setFormValues({
        cardnumber: '',
        expireMonth: '',
        expireYear: '',
        cvv: '',
        cardholdername: '',
        cardType: 'debit', 
        dateofissue:''
      });
      setError422('');
    };
    const handleClose1 = () => {
      handleClose();
      resetFormValues();
    }
    const check = useCallback(() => {
      const valid = (
       formValues.cardnumber !== '' && formValues.dateofissue !== '' && formValues.cvv !== '' && formValues.cardholdername !== '' && formValues.cardType !== ''
      )
      setEnabled(valid);
    },[formValues.cardnumber,formValues.cardholdername,formValues.dateofissue,formValues.cvv,formValues.cardType]);
    useEffect(()=>{
      check();
    },[check]);
    const save = async()=>{
      const data = {
          card_id : formValues.id,
          card_type : formValues.cardType,
          card_number : formValues.cardnumber,
          expired_month : parseInt(formValues.expireMonth),
          expired_year : parseInt(formValues.expireYear),
          cardholder_name : formValues.cardholdername,
          cvv : formValues.cvv
      }
      try{
          setIsLoading4(true);
          const response = await axiosInstance.post(updatecardsapiurl,data);
          if(response.status === 200){
              getcardall();
              handleClose1();
              setIsLoading4(false);
          }
      }catch(error){
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      }
    }
  return (
    <>
     {isLoading4 && <Loader/>}
      <Modal show={show} dialogClassName="card-example-dialog" contentClassName="card-example-content" onHide={handleClose1} style={{paddingRight:'0px'}} centered>
        <Modal.Body className='card-example-dialog-body'>
        <div className="text-end">
            <img src={closeicon} onClick={handleClose1} style={{cursor:'pointer'}} className="closeicon mx-1 mt-2" alt="closeicon" ></img>
         </div>
         <div className='card-example-dialog-bodypadd'>
         <h5 className="addnewh5 ">Add new card</h5>
         <div className="d-flex align-items-center cardscards mt-3">
        <div className="d-flex align-items-center me-3">
            <input type="radio" id="debitRadioId" className='creditcard me-2' value="debit" checked={formValues.cardType === 'debit'} onChange={handleRadioChange} name="yourRadioGroupName" />
            <label htmlFor="debitRadioId" className="debitcardlabel">Debit Card</label>
        </div>
        <div className="d-flex align-items-center">
            <input type="radio" id="creditRadioId" name="yourRadioGroupName" className="me-2" value="credit" checked={formValues.cardType === 'credit'} onChange={handleRadioChange} />
            <label htmlFor="creditRadioId" className="debitcardlabel">Credit Card</label>
        </div>
        </div>
        <div className='mt-3'>
        <label htmlFor="cardNumber" className="cardlabel">Card Number:</label>
        <input type="text" id="cardNumber" placeholder='Enter Card Number' maxLength={16} onKeyPress={handleKeyPress} className={`form-control cardinputbox ${formValues.cardnumber ? 'cardinputbox_active' : ''}`} value={formValues.cardnumber} onChange={handleChange} name="cardnumber"  />
        </div>
        <div className='row mt-2'>
            <div className='col-6'>
            <label htmlFor="dateofissues" className="cardlabel">Expire Date</label>
            <DatePicker id='dateofissues' onKeyDown={handleKeyDown} value={formValues.dateofissue}  onChange={handleDateChange} picker="month" placeholder="MM/YY" className={`${formValues.dateofissue ? 'ant-picker-active' : 'ant-picker'} modaldate`} style={{width:'100%',height:'40px',borderColor: formValues.dateofissue ? 'black' : '#9B9898',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'6px'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={null}/>
            </div> 
            <div className='col-6'>
            <label htmlFor="cvv" className="cardlabel">CVV</label>
            <input type='text' id='cvv' value={formValues.cvv} onPaste={handlepaste} onChange={handleChange} placeholder='CVV' maxLength={3} className={`form-control cardinputbox ${formValues.cvv ? 'cardinputbox_active' : ''}`} name="cvv" onKeyPress={handleKeyPress}></input>
            </div>
        </div>
        <div className='mt-2'>
        <label htmlFor="cardholdername" className="cardlabel">Cardholder Name</label>
        <input type='text' id='cardholdername' value={formValues.cardholdername} onChange={handleChange} placeholder='Enter Cardholder Name'  className={`form-control cardinputbox ${formValues.cvv ? 'cardinputbox_active' : ''}`} name="cardholdername"></input>
        </div>
        {error422 && <p className="error-message">{error422}</p>}
        <div className='mt-4'>
          <button className={`modal_save_card ${enabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!enabled} onClick={save}>Save</button>
        </div>
         </div>
        </Modal.Body>
      </Modal>
    </>
  )
})

export default WithToast(Editcardmodal);


