import React, { useEffect, useState, useRef,useCallback } from "react";
import "./navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import navlogo from "./images/evzone-pay-logo-01.png";
import user from "./images/user-3.jpg";
import bell from "./images/bell-01.png";
import apps1 from "./images/school_orange-01.png";
import apps from './images/school_green-01.png';
import exports from './images/export-01.png';
import Modal from "react-bootstrap/Modal";
import { handleApiError, useRouteParams } from "./api/commonfunct.js/common";
import WithToast from "./api/commonfunct.js/withtoast";
import Walletpopimg from './images/wallet/Wallet.png';
import { getkycapi,addkycapi } from "./api/url";
import axiosInstance from "./api/commonfunct.js/axiosinstance";
// const refreshurl = refreshapi();
const getkycapiurl = getkycapi();
const addkycapiurl = addkycapi();
function Navbars({showErrorToast}) {
  const navigate = useNavigate();
  const params = useRouteParams();
  const location = useLocation();
  const [isLoading4,setIsLoading4 ]= useState(false);
  const [modalstate,setModalstate] = useState({modal1 : false,modal2 : false,});
  const getkyc = useCallback(async () => {
    try {
      // setIsLoading4(true);
      const response = await axiosInstance.get(getkycapiurl);
      if (response.status === 200) {
        const kyc = response.data.data;
        if (kyc === null || kyc.is_completed === 0 || (Array.isArray(kyc) && kyc.length === 0) ) {
          setModalstate(prev => ({
            ...prev,
            modal1: true,
          }));
        }
        // setIsLoading4(false);
      }
    } catch (error) {
      handleApiError(error, null, null, true, showErrorToast);
    }
  }, [navigate, params]);

  useEffect(() => {
    if (location.pathname.includes('wallet/profile-setups')) {
      return;
    }else{
      getkyc();
    }
  }, [location.pathname, getkyc]);
  const start = async() => {
    try{
      setIsLoading4(true);
      const response = await axiosInstance.get(addkycapiurl);
      if(response.status === 200){
        setIsLoading4(false);
        localStorage.setItem('activeStep',0);
        navigate(`/u/${params}/wallet/profile-setups`);
      }
    }catch(error){
       handleApiError(error, setIsLoading4, null, true, showErrorToast);
    }
  }
  const [isAllAppsOpen, setIsAllAppsOpen] = useState(false);

  const allapps = () => {
    setIsAllAppsOpen(!isAllAppsOpen);
  };
  const [isAppRowVisible, setIsAppRowVisible] = useState(false);

  const toggleAppRow = () => {
    setIsAppRowVisible(!isAppRowVisible);
  };
  const [isJobPostingsChecked, setIsJobPostingsChecked] = useState(false);

  const handleJobPostingsCheckbox = () => {
    setIsJobPostingsChecked(!isJobPostingsChecked);
    toggleAppRow();
  };
  const [isAccountdetailsOpen, setIsAccountdetailsOpen] = useState(false);
  const [isDropdownOpenDueToClick, setIsDropdownOpenDueToClick] = useState(false);
  const accountsdetails = () => {
    setIsAccountdetailsOpen(!isAccountdetailsOpen);
    setIsDropdownOpenDueToClick(true);
  }
  const userProfileImageRef = useRef(null);
  const accountDetailsRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountDetailsRef.current &&
        userProfileImageRef.current &&
        !accountDetailsRef.current.contains(event.target) &&
        !userProfileImageRef.current.contains(event.target)
      ) {
        setIsAccountdetailsOpen(false); // Close the dropdown when clicking outside
      }
    };

    // Add a mousedown event listener to the window
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isAccountdetailsOpen) {
        setIsAccountdetailsOpen(false);
      }
    };

    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAccountdetailsOpen]);

  return (
    <>
     {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
      <nav className="navbar">
        <div className="navbar-left">
          <img src={navlogo} alt="Left "  loading="lazy"  />
        </div>
        <div className="navbar-right">
          <img
            src={bell}
            alt="Right  1"
            className="nav-bell"
            style={{ marginRight: "20px", minWidth: "21.54px", maxHeight: "24px",marginTop:'20px'}}
          />
          {/* <img
            id="login_img_for_profile"
            src={userProfile?.image}
            className="rounded-circle nav-user header-profile-user border-dark"
            // className={`user-image ${imageClassName}`}
            style={{ width: "45px", marginRight: "5px", height: "40px"}}
            onClick={accountsdetails}
            ref={userProfileImageRef}
          /> */}
          {/* <div className="user-details">
            <p className="user-name" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000',marginBottom:'0px' }}>{userProfile?.name}</p>
            <img
              src={downArrowIcon}
              alt="Down Arrow"
              className="down-arrow-icon"
              width='15.5px'
              ref={userProfileImageRef}
              height='8.5px'
              onClick={accountsdetails}
            />
          </div> */}
        </div>
        {isAllAppsOpen && (
          <div className="all-apps-container">
            <label className="userapp-label mb-1" style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>User App</label>
            <div className="apps-row">

              <div className="userapp-item apps">
                <img src={apps} alt="App 1" width="32px" height="32px" />
                <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>School</p>
              </div>
              <div className="userapp-item apps">
                <img src={apps} alt="App 2" width="32px" height="32px" />
                <p className="evmarket" style={{ position: 'absolute', fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>EvMarke...</p>
                <p className="evplace" style={{ fontWeight: '400', fontSize: '12px', color: '#5E5E5E' }}>EvMarket Place</p>
              </div>
              <div className="userapp-item apps">
                <img src={apps} alt="App 3" width="32px" height="32px" />
                <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>FaithHub</p>
              </div>
            </div>
            <hr className="divider mt-3"></hr>
            <label className="userapp-label mb-1" style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Provider App</label>
            <div className="apps-row">
              <div className="userapp-item apps1">
                <img src={apps1} alt="App 4" width="32px" height="32px" />
                <p className="educations" style={{ position: 'absolute', fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>Educat...</p>
                <p className="education" style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>Education</p>
              </div>
              <div className="userapp-item apps1">
                <img src={apps1} alt="App 5" width="32px" height="32px" />
                <p className="ecommerces" style={{ position: 'absolute', fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>E_Comm...</p>
                <p className="ecommerce" style={{ fontWeight: '400', fontSize: '12px', color: '#5E5E5E' }}>E_Commerce Place</p>
              </div>
              <div className="userapp-item apps1">
                <img src={apps1} alt="App 6" width="32px" height="32px" />
                <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>FaithHub</p>
              </div>
            </div>
            <hr className="divider mt-3"></hr>
            <label className="userapp-label mb-1" style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Web Services</label>
            <div className="apps-row">
              <div className="userapp-item apps">
                <img src={apps} alt="App 7" width="32px" height="32px" />
                <p style={{ position: 'absolute', fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }} className="webeducations">Educat....</p>
                <p className="webeducation" style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>Educations</p>
              </div>
              <div className="userapp-item apps" >
                <img src={apps} alt="App 8" width="32px" height="32px" />
                <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>School</p>
              </div>
              <div className="userapp-item">
                {/* Leave empty div if there is no 3rd app */}
              </div>
            </div>
            <hr className="divider mt-3"></hr>
            <div className="last-label-row">
              <label className={`userapp-label mb-1 ${isJobPostingsChecked ? 'job-postings-checked' : ''}`}
                onClick={handleJobPostingsCheckbox} style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#999494' }} >Job Postings</label>
              <input type="checkbox" checked={isJobPostingsChecked} onChange={handleJobPostingsCheckbox} />
            </div>
            {isAppRowVisible && (
              <div className="apps-row">
                <div className="userapp-item apps">
                  <img src={apps} alt="App 7" width="32px" height="32px" />
                  {/* <p style={{ position: 'absolute' }} className="webeducations">Educat....</p> */}
                  <p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '28.8px', color: '#5E5E5E' }}>Pilot</p>
                </div>
                <div className="userapp-item apps" >

                </div>
                <div className="userapp-item">
                </div>
                <hr className="divider" />
              </div>
            )}


          </div>
        )}
        {isAccountdetailsOpen && (
          <div className="box accountdetails" ref={accountDetailsRef} style={{ width: '100%', maxWidth: '349px', minHeight: '388px', border: '1px solid #EAEDF2', backgroundColor: '#FFFFFF', borderRadius: '28px' }}>
            <div className="box accountdetailsbox1">
              <div className="d-flex  align-items-center  ">
                <div className='col-md-4 mb-1 ' style={{ flexBasis: '25%' }} >
                  <img
                    src={user}
                    className="rounded-circle header-profile-user border-dark text-start"
                    style={{ width: "56px", height: "56px" }}
                    alt="Right 3"
                  />
                </div>
                <div className='col-md-4  text-start ' style={{ flexBasis: '50%' }}>
                  <p className="mb-1 mt-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>John Doe</p>
                  <p style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#636363' }}>Johndoe07@gmail.com</p>
                </div>
                <div className='col-md-4  mt-0 mb-0 d-flex justify-content-end' style={{ flexBasis: '25%' }}>
                  <img style={{ cursor: 'pointer', width: '19.5px', height: '19.5px' }} src={exports} alt="edit"></img>
                </div>
              </div>
              <div className="text-center ps-5">
                <button className="accountsettings text-center">Account Settings</button>
              </div>
            </div>
            <div className="box accountdetailsbox2" >
              <div className="d-flex  align-items-center  ">
                <div className='col-md-4 mb-1 ' style={{ flexBasis: '20%' }} >
                  <img
                    src={user}
                    className="rounded-circle header-profile-user border-dark text-start"
                    style={{ width: "32px", height: "32px" }}
                    alt="Right 3"
                  />
                </div>
                <div className='col-md-4  text-start ' style={{ flexBasis: '50%' }}>
                  <p className="mb-1 mt-2" style={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px', color: '#000000' }}>John Doe</p>
                  <p style={{ fontWeight: '400', fontSize: '10px', lineHeight: '15px', color: '#636363' }}>Johndoe07@gmail.com</p>
                </div>
                <div className='col-md-4  mt-0 mb-0 d-flex justify-content-end' style={{ flexBasis: '30%' }}>
                  <img style={{ cursor: 'pointer', width: '19.5px', height: '19.5px' }} src={exports} alt="edit"></img>
                </div>
              </div>
              <hr className="mt-0 mb-0" style={{ color: '#A6A4A4' }}></hr>
              <div className="d-flex  align-items-center  ">
                <div className='col-md-4 mb-1 ' style={{ flexBasis: '20%' }} >
                  <img
                    src={user}
                    className="rounded-circle header-profile-user border-dark text-start"
                    style={{ width: "32px", height: "32px" }}
                    alt="Right 3"
                  />
                </div>
                <div className='col-md-4  text-start ' style={{ flexBasis: '50%' }}>
                  <p className="mb-1 mt-2" style={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px', color: '#000000' }}>John Doe</p>
                  <p style={{ fontWeight: '400', fontSize: '10px', lineHeight: '15px', color: '#636363' }}>Johndoe07@gmail.com</p>
                </div>
                <div className='col-md-4  mt-0 mb-0 d-flex justify-content-end' style={{ flexBasis: '30%' }}>
                  <img style={{ cursor: 'pointer', width: '19.5px', height: '19.5px' }} src={exports} alt="edit"></img>
                </div>
              </div>
              <hr className="mt-0 mb-4" style={{ color: '#A6A4A4' }} />
              <div className="d-flex mb-2">
                <img className="me-3" src={exports} alt="exports" width='19.5px' height='19.5px'></img>
                <p style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Sign out from all accounts</p>
              </div>
            </div>
          </div>
        )}
      </nav>

      <Modal backdropClassName="custom-backdrop-class" show={modalstate.modal1} size="lg"   centered>
      <Modal.Body style={{ margin: "0", padding: "0" }}>
       <div className="d-flex justify-content-center flex-column align-items-center walletimgpopdiv">
        <img src={Walletpopimg} alt="wallet" className="walletimgpop"></img>
        <h6 className="walletimgpoph6">Wallet Profile Set Up</h6>
        <div className="text-center">
          <p className="walletimgpopp">Welcome to our secure online wallet! To ensure the safety of your transactions and protect your account, Please complete your profile by providing essential information. This information helps us verify your identity and enhances the security of your wallet. Rest assured that we prioritize the confidentiality of your personal information. Thank you for your cooperation in creating a safe and seamless financial experience with us.</p>
          <button onClick={start} className="walletimgpoppstart">Start</button>
        </div>
       </div>
      </Modal.Body>  
      </Modal>
    </>
  );
}

export default WithToast(Navbars);
