import React, { useState } from "react";
import Registerservices from "./registerservices";
import Navbars from "./navbar";
import Modal from 'react-bootstrap/Modal';
import closeicon from './images/times-circle-01.png';
import plus from './images/plus.png';
import registerservicce from './images/registered_services-01.png';
function Cards(){
    const [show10 , setShow10] = useState(false);
    const [cardnumber , setCardnumber] = useState('');
    const [cardexpiry , setCardexpiry] = useState('');
    const [cardcvv , setCardcvv] = useState('');
    const [cardname , setCardname] = useState('');
    const addnewcard = () => {
        setShow10(true);
    }
    const handleCloses10 = () => {
        setShow10(false);
        setCardnumber('');
        setCardexpiry('');
        setCardcvv('');
        setCardname('');
    }
    const closedialog10 = () => {
        setShow10(false);
        setCardnumber('');
        setCardexpiry('');
        setCardcvv('');
        setCardname('');
    }
    
    const getButtonClass = () => {
        return cardnumber && cardexpiry && cardcvv && cardname > '' ? 'addnewsave addnewsave1' : 'addnewsave';
    }
    const [show11 , setShow11] = useState(false);
    const handleCloses11 = () => {
        setShow11(false);
    }
    const closedialog11 = () => {
        setShow11(false);
    }
    const save = () => {
        setShow10(false);
        setCardnumber('');
        setCardexpiry('');
        setCardcvv('');
        setCardname('');
    }
    return(
        <> 
        {/* <Navbars/>
        <div className="container-fluid " style={{backgroundColor:'#F8F8F8',width:'100%',height:'100%'}}>
            <div className="row pt-4">
                <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                    <Registerservices/>
                </div> */}
                <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" >
                    <main className="cardsmaindiv" style={{backgroundColor:'#FFFFFF',width:'100%',boxShadow:"0px 0px 5px 0px #0000001A",height:'100%',minHeight:'400px'}}> 
                    <h5 className="ps-4 pt-4 registerservicecard">Cards</h5>
                       <p className="cardsp registerservicecard ps-4 mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr" style={{width:'100%',margin:'0 2.5%',maxWidth:'95%',minHeight:'400px',border:'1px solid #7D7878', }}>
                            <div className="d-flex justify-content-between align-items-center mt-4 mx-4">
                            <h5 style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#494949'}}>Credit Cards</h5>
                            <button className="addnewcard d-none" onClick={addnewcard}><img src={plus} className="me-2" alt="plus" width='15px' height='15px'></img>Add new card</button>
                            </div>
                            <div className="d-flex flex-column align-items-center regdiv justify-content-center">
                                <img src={registerservicce} alt="registerservice" className="registerimage" ></img>
                                <p className="registerpcard">Please add your first Card to start making Card transfers</p>
                                <button className="addnewcard1 mb-3" onClick={addnewcard}><img src={plus} className="me-2" alt="plus" width='15px' height='15px'></img>Add new card</button>
                            </div>
                       </div>
                    </main>
                </div>
            {/* </div>
           </div> */}
           <Modal show={show10} dialogClassName="example-dialog10" contentClassName="example-content10" onHide={handleCloses10} centered>
                <Modal.Body style={{ margin: '0', padding: '0'}}>
                <div className="text-end">
                        <img src={closeicon} onClick={closedialog10} style={{cursor:'pointer'}} className="closeicon mx-1 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewh5 ps-4">Add new card</h5>
                    </div>
                    <div className="d-flex align-items-center ps-4 ms-2 cardscards">
                        <div className="d-flex align-items-center me-3">
                            <input type="radio" id="debitRadioId" className='creditcard me-2' name="yourRadioGroupName" />
                            <label htmlFor="debitRadioId" className="me-2 debitcardlabel">Debit Card</label>
                        </div>
                        
                        <div className="d-flex align-items-center">
                            <input type="radio" id="creditRadioId" name="yourRadioGroupName" className="me-2" />
                            <label htmlFor="creditRadioId" className="creditcardlabel">Credit Card</label>
                        </div>
                    </div>
                    <div className="mt-3 mb-3" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Card Number:</label>
                        <input type="text" id="cardNumber" value={cardnumber} onChange={(e) => setCardnumber(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="row" style={{margin:'0 5.5%'}} >
                        <div className="col-6 ">
                        <label htmlFor="cardNumber"  className="cardlabel">Expire Date</label>
                        <input type="text" id="cardNumber" value={cardexpiry} onChange={(e) => setCardexpiry(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                        </div>
                        <div className="col-6">
                        <label htmlFor="cardNumber" className="cardlabel">CVV</label>
                        <input type="text" id="cardNumber" value={cardcvv} onChange={(e) => setCardcvv(e.target.value)} name="cardNumber" className="form-control cardinputbox" /> 
                        </div>
                    </div>
                    <div className="mt-3 mb-4" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Cardholder Name</label>
                        <input type="text" id="cardNumber" value={cardname} onChange={(e) => setCardname(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className=" mb-2" style={{margin:'0 8%'}}>
                        <button className={getButtonClass()} onClick={save}>Save</button>
                    </div>

                </Modal.Body> 
            </Modal>
            <Modal show={show11} dialogClassName="example-dialog10" contentClassName="example-content10" onHide={handleCloses11} centered>
                <Modal.Body style={{ margin: '0', padding: '0'}}>
                <div className="text-end">
                        <img src={closeicon} onClick={closedialog11} style={{cursor:'pointer'}} className="closeicon mx-1 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewh5 ps-4">Add new card</h5>
                    </div>
                    <div className="d-flex align-items-center ps-4 ms-2 cardscards">
                        <div className="d-flex align-items-center me-3">
                            <input type="radio" id="debitRadioId" className='creditcard me-2' name="yourRadioGroupName" />
                            <label htmlFor="debitRadioId" className="me-2 debitcardlabel">Debit Card</label>
                        </div>
                        
                        <div className="d-flex align-items-center">
                            <input type="radio" id="creditRadioId" name="yourRadioGroupName" className="me-2" />
                            <label htmlFor="creditRadioId" className="creditcardlabel">Credit Card</label>
                        </div>
                    </div>
                    <div className="mt-3 mb-3" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Card Number:</label>
                        <input type="text" id="cardNumber" name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="row" style={{margin:'0 5.5%'}} >
                        <div className="col-md-6">
                        <label htmlFor="cardNumber" className="cardlabel">Expire Date</label>
                        <input type="text" id="cardNumber" name="cardNumber" className="form-control cardinputbox" />
                        </div>
                        <div className="col-md-6">
                        <label htmlFor="cardNumber" className="cardlabel">CVV</label>
                        <input type="text" id="cardNumber" name="cardNumber" className="form-control cardinputbox" />   
                        </div>
                    </div>
                    <div className="mt-3 mb-4" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Cardholder Name</label>
                        <input type="text" id="cardNumber" name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div style={{margin:'0 8%'}}>
                        <button className="addnewsave">Confirm</button>
                    </div>

                </Modal.Body> 
            </Modal>
        </>
    );
}
export default Cards;