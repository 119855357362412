import { React, useState } from "react";
import Navbars from "./navbar";
import Systemsidebar from "./systemsidebar";
import plus from "./images/ApplePay.png";
import circle from "./images/circle-white.svg";
import schoolbus from "./images/Wallet-icons/School-bus.svg";
import student from "./images/Wallet-icons/Student-location-tracking.svg";
import recordsess from "./images/Wallet-icons/Record-sessions.svg";
function Subscription() {
  const [isOn, setIsOn] = useState(false);
  const toggle = () => {
    setIsOn(!isOn);
  };
  return (
    <>
      {/* <Navbars />
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row collectionrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Systemsidebar />
                    </div> */}
      <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1">
        <main
          className="mobile_height "
          style={{
            backgroundColor: "#FFFFFF",
            width: "100%",
            boxShadow: "0px 0px 5px 0px #0000001A",
            minHeight: '100vh',
            paddingBottom:'10px'
          }}
        >
          <div className="max-width">
            <h5 className="ps-4 pt-4">Subscriptions</h5>
            <p className="cardsp   ps-4 mb-4">
              This is a system "C Transfer" that happens if the user automates
              payment to a particular service and expects it to repeat after a
              period of time
            </p>
            <div className="massage_box_corr mobile_maxheight ">
              <div className="purchaserow22 mar-20 after-one ">
                {/* <div className="d-flex justify-content-between align-items-center dis__coo">
                                        <h5 className="productname1h5">School : Student Bus Fees</h5>
                                        <p className="autop">Auto Renew</p>
                                        </div> */}
                <div className="">
                  <div className="d-flex ">
                    <div className=" col_87 dis-corr">
                      <div className="d-flex ">
                        <div className=" mobile_co_img">
                          <img
                            src={schoolbus}
                            alt="plus"
                            width="80px"
                            height="80px"
                          ></img>
                        </div>
                        <div className="se-corr mar-0">
                        <h5 className=" mar-left-0 productname1h5">School : Student Bus Fees</h5>
                          <ul className="d-flex">
                            <li>
                              <p className="productname1p">
                                Amount Paid :
                                <b>
                                  <strong className="productname1pp"> UGX 2000</strong>
                                </b>
                              </p>
                              <p className="productname1p">
                                Paid at :<strong className="productname1pp"> 14 June,2023</strong>
                              </p>
                              <p className="productname1p">
                                Expires at :<strong className="productname1pp"> 12 June,2024</strong>
                              </p>
                            </li>
                            <li>
                              <p className="productname1p">
                                Subscription ID :<strong className="productname1pp"> 68679658964</strong>
                              </p>
                              <p className="productname1p">
                                Beneficiary :<strong className="productname1pp"> James Charles</strong>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col_13">
                      <div className="text-end">
                        <p className="autop">Auto Renew</p>
                        <label className="toggle-switchsub">
                          <input
                            type="checkbox"
                          />
                          <span className="partnerslidersub"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="purchaserow22 mar-20 after-one ">
                {/* <div className="d-flex justify-content-between align-items-center dis__coo">
                                        <h5 className="productname1h5">School : Student Bus Fees</h5>
                                        <p className="autop">Auto Renew</p>
                                        </div> */}
                <div className="">
                  <div className="d-flex ">
                    <div className=" col_87 dis-corr">
                      <div className="d-flex ">
                        <div className=" mobile_co_img">
                          <img
                            src={recordsess}
                            alt="plus"
                            width="80px"
                            height="80px"
                          ></img>
                        </div>
                        <div className="se-corr mar-0">
                        <h5 className=" mar-left-0 productname1h5">School : Student Bus Fees</h5>
                          <ul className="d-flex">
                            <li>
                              <p className="productname1p">
                                Amount Paid :
                                <b>
                                  <strong className="productname1pp"> UGX 2000</strong>
                                </b>
                              </p>
                              <p className="productname1p">
                                Paid at :<strong className="productname1pp"> 14 June,2023</strong>
                              </p>
                              <p className="productname1p">
                                Expires at :<strong className="productname1pp"> 12 June,2024</strong>
                              </p>
                            </li>
                            <li>
                              <p className="productname1p">
                                Subscription ID :<strong className="productname1pp"> 68679658964</strong>
                              </p>
                              <p className="productname1p">
                                Beneficiary :<strong className="productname1pp"> James Charles</strong>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col_13">
                      <div className="text-end">
                        <p className="autop">Auto Renew</p>
                        <label className="toggle-switchsub">
                          <input
                            type="checkbox"
                          />
                          <span className="partnerslidersub"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="purchaserow22 mar-20 after-one ">
                {/* <div className="d-flex justify-content-between align-items-center dis__coo">
                                        <h5 className="productname1h5">School : Student Bus Fees</h5>
                                        <p className="autop">Auto Renew</p>
                                        </div> */}
                <div className="">
                  <div className="d-flex ">
                    <div className=" col_87 dis-corr">
                      <div className="d-flex ">
                        <div className=" mobile_co_img">
                          <img
                            src={student}
                            alt="plus"
                            width="80px"
                            height="80px"
                          ></img>
                        </div>
                        <div className="se-corr mar-0">
                        <h5 className=" mar-left-0 productname1h5">School : Student Bus Fees</h5>
                          <ul className="d-flex">
                            <li>
                              <p className="productname1p">
                                Amount Paid :
                                <b>
                                  <strong className="productname1pp"> UGX 2000</strong>
                                </b>
                              </p>
                              <p className="productname1p">
                                Paid at :<strong className="productname1pp"> 14 June,2023</strong>
                              </p>
                              <p className="productname1p">
                                Expires at :<strong className="productname1pp"> 12 June,2024</strong>
                              </p>
                            </li>
                            <li>
                              <p className="productname1p">
                                Subscription ID :<strong className="productname1pp"> 68679658964</strong>
                              </p>
                              <p className="productname1p">
                                Beneficiary :<strong className="productname1pp"> James Charles</strong>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col_13">
                      <div className="text-end">
                        <p className="autop">Auto Renew</p>
                        <label className="toggle-switchsub">
                          <input
                            type="checkbox"
                          />
                          <span className="partnerslidersub"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* </div>
            </div> */}
    </>
  );
}
export default Subscription;
