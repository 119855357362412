import React, { useState,useRef } from "react";
import Navbars from "../../navbar";
import tick from "../../images/user-3.jpg";
import { parseISO } from 'date-fns';
import eye from "../../images/eye_green-01.png";
import menufile from '../../images/My_documents_black-01.png';
import popdeletes from '../../images/info_icon/delete-01.png';
import Modal from 'react-bootstrap/Modal';
import fileopen from '../../images/folder-open-01.png';
import DatePicker from 'react-datepicker';
import OtpInput from 'react-otp-input';
import resetpasswordotp from '../../images/Enter-Otp-code.png';
import modaldelete from '../../images/times-circle-01.png';
import eyeslash from '../../images/Profile Setup/eye-slash-01.png';
import 'react-datepicker/dist/react-datepicker.css';
import { handleDragOver,handleDrop,handleFileChange,truncateModel,formatFileSize,handleDrop1,handleEditfilechange,handleDragOver1,getMaskedEmail,getMaskedPhoneNumber } from "../../api/commonfunct.js/common";
const steps = [
  "Profile",
  "Privacy & Terms",
  "Email & Phone",
  "National ID & Documents",
  "General ID Documents",
  "Recovery email & Mobile",
  "Emergency contacts",
];
function ProfileSetup() {
  const [activeStep, setActiveStep] = useState(0);
  const [walletpasscodehide,setWalletpasscodehide] = useState(false);
  const [uploading,setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressColor, setProgressColor] = useState("#28a745");
  const [image, setImage] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [fileType, setFileType] = useState('');
  const [format ,setFormat] = useState('');
  const [editImage, setEditImage] = useState([]);
  const [editImagedetails, setEditImageDetails] = useState(true);
  const [editProgress, setEditProgress] = useState(0);
  const [editProgressColor, setEditProgressColor] = useState('#28a745');
  const [dateofissue, setDateofissue] = useState(null);
  const [expiryissue, setExpiryofissue] = useState(null);
  const [show,setShow] = useState(false);
  const [otp, setOtp] = useState('');
  const [countdown, setCountdown] = useState(120);
  const [showPassword, setShowPassword] = useState(false);
  const [password,setPassword] = useState('');
  const [responseemail, setResponseEmail] = useState('');
  const [responsephoneNumber, setResponsePhoneNumber] = useState('');
  const [responsecountrycode , setResponsecountrycode] = useState('');
  const [otpVerificationError, setOtpVerificationError] = useState(false)
  const [selectedOption, setSelectedOption] = useState('email');
  const [show1,setShow1] = useState(false);
  // Function to handle the next step
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Function to handle the previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleCheckboxChange = (event) => {
    setWalletpasscodehide(event.target.checked);
  };
  const fileInputRef = useRef(null);
  const handleFileClick = () => {
    fileInputRef.current.click();
  }
  const fileInputRef1 = useRef(null);
  const handleFileimageClick1 = () => {
    fileInputRef1.current.click();
  }
  const handleFileDrop = (selectedFile) => {
    handleFileChange(
      { target: { files: [selectedFile] } },
      setImage,
      setFileSize,
      setFileType,
      setProgress,
      setProgressColor,
      setUploading,
      setFormat,
    );
  };
  const handleeditFileDrop = (selectedFile1) => {
    handleEditfilechange(
      { target: { files: [selectedFile1] } },
      setEditImage,
      setFileSize,
      setFileType,
      setEditProgress,
      setEditProgressColor,
      setEditImageDetails,
      setFormat,
    );
  }
  const handleEyeClick = () => {

  }
  const handledelete = () => {
    setImage([]);
    setUploading(false);
    setProgress(0);
  }
  const handledelete1 = () => {
   setEditImage([]);
   setEditImageDetails(true);
   setEditProgress(0);
  }
  const handleEyeClick1 = () => {

  }
  const workfileInputRef = useRef(null);
  const handleworkFileClick = () => {
    workfileInputRef.current.click();
  }
  const handleworkeye = () => {

  }
  const handleworkdelete = () => {
    setUploading(false);
    setProgress(0);
    setFileSize(0);
  }
  const handleClose = () => {
    setShow(false);
  }
  const resendotp = async() => {
  }  
  const handlePaste = () => {

  }
  const handleInputChangeotp = () => {

  }
  const getotpemail = () => {
    setShow(true);
  }
  const handleClose1 = () => {
    setShow1(false);
  }
  const walletpasscodeclose = () => {
    handleClose1();
  }
  const setWalletpass = () => {
    setShow1(true);
  }
  const handleInputChanges = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
}
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};
  return (
    <>
      <Navbars />
      <div className="container-fluid profile-setup">
        <div className="profilebox">
          <div className="row">
            <div className="col-6 profilesetup">
              <div className="custom-vertical-stepper">
                {steps.map((label, index) => (
                  <div
                    key={index}
                    className={`step ${
                      index === activeStep
                        ? "active"
                        : index < activeStep
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div
                      className={`step-circle ${
                        index === activeStep
                          ? "active"
                          : index < activeStep
                          ? "completed"
                          : ""
                      }`}
                    >
                      <span>{(index + 1).toString().padStart(2, "0")}</span>
                    </div>
                    {/* {index < steps.length - 1 && (
                      <div className="MuiStepConnector-line MuiStepConnector-lineVertical" />
                    )} */}
                    <div
                      className={`step-label ${
                        index === activeStep ? "active" : ""
                      }`}
                    >
                      {label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-6 profilesetups vertical-lines">
              {activeStep === 0 && (
                <div className="wallet-profilepad">
                  <div className="wallet-profilediv">
                    <div className="profilediv">
                      <div
                        className="header-profile-user border-dark user"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          marginRight: "5px",
                        }}
                      >
                        <img
                          id="login_img_for_profile"
                          src={tick}
                          className="rounded-circle nav-user header-profile-user border-dark"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center center",
                          }}
                        />
                      </div>
                      <div>
                        <p className="mb-0">Upload Your Picture</p>
                        {/* <p className="mb-0"></p> */}
                      </div>
                    </div>
                    <div className="profile-uploadbuttondiv">
                      <button className="profile-upload">Upload</button>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="mt-3">
                    <label className="walletprofile-label">
                      Name<span className="docpopstar">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control mt-1 walletprofile-input"
                      placeholder="Enter your name"
                    ></input>
                  </div>
                  <div className="mt-3">
                    <label className="walletprofile-label">
                      Country<span className="docpopstar">*</span>
                    </label>
                    <select
                      className="form-select mt-1 walletcountryselect walletprofile-input"
                      placeholder="Enter your name"
                    >
                      <option value="">Select Country</option>
                    </select>
                  </div>
                  <div className="mt-3 mb-4">
                    <div className="row">
                      <div className="col-6">
                        <label className="walletprofile-label">
                          Gender<span className="docpopstar">*</span>
                        </label>
                        <select
                          className="form-select mt-1 walletcountryselect walletprofile-input"
                          placeholder="Enter your name"
                        >
                          <option value="">Select gender</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <label className="walletprofile-label">
                          Date of Birth<span className="docpopstar">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control mt-1 walletprofile-input"
                          placeholder="Enter your name"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="walletprofile-buttons mt-3">
                    <button className="walletprofile-save me-3">Save</button>
                    <button
                      className="walletprofile-next me-3"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <button
                      className="walletprofile-cancel"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div className="wallet-profilepad">
                  <h5>Terms and Condition</h5>
                  <div className="wallet-profilescroll">
                    <p>
                      These terms and conditions outline the rules and
                      regulations for the use of Company Name's Website, located
                      at Website.com. By accessing this website we assume you
                      accept these terms and conditions. Do not continue to use
                      Website Name if you do not agree to take all of the terms
                      and conditions stated on this page. The following
                      terminology applies to these Terms and Conditions, Privacy
                      Statement and Disclaimer Notice and all Agreements:
                      “Client”, “You” and “Your” refers to you, the person log
                      on this website and compliant to the Company's terms and
                      conditions. “The Company”, “Ourselves”, “We”, “Our” and
                      “Us”, refers to our Company. “Party”, “Parties”, or “Us”,
                      refers to both the Client and ourselves. All terms refer
                      to the offer, acceptance and consideration of payment
                      necessary to undertake the process of our assistance to
                      the Client in the most appropriate manner for the express
                      purpose of meeting the Client's needs in respect of
                      provision of the Company's stated services, in accordance
                      with and subject to, prevailing law of Netherlands. Any
                      use of the above terminology or other words in the
                      singular, plural, capitalization and/or he/she or they,
                      are taken as interchangeable and therefore as referring to
                      same. Cookies We employ the use of cookies. By accessing
                      Website Name, you agreed to use cookies in agreement with
                      the Company Name's Privacy Policy. Most interactive
                      websites use cookies to let us retrieve the user's details
                      for each visit. Cookies are used by our website to enable
                      the functionality of certain areas to make it easier for
                      people visiting our website. Some of our
                      affiliate/advertising partners may also use cookies.
                    </p>
                  </div>
                  <div className="form-check mt-3">
                    <input
                      type="checkbox"
                      className="form-check-input forgotpagecheckbox"
                      id="customControlInline" 
                      checked={walletpasscodehide}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label forgotpagecheckboxlabel"
                      htmlFor="customControlInline"
                    >
                      I agree to the <strong>Privacy & Terms</strong>
                    </label>
                  </div>
                  {walletpasscodehide && (
                    <>
                    <hr className="walletprofile-mar"></hr>
                    <p>To keep your wallet safe! Create a 5-digit memorable Passcode. Don't share it with anyone for extra security.</p>
                    <button className="setwalletprofile-code" onClick={setWalletpass}>Set Wallet Passcode</button>
                    </>
                  )}
                  <hr className="walletprofile-mar"></hr>
                  <div className="walletprofile-buttons mt-3">
                    <button className="walletprofile-save me-3">Save</button>
                    <button
                      className="walletprofile-next me-3"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <button
                      className="walletprofile-cancel"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <div className="wallet-profilepad">
                   <div className="mt-3">
                    <label className="walletprofile-label">
                    Email<span className="docpopstar">*</span>
                    </label>
                    <input
                      type="email" value={responseemail} onChange={(e)=>setResponseEmail(e.target.value)}
                      className="form-control mt-1 walletprofile-input"
                      placeholder="Enter your email address"
                    ></input>
                    <p className="wallet-pofileotpp" onClick={getotpemail}>Get OTP</p>
                  </div>
                  <div className="mt-1">
                    <label className="walletprofile-label">
                    Mobile Number<span className="docpopstar">*</span>
                    </label>
                    <input
                      type="text" value={responsephoneNumber} onChange={(e)=>setResponsePhoneNumber(e.target.value)}
                      className="form-control mt-1 walletprofile-input"
                      placeholder="Enter your Mobile number"
                    ></input>
                    <p className="wallet-pofileotpp"onClick={getotpemail}>Get OTP</p>
                  </div>
                  <hr className="walletprofile-mar"></hr>
                  <div className="walletprofile-buttons mt-3">
                    <button className="walletprofile-save me-3">Save</button>
                    <button
                      className="walletprofile-next me-3"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <button
                      className="walletprofile-cancel"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {activeStep === 3 && (
                <div className="wallet-profilepad">
                    <div className="">
                    <div className="row">
                      <div className="col-6">
                        <label className="walletprofile-label">
                        First Name<span className="docpopstar">*</span>
                        </label>
                        <input
                          className="form-control mt-1 walletcountryselect walletprofile-input"
                          placeholder="Type as it appears on ID"
                        >
                        </input>
                      </div>
                      <div className="col-6">
                        <label className="walletprofile-label">
                        Other Name(S)<span className="docpopstar">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1 walletprofile-input"
                          placeholder="Enter other name"
                        ></input>
                      </div>
                    </div>
                </div>
                <div className="mt-3">
                        <label className="walletprofile-label">
                        National ID number<span className="docpopstar">*</span>
                        </label>
                        <input
                          className="form-control mt-1 walletcountryselect walletprofile-input"
                          placeholder="Enter national ID number"
                        >
                        </input>
                      </div>
                      <div className="mt-3">
                    <div className="row">
                      <div className="col-6">
                        <label className="walletprofile-label">
                        Date Of Issue<span className="docpopstar">*</span>
                        </label>
                        <input type="date"
                          className="form-control mt-1 walletcountryselect walletprofile-input"
                          placeholder="Type as it appears on ID"
                        >
                        </input>
                      </div>
                      <div className="col-6">
                        <label className="walletprofile-label">
                        Expiry Date<span className="docpopstar">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control mt-1 walletprofile-input"
                          placeholder="Enter other name"
                        ></input>
                      </div>
                    </div>
                </div>
                <div className="mt-3">
                  <div className="row">
                   <div className="col-6">
                   {uploading ? (
              <div className="popprogressbox">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex mb-2">
                    <img src={menufile} alt="file" className="popuploadfileimg me-3"></img>
                    <div>
                      <p className="popfilefrontp">{`${truncateModel(fileType)}.${format}`}</p>
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                    </div>
                  </div>
                  <div>
                    {progress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleEyeClick(image)} className="popupeye me-3"></img>
                    )}
                    <img src={popdeletes} alt="delete" className="popupeye" onClick={handledelete}></img>
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            ) : (
              <div className="popinputbox mt-3" onClick={handleFileClick} onDrop={(e) => handleDrop(e, handleFileDrop)}
                onDragOver={handleDragOver}>
                <div className="d-flex align-items-center justify-content-center flex-column p-2">
                  <img src={fileopen} className="popuploadflle" alt="file" width='48px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, setImage, setFileSize, setFileType, setProgress, setProgressColor, setUploading,setFormat)} />
              </div>
            )}
                   </div>
                   <div className="col-6">
                   {editImagedetails ? (
              <div className="popinputbox mt-3" onClick={handleFileimageClick1} onDrop={(e) => handleDrop1(e, handleeditFileDrop)}
                onDragOver={handleDragOver1}>
                <div className="d-flex align-items-center justify-content-center flex-column p-2">
                  <img src={fileopen} className="popuploadflle" alt="file" width="48px" height="auto" />
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size: 20 MB)</p>
                </div>
                <input type="file" ref={fileInputRef1} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleEditfilechange(e, setEditImage, setFileSize, setFileType, setEditProgress, setEditProgressColor, setEditImageDetails, setFormat)} />
              </div>
            ) : (
              <div className="popprogressbox">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex mb-2">
                    <img src={menufile} alt="file" className="popuploadfileimg me-3" />
                    <div>
                      <p className="popfilefrontp">{`${truncateModel(fileType)}.${format}`}</p>
                      {fileSize && fileSize >= 1024 && (
                        <p className="popfilekb">{formatFileSize(fileSize)}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    {editProgress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleEyeClick1(editImage)} className="popupeye me-3" />
                    )}
                    <img src={popdeletes} alt="delete" onClick={() => handledelete1()} className="popupeye" />
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${editProgress}%`, backgroundColor: editProgressColor }} aria-valuenow={editProgress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            )}
                   </div>
                   </div>
                   <hr className="walletprofile-mar"></hr>
                  <div className="walletprofile-buttons mt-3">
                    <button className="walletprofile-save me-3">Save</button>
                    <button
                      className="walletprofile-next me-3"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <button
                      className="walletprofile-cancel"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              )}
              {activeStep === 4 && (
                <div className="wallet-profilepad">
                   <div className="">
                    <div className="row">
                      <div className="col-6">
                        <label className="walletprofile-label">
                        Document Type<span className="docpopstar">*</span>
                        </label>
                        <select
                          className="form-select mt-1 walletcountryselect walletprofile-input"
                          placeholder="Type as it appears on ID"
                        >
                          <option>Select a Document Type</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <label className="walletprofile-label">
                        Document Name<span className="docpopstar">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1 walletprofile-input" 
                          placeholder="Enter Document Name"
                        ></input>
                      </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="row">
                      <div className="col-6">
                        <label className="walletprofile-label">
                        Date Of Issue<span className="docpopstar">*</span>
                        </label>
                        <DatePicker selected={dateofissue ? parseISO(dateofissue) : null} onChange={(date) => setDateofissue(date ? date.toISOString() : null)} showMonthYearPicker dateFormat="MM/yyyy" placeholderText="MM/YY" className="form-control walletprofile-input" />
                      </div>
                      <div className="col-6">
                        <label className="walletprofile-label">
                        Expiry Date<span className="docpopstar">*</span>
                        </label>
                        <DatePicker selected={expiryissue ? parseISO(expiryissue) : null} onChange={(date) => setExpiryofissue(date ? date.toISOString() : null)} showMonthYearPicker dateFormat="MM/yyyy" placeholderText="MM/YY" className="form-control walletprofile-input" />
                      </div>
                    </div>
                </div>
                {uploading ? (
              <div className="popprogressbox">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex mb-2">
                    <img src={menufile} alt="file" className="popuploadfileimg me-3"></img>
                    <div>
                      <p className="popfilefrontp">{truncateModel(fileType)}{format}</p>
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                    </div>
                  </div>
                  <div>
                    {progress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleworkeye(image)} className="popupeye me-3"></img>
                    )}
                    <img src={popdeletes} alt="delete" className="popupeye" onClick={handleworkdelete}></img>
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            ) : (
              <div className="popinputbox mt-3" onClick={handleworkFileClick} onDrop={(e) => handleDrop(e, handleFileDrop)} onDragOver={handleDragOver}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width='60px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" ref={workfileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, setImage, setFileSize, setFileType, setProgress, setProgressColor, setUploading,setFormat)} />
              </div>
              )}
               <hr className="walletprofile-mar"></hr>
                  <div className="walletprofile-buttons mt-3">
                    <button className="walletprofile-save me-3">Save</button>
                    <button
                      className="walletprofile-next me-3"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <button
                      className="walletprofile-cancel"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Cancel
                    </button>
                  </div>
                </div> 
              )}
              {activeStep === 5 && (
                <div className="wallet-profilepad">
                <div className="mt-3">
                 <label className="walletprofile-label">
                 Recovery Email<span className="docpopstar">*</span>
                 </label>
                 <input
                   type="email"
                   className="form-control mt-1 walletprofile-input"
                   placeholder="Enter Recovery email address"
                 ></input>
               </div>
               <div className="mt-3 ">
                 <label className="walletprofile-label">
                 Recovery Mobile Number<span className="docpopstar">*</span>
                 </label>
                 <input
                   type="text"
                   className="form-control mt-1 walletprofile-input"
                   placeholder="Enter Recovery Mobile number"
                 ></input>
               </div>
               <hr className="walletprofile-mar"></hr>
               <div className="walletprofile-buttons mt-3">
                 <button className="walletprofile-save me-3">Save</button>
                 <button
                   className="walletprofile-next me-3"
                   onClick={handleNext}
                 >
                   Next
                 </button>
                 <button
                   className="walletprofile-cancel"
                   disabled={activeStep === 0}
                   onClick={handleBack}
                 >
                   Cancel
                 </button>
               </div>
             </div>
              )}
              {activeStep === 6 && (
                <div className="wallet-profilepad">
                <div className="wallet-profilediv">
                  <div className="profilediv">
                    <div
                      className="header-profile-user border-dark user"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        marginRight: "5px",
                      }}
                    >
                      <img
                        id="login_img_for_profile"
                        src={tick}
                        className="rounded-circle nav-user header-profile-user border-dark"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                      />
                    </div>
                    <div>
                      <p className="mb-0">Upload Your Picture</p>
                      {/* <p className="mb-0"></p> */}
                    </div>
                  </div>
                  <div className="profile-uploadbuttondiv">
                    <button className="profile-upload">Upload</button>
                  </div>
                </div>
                <hr></hr>
                <div className="mt-3">
                  <label className="walletprofile-label">
                    Name<span className="docpopstar">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control mt-1 walletprofile-input"
                    placeholder="Enter your name"
                  ></input>
                </div>
                <div className="mt-3">
                  <label className="walletprofile-label">
                  Mobile Number<span className="docpopstar">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control mt-1 walletprofile-input"
                    placeholder="Enter your Mobile Number"
                  ></input>
                </div>
                <div className="mt-3">
                  <label className="walletprofile-label">
                  Email Address<span className="docpopstar">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control mt-1 walletprofile-input"
                    placeholder="Enter your Email"
                  ></input>
                </div>
                <div className="mt-3">
                  <label className="walletprofile-label">
                  Relation<span className="docpopstar">*</span>
                  </label>
                  <select
                    type="email"
                    className="form-select mt-1 walletprofile-input"
                    placeholder="Enter your Email"
                  ></select>
                </div>
                <div className="form-check mt-3">
                    <input
                      type="checkbox"
                      className="form-check-input forgotpagecheckbox"
                      id="customControlInline"
                    />
                    <label
                      className="form-check-label forgotpagecheckboxlabel"
                      htmlFor="customControlInline"
                    >
                      <strong>Next of Kin</strong>
                    </label>
                  </div>
                  <hr className="walletprofile-mar"></hr>
               <div className="walletprofile-buttons mt-3">
                 <button className="walletprofile-save me-3">Save</button>
                 <button
                   className="walletprofile-next me-3"
                   onClick={handleNext}
                 >
                   Next
                 </button>
                 <button
                   className="walletprofile-cancel"
                   disabled={activeStep === 0}
                   onClick={handleBack}
                 >
                   Cancel
                 </button>
               </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


      <Modal show={show} dialogClassName="example-walletprofiledialog" contentClassName="example-walletcontent" onHide={handleClose} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                  <div className="text-end p-2">
                  <img src={modaldelete} className="text-end" alt="modaldelete" width='34px' height='auto'></img>
                  </div>
                <form className="formdsaas" action=''  >
                              <div className='resetpasspad'>
                                  <div className='d-flex flex-column justify-content-center align-items-center pt-2'>
                                      <img src={resetpasswordotp} alt='image' className='resetppasslock '></img>
                                      <h6 className='mt-2 resetpassh6'>Enter OTP Code</h6>
                                      <p className='resetpassp resetpassp1'>Enter the 6-didit code sent<br></br>
                                          to {selectedOption === 'email' ? `${getMaskedEmail(responseemail)}` : `+${responsecountrycode}${getMaskedPhoneNumber(responsephoneNumber)}`} Did not receive<br></br>
                                          OTP?<strong className={`${countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={countdown <= 0 ? resendotp : null}>Resend OTP</strong></p>
                                      <OtpInput
                                          value={otp}
                                          onChange={setOtp}
                                          numInputs={6} 
                                          renderInput={(props) => <input {...props} onPaste={handlePaste} className={`custom-inputotp mt-3 ${otpVerificationError ? 'error-border' : ''}`} onKeyPress={handleInputChangeotp} />}
                                      />
                                      <div className='mt-3  d-flex justify-content-center w-100'>
                                          <button type="button" className='resendotp'> {countdown > 0 ? `${countdown}S` : `${countdown}S`}
                                          </button>
                                      </div>
                                      <p className='mt-4 mb-4'>Having any trouble?<strong className='activeotpsssp forgotpad'>Get Help</strong></p>
                                  </div>
                              </div>
                          </form>
                </Modal.Body>
            </Modal>

            <Modal show={show1} dialogClassName="example-walletprofiledialog" contentClassName="example-walletcontent1" onHide={handleClose1} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                      <h5 className="walletpasscodeh5">Passcode</h5>
                      <div className="mt-3">
                      <label className="walletprofile-label">
                    Set Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                                </div>
                                <div className="mt-3">
                      <label className="walletprofile-label">
                    Confirm Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                                </div>
                     
                  <div className="walletprofile-buttons mt-3">
                    {/* <button className="walletprofile-save me-3">Save</button> */}
                    <button
                      className=" walletprofile-cancel me-3"
                      onClick={walletpasscodeclose}
                    >
                      Cancel
                    </button>
                    <button
                      className="walletprofile-next"
                      // disabled={activeStep === 0}
                      onClick={walletpasscodeclose}
                    >
                      Submit
                    </button>
                  </div>
                </Modal.Body>
            </Modal>    
    </>
  );
}

export default ProfileSetup;
