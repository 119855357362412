import React from "react";
import { useEffect } from "react";
function Transactionside({setSource3, source3}){
    const handleSource3 = (source3) => {
        setSource3(source3);
        localStorage.setItem('activeSection', source3);
   }
   useEffect(() => {
    const storedActiveSection = localStorage.getItem('activeSection'); // Retrieve the active section from localStorage

    if (storedActiveSection) {
      setSource3(storedActiveSection); 
    }
}, []);

    return(
        <>
          <div className="asides">
                <aside>
                    <div className="pt-4 ps-3">
                    <h6 className="transideh5">Transaction History</h6>
                    </div>
                    <hr className="manualsidehr" style={{ width: '100%', maxWidth: '90%', color: '#ADA9A9', margin: '5% 5%', border: 'none' }}></hr>
                    <div className="registeruls mt-3">
                        <ul>
                            <li onClick={() => handleSource3('agentrecord')} className={source3 === 'agentrecord' ? 'activesystem' : ''}
                                style={{ backgroundColor: source3 === 'agentrecord' ? '#C5FFED' : 'transparent', borderLeft: source3 === "agentrecord" ? '6px solid #04D08E' : 'none' }}>
                                <p className="mb-0" style={{cursor:'pointer'}}>Agent Records</p>
                            </li>
                            <li onClick={() => handleSource3('agendpay')} className={source3 === 'agendpay' ? 'activesystem' : ''}
                                style={{ backgroundColor: source3 === "agendpay" ? '#C5FFED' : 'transparent', borderLeft: source3 === "agendpay" ? '6px solid #04D08E' : 'none' }}
                            >
                                <p className="mb-0" style={{cursor:'pointer'}}>PayNow Records</p>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
        </>
    );
}
export default Transactionside;