import React from 'react';
import { Image } from 'antd';

function Imageviewer({ isPreviewVisible, selectedImage1, onClose }) {
    const handlePreviewClose = () => { 
        if (onClose) {
            onClose(); 
        }
    };

    return (
        <Image
            src={selectedImage1}
            alt="Enlarged Image"
            preview={{
                visible: isPreviewVisible,
                onVisibleChange: isPreviewVisible,
                onClose: handlePreviewClose,
            }}
            style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999',display:'none' }}
        />
    );
}

export default Imageviewer;
