import React, { useState } from "react";
import Registerservices from "./registerservices";
import Navbars from "./navbar";
import plus from './images/plus.png';
import Modal from 'react-bootstrap/Modal';
import closeicon from './images/times-circle-01.png';
import trash from './images/paypal/trash-01.png';
import edit from './images/paypal/pen-01.png';
function Bank() {
    const [show15, setShow15] = useState(false);
    const [show21,setShow21] = useState(false);
    const [swiftcode, setSwiftcode] = useState('');
    const [ihaveswift ,setIhaveswift] = useState(true);
    const handleCloses15 = () => {
        setShow15(false);
        setSwiftcode('');
    }
    const closedialog15 = () => {
        setShow15(false);
        setSwiftcode('');
    }
    const addnewbank = () => {
        setShow20(true);
    }
    const swiftcodechange = (e) => {
        setSwiftcode(e.target.value);
    }
    const confirmswiftcodechange = () => {
        if(!swiftcode){
            setShow15(true);
        }
        else{
            setShow15(false);
            setShow16(true);
        }
    }
    // const getButtonClass15 = () => {
    //     return swiftcode > '' ? 'addnewsaveafter' : 'addnewsave'
    // }
    const swiftcodesave = () => {
        setShow15(false);
        setShow16(true);
    }
    const confirmswiftcode = () => {
        return !swiftcode ? 'confirmswiftcode ' : 'confirmswiftcode orangeconfirm'
    }
    const [show16, setShow16] = useState(false);
    const [swiftcodes, setSwiftcodes] = useState('');
    const handleCloses16 = () => {
        setShow16(false);
    }
    const closedialog16 = () => {
        setShow16(false);
    }
    const swiftcodechanges = (e) => {
        setSwiftcodes(e.target.value);
    }
    const swiftcodechange1 = (e) => {
        setSwiftcode(e.target.value);
        if (e.target.value.trim() !== '') {
            setIhaveswift(false);
        } else {
            setIhaveswift(true); 
        }
    }
    const getButtonClass16 = () => {
        return swiftcodes > '' ? 'addnewsaveafter' : 'addnewsave'
    }
    const swiftcodeconfirmsave = () => {
        setShow16(false);
        setShow17(true);
    }
    const [show17, setShow17] = useState(false);
    const handleCloses17 = () => {
        setShow17(false);
    }
    const closedialog17 = () => {
        setShow17(false);
    }
    const addbank = () => {
        setShow17(false);
        setSwiftcode('');
        setSwiftcodes('');
    }
    // Bank name

    const [show18, setShow18] = useState(false);
    const [bankname, setBankname] = useState('');
    const [haveswift, setHaveswift] = useState(true);
    const [bankcityname, setBankcityname] = useState('');
    const [bankbranchname , setBankbranchname] = useState(''); 
    const bankcitynamechange = (e) => {
        setBankcityname(e.target.value);
    }
    const branchnamechange = (e) => {
        setBankbranchname(e.target.value);
    }
    const handleCloses18 = () => {
        setShow18(false);
        setBankname('');
    }
    const closedialog18 = () => {
        setShow18(false);
        setBankname('');
    }
    const addbankname = () => {
        setShow15(false);
        setShow18(true);
    }
    const bankswiftcode = () => {
        setShow18(false);
        setShow15(true);
    }
    const swiftcodesaves = () => {
        setShow18(false);
        setShow19(true);
    }
    const banknamechange = (e) => {
        setBankname(e.target.value);
        if (e.target.value.trim() !== '') {
            setHaveswift(false); // Hide "I have Swift Code" when input is not empty
        } else {
            setHaveswift(true); // Show "I have Swift Code" when input is empty
        }
    }
    const getButtonClass18 = () => {
        return bankname > '' ? 'addnewsaveafter' : 'addnewsave'
    }
    const getButtonClass19 = () => {
        return bankname && bankcityname && bankbranchname > '' ? 'addnewsaveafter' : 'addnewsave'
    }
    const bankcity = () => {
        setShow18(false);
        setShow19(true);
    }
    const bankdetails = () => {
        setShow19(false);
        setShow20(true);
    }
    const [show19, setShow19] = useState(false);
    const handleCloses19 = () => {
        setShow19(false);
        setBankbranchname('');
        setBankcityname('');
        setBankbranchname('');
        setBankname('');
    }
    const closedialog19 = () => {
        setShow19(false);
        setBankbranchname('');
        setBankcityname('');
        setBankbranchname('');
        setBankname('');
    }
    const [show20, setShow20] = useState(false);
    const [country, setCountry] = useState('');
    const [bankName, setBankName] = useState('');
    const [city, setCity] = useState('');
    const [branch, setBranch] = useState('');
    const handleCloses20 = () => {
        setShow21(false);
        setAccountholdername('');
        setAccountholdernumber('');
        setAccountholderconfirm('');
    }
    const closeDialog20 = () => {
          setShow20(false)
    }
    const handleClose20 = () => {
       setShow20(false);
    }
    const closedialog20 = () => {
        setShow21(false);
        setAccountholdername('');
        setAccountholdernumber('');
        setAccountholderconfirm('');
    }
    const [accountholdername , setAccountholdername] = useState('');
    const [accountholdernumber , setAccountholdernumber] = useState('');
    const [accountholderconfirm , setAccountholderconfirm] = useState('');
    const getButtonClass20 = () => {
        return country && bankName && city && branch  ? 'addnewsaveafter' : 'addnewsaveafter'
    }
    const confirmBankSave = () => {
        setShow20(false);
        setShow21(true);
        setCountry('');
        setCity('');
        setBranch('');
        setBankName('');
    }
    const confirmbanksave = () => {
            setShow21(false);
    }
    return (
        <>
            {/* <Navbars />
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row pt-4">
                    <div className="col-lg-3 col_corre_2">
                        <Registerservices />
                    </div> */}
                    <div className="col-lg-9 col_corr_1">
                        <main className="cardsmaindiv" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", height: '100%',minHeight:'400px' }}>
                            <h5 className="ps-4 pt-4 registerservicecard">Bank Account</h5>
                            <p className="cardsp ps-4 mb-4 registerservicecard">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                            <div style={{ width: '100%', margin: '0 2.5%', maxWidth: '95%', minHeight: '400px', border: '1px solid #7D7878', }}>
                                <div className="d-flex align-items-center justify-content-between p-4 paypalhead">
                                    <h5 className="paypalh5" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#494949' }}>Bank Account</h5>
                                    <button className="addnewcards" onClick={addnewbank}><img src={plus} className="me-2 paypalplus" alt="plus" width='15px' height='15px'></img>Add bank account</button>
                                </div>
                                <div className="d-flex radio align-items-center w-100 paypalradio" style={{ padding: '0px 30px' }}>
                                    <input type="radio" id="myRadio" style={{  marginRight: '20px', }}></input>
                                    <div className="paybox">
                                        <div className="d-flex justify-content-between">
                                            <div className="bankcol-70">
                                                <div className="d-flex justify-content-between bankcol-70div">
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Bank Name</p>
                                                        <p className="paybankname">City Bank Uganda</p>
                                                    </div>
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Account Holder</p>
                                                        <p className="paybankname">John Doe</p>
                                                    </div>
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Account Number</p>
                                                        <p className="paybankname">2364568946796589</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bankcol-30"style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="d-flex align-items-center ">
                                                        <img src={edit} className="me-3 paypaldelete paypaldelete1"  alt="edit"></img>
                                                        <img src={trash} className="paypaldelete" alt="trash"></img>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}

            <Modal show={show15} dialogClassName="example-dialog15" contentClassName="example-content15" onHide={handleCloses15} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end">
                        <img src={closeicon} onClick={closedialog15} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Bank Account</h5>
                    </div>
                    <div className="mt-3 mb-1" style={{ margin: '0 8%' }}>
                        <div className="d-flex align-items-cnter justify-content-between">
                            <label htmlFor="cardNumber" className="cardlabel">Enter Bank  Swift Code</label>
                            {ihaveswift &&(
                            <label className="mx-2 swiftknow" onClick={addbankname}>I don’t Know</label>
                            )}
                        </div>
                        <input type="text" id="cardNumber" value={swiftcode} onChange={swiftcodechange1} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="text-end mt-0" style={{ margin: '0 10%' }}>
                        <p className={confirmswiftcode()} onClick={confirmswiftcodechange}>Confirm Swift Code</p>
                    </div>
                    <div style={{ margin: '0 8%' }}>
                        <button className='addnewsave addnewsavecursor' disabled onClick={swiftcodesave}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show16} dialogClassName="example-dialog15" contentClassName="example-content15" onHide={handleCloses16} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end">
                        <img src={closeicon} onClick={closedialog16} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Bank Account</h5>
                    </div>
                    <div className="mt-2 mb-1" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Swift Code</label>
                        <input type="text" id="cardNumber" value={swiftcodes} onChange={swiftcodechanges} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="text-end mt-0" style={{ margin: '0 10%' }}>
                
                        <p className="confirmswiftcodes">Confirm Swift Code</p>
                       
                    </div>
                    <div style={{ margin: '0 8%' }}>
                        <button className={getButtonClass16()} onClick={swiftcodeconfirmsave}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show17} dialogClassName="example-dialog17" contentClassName="example-content17" onHide={handleCloses17} >
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end">
                        <img src={closeicon} onClick={closedialog17} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Bank Account</h5>
                    </div>
                    <div className="mt-1 mb-3" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Swift Code</label>
                        <input type="text" id="cardNumber" value={swiftcodes} onChange={swiftcodechanges} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="input_sec" style={{ margin: '0 8%', display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'column' }}>
                        <div className="d-flex align-items-center" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p className="ps-3 banknameid" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Bank Name :</p>
                            <p className="ps-3 banknameid1" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>ECOBANK UGANDA</p>
                        </div>
                        <div className="d-flex align-items-center" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p className="ps-4 banknameid" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>City Name :</p>
                            <p className="ps-3 banknameid1" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>Kampala</p>
                        </div>
                        <div className="d-flex align-items-center" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p className="banknameid" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Branch Name :</p>
                            <p className="ps-3 banknameid1" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>Kampala</p>
                        </div>
                    </div>

                    {/* <div style={{margin:'0 8%'}}>
                        <div className="d-flex align-items-center">
                        <p className="ps-3" style={{fontWeight:'400',fontSize:'14px',lineHeight:'21px',color:'#000000'}}>Bank Name : </p>
                        <p className="ps-3" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px'}}>ECOBANK UGANDA</p>
                        </div>
                        <div className="d-flex align-items-center">
                        <p className="ps-4" style={{fontWeight:'400',fontSize:'14px',lineHeight:'21px',color:'#000000'}}>  City Name : </p>
                        <p className="ps-3" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px'}}>Kampala</p>
                        </div>
                        <div className="d-flex align-items-center">
                        <p style={{fontWeight:'400',fontSize:'14px',lineHeight:'21px',color:'#000000'}}>Branch Name :</p>
                        <p className="ps-3" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px'}}>Kampala</p>
                        </div>
                    </div> */}
                    <div className="mt-0 mb-2" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter Account Holder’s Name</label>
                        <input type="text" id="cardNumber" name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className=" mb-2" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter Account Number</label>
                        <input type="text" id="cardNumber" name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className=" mb-3" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Confirm Account Number</label>
                        <input type="text" id="cardNumber" name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="mb-2" style={{ margin: '0 8%' }}>
                        <button className={getButtonClass16()} onClick={addbank}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* bankname */}

            <Modal show={show18} dialogClassName="example-dialog15" contentClassName="example-content15" onHide={handleCloses18} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end">
                        <img src={closeicon} onClick={closedialog18} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Bank Account</h5>
                    </div>
                    <div className="mt-3 mb-1" style={{ margin: '0 8%' }}>
                        <div className="d-flex align-items-cnter justify-content-between">
                            <label htmlFor="cardNumber" className="cardlabel">Enter Bank Name</label>
                            {haveswift && (
                                <label className="mx-2 swiftknow" onClick={bankswiftcode} >I have Swift Code</label>
                            )}
                        </div>
                        <input type="text" id="cardNumber" value={bankname} onChange={banknamechange} name="cardNumber" className="form-control cardinputbox mb-4" />
                    </div>
                    <div style={{ margin: '0 8%' }}>
                        <button className={getButtonClass18()}  onClick={swiftcodesaves}>Next</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* <Modal show={show18} dialogClassName="example-dialog15" contentClassName="example-content15" onHide={handleCloses18} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end">
                        <img src={closeicon} onClick={closedialog18} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Bank Account</h5>
                    </div>
                    <div className="mt-3 mb-4" style={{ margin: '0 8%' }}>
                        <div className="d-flex align-items-cnter justify-content-between">
                            <label htmlFor="cardNumber" className="cardlabel">Enter Bank Swift Code</label>
                            {haveswift && (
                                <label className="mx-2 swiftknow" >I have Swift Code</label>
                            )}
                        </div>
                        <input type="text" id="cardNumber" value={bankname} onChange={banknamechange} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div style={{ margin: '0 8%' }}>
                        <button className={getButtonClass18()} onClick={bankcity}>Next</button>
                    </div>
                </Modal.Body>
            </Modal> */}

            <Modal show={show19} dialogClassName="example-dialog19" contentClassName="example-content19" onHide={handleCloses19} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end">
                        <img src={closeicon} onClick={closedialog19} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Bank Account</h5>
                    </div>
                    <div className="mt-1 mb-1" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter Bank Name</label>
                        <input type="text" id="cardNumber" value={bankname} onChange={banknamechange} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="mt-3 mb-1" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter City Name</label>
                        <input type="text" id="cardNumber" value={bankcityname} onChange={bankcitynamechange} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="mt-3 mb-4" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Branch Name</label>
                        <input type="text" id="cardNumber" value={bankbranchname} onChange={branchnamechange} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div style={{ margin: '0 8%' }}>
                        <button className={getButtonClass19()} onClick={bankdetails}>Next</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* <Modal show={show20} dialogClassName="example-dialog20" contentClassName="example-content20" onHide={handleCloses20} >
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end">
                        <img src={closeicon} onClick={closedialog20} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Bank Account</h5>
                    </div>
                    <div style={{ margin: '0 8%' }}>
                        <div className="d-flex align-items-center">
                            <p className="ps-3" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Bank Name : </p>
                            <p className="ps-3" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>ECOBANK UGANDA</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="ps-4" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>  City Name : </p>
                            <p className="ps-3" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>Kampala</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Branch Name :</p>
                            <p className="ps-3" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>Kampala</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="ps-4" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#000000' }}>Swift code :</p>
                            <p className="ps-3" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>ECOCUGKA</p>
                        </div>
                    </div>
                    <div className="mt-0 mb-2" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter Account Holder’s Name</label>
                        <input type="text" id="cardNumber" value={accountholdername} onChange={(e)=>setAccountholdername(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className=" mb-2" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter Account Number</label>
                        <input type="text" id="cardNumber" value={accountholdernumber} onChange={(e)=>setAccountholdernumber(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className=" mb-3" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Confirm Account Number</label>
                        <input type="text" id="cardNumber" value={accountholderconfirm} onChange={(e)=>setAccountholderconfirm(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div style={{ margin: '0 8%' }}>
                        <button className={getButtonClass20()} onClick={confirmbanksave}>Save</button>
                    </div>
                </Modal.Body>
            </Modal> */}
             

             <Modal show={show20} dialogClassName="example-dialog20" contentClassName="example-content20" onHide={handleClose20}>
    <Modal.Body style={{ margin: '0', padding: '0' }}>
        <div className="text-end">
            <img src={closeicon} onClick={closeDialog20} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px" />
        </div>
        <div className="p-2">
            <h5 className="addnewwh5 ps-4">Add Bank Details</h5>
        </div>
        <div className="mt-0 mb-2" style={{ margin: '0 8%' }}>
            <label htmlFor="countrySelect" className="cardlabel">Country</label>
            <select id="countrySelect"  value={country} onChange={(e)=>setCountry(e.target.value)} className="form-control form-select cardinputbox">
                <option value="USA">USA</option>
                <option value="UK">UK</option>
                {/* Add more options as needed */}
            </select>
        </div>
        <div className="mb-2" style={{ margin: '0 8%' }}>
            <label htmlFor="bankSelect" className="cardlabel">Bank Name</label>
            <select id="bankSelect" value={bankName} onChange={(e)=>setBankName(e.target.value)} className="form-control form-select cardinputbox">
                <option value="Bank A">Bank A</option>
                <option value="Bank B">Bank B</option>
                {/* Add more options as needed */}
            </select>
        </div>
        <div className="mb-3" style={{ margin: '0 8%' }}>
            <label htmlFor="citySelect" className="cardlabel">City Name</label>
            <select id="citySelect" value={city} onChange={(e)=>setCity(e.target.value)} className="form-control form-select cardinputbox">
                <option value="New York">New York</option>
                <option value="London">London</option>
                {/* Add more options as needed */}
            </select>
        </div>
        <div className="mb-3" style={{ margin: '0 8%' }}>
            <label htmlFor="branchSelect" className="cardlabel">Branch Name</label>
            <select id="branchSelect" value={branch} onChange={(e)=>setBranch(e.target.value)} className="form-control form-select cardinputbox">
                <option value="Branch X">Branch X</option>
                <option value="Branch Y">Branch Y</option>
                {/* Add more options as needed */}
            </select>
        </div>
        <div style={{ margin: '4% 8%' }}>
            <button className={getButtonClass20()} onClick={confirmBankSave}>Next</button>
        </div>
    </Modal.Body>
             </Modal>


            <Modal show={show21} dialogClassName="example-dialog20" contentClassName="example-content20" onHide={handleCloses20} >
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                    <div className="text-end">
                        <img src={closeicon} onClick={closedialog20} style={{ cursor: 'pointer' }} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Account Details</h5>
                    </div>
                    <div className="mt-0 mb-2" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Account Number</label>
                        <input type="text" id="cardNumber" placeholder="Enter Account Number" value={accountholdername} onChange={(e)=>setAccountholdername(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className=" mb-2" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Account Holder</label>
                        <input type="text" id="cardNumber" placeholder="Enter Account Holder" value={accountholdernumber} onChange={(e)=>setAccountholdernumber(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className=" mb-3" style={{ margin: '0 8%' }}>
                        <label htmlFor="cardNumber" className="cardlabel">Phone Number</label>
                        <input type="text" id="cardNumber" placeholder="Enter Phone Number" value={accountholderconfirm} onChange={(e)=>setAccountholderconfirm(e.target.value)} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div style={{ margin: '4% 8%' }}>
                        <button className={getButtonClass20()} onClick={confirmbanksave}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    );
}
export default Bank;