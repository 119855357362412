import axiosInstance from "../api/commonfunct.js/axiosinstance";
import { getCardsapi, getmobilemoneysapi, getorganzationapi } from "../api/url";
const getCardsapiurl = getCardsapi();
const getmobilemoneysapiurl = getmobilemoneysapi();
const getorganzationapiurl = getorganzationapi();
export const getcards = async() => {
    return await axiosInstance.get(getCardsapiurl);
}
export const getmobilemoney = async() => {
    return await axiosInstance.get(getmobilemoneysapiurl);
} 
export const getorganization = async() => {
    return await axiosInstance.get(getorganzationapiurl);
} 