import React, { useState , useEffect } from "react";
import Navbars from "./navbar";
import Systemsidebar from "./systemsidebar";
import Wallettowallet from "./wallettowallet";
import Wallettomobilemoney from "./wallettomobilemoney";
import Wallettobank from "./wallettobank";
import Wallettopaypal from "./wallettopaypal";
import Giftcards from "./giftcards";
import Purchase from "./purchase";
import Tickets from "./tickets";
import Subscription from "./subscription";
import Contribution from "./contribution";
import Payout from "./payout";
import Refund from "./refund";
function Ctransfer(){
    // const [source2 , setSource2] = useState('purchase');
    const [source2, setSource2] = useState(() => {
        const storedActiveSection = localStorage.getItem('activeSection');
        return storedActiveSection || 'purchase'; // Set the default section if none is found in localStorage
      });
      useEffect(() => {
        localStorage.setItem('activeSection', source2);
      }, [source2]);
    return(
        <>
         <Navbars/>
        <div className="container-fluid addsourcesdiv" style={{backgroundColor:'#F8F8F8',width:'100%',minHeight:'100vh'}}>
            <div className="row collectionrow">
                <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2 collectionmanual">
                    <Systemsidebar setSource2={setSource2} source2={source2}/>
                </div>
                {source2 === "wallettowallet" && 
                <Wallettowallet />}
                 {source2 === "wallettobank" && 
                <Wallettobank />}
                {source2 === "wallettomobilemoney" && 
                <Wallettomobilemoney />}
                 {source2 === "wallettopaypal" && 
                <Wallettopaypal />}
                 {source2 === "giftcards" && 
                <Giftcards />}
                 {source2 === "purchase" && 
                <Purchase />}
                 {source2 === "tickets" && 
                <Tickets />}
                {source2 === "subscription" && 
                <Subscription />}
                {source2 === "contribution" && 
                <Contribution />}
                {source2 === "payout" && 
                <Payout />}
                {source2 === "refund" && 
                <Refund />}
            </div>
           </div>
        </>
    );
}
export default Ctransfer;