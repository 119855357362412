  
 function domain()
{
    return process.env.REACT_APP_API_URL;
}
export function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;  
}
export function staticimage()
{
    return process.env.REACT_APP_STATIC_PUBLIC_URL;
}
export function loginapi()
{
   return domain() + "/api/v1.0/app/core/auth/login";
}
export function signupapi()
{
    return domain() + "/api/v1.0/app/core/auth/signup";
}
export function country_codeapi()
{
    return domain() + "/api/v1.0/app/core/general/mcc/list/all";
}
export function countryselectapi()
{
    return domain() + "/api/v1.0/app/core/general/country/list/all";
}
export function forgototpapi()
{
    return domain() + "/api/v1.0/app/core/auth/forgot/password";
}
export function verifyotpapi()
{
    return domain() + "/api/v1.0/app/core/auth/forgot/password/verify-otp";
}
export function resendotpapi()
{
    return domain() + '/api/v1.0/app/core/auth/resend-otp';
}
export function resetpasswordsetapi()
{
    return domain() + '/api/v1.0/app/core/auth/reset/password';
}
export function signupverifyapi()
{
    return domain() + '/api/v1.0/app/core/auth/verify-otp';
}
export function profileapi()
{
    return domain() + '/api/v1.0/app/core/user/profile';
}
export function refreshapi()
{
    return domain() + '/api/v1.0/app/core/auth/refresh/token';
}
export function dateofbirthapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update';
}
export function profileremoveapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/remove/image';
}
export function genderapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update';
}
export function profileupdateotpapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update/send-otp';
}
export function profileupdateauthapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update/auth';
}
export function statelistapi()
{
    return domain() + '/api/v1.0/app/core/general/state/list/all';
}
export function citylistapi()
{
    return domain() + '/api/v1.0/app/core/general/city/list/all';
}
export function locationtypesapi()
{
    return domain() + '/api/v1.0/app/core/user/location/types';
}
export function locationstoreapi()
{
    return domain() + '/api/v1.0/app/core/user/location/store';
}
export function getuserlocationapi()
{    
    return domain() + '/api/v1.0/app/core/user/locations' 
}    
export function getuserdeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/location/delete';
}
export function languagelistapi()
{
    return domain() + '/api/v1.0/app/core/user/language/list/all';
}
export function languagestoreapi()
{
    return domain() + '/api/v1.0/app/core/user/language/store';
}
export function languagegetapi()
{
    return domain() + '/api/v1.0/app/core/user/languages';
}
export function languagepreferredapi()
{
    return domain() + '/api/v1.0/app/core/user/langauge/preferred';
}


// payment 
export function paymantdomain()
{
    return process.env.REACT_APP_PAYMENT_API_URL;
}
export function paymantdomainuser()
{
    return paymantdomain() + '/user'
}
export function getkycapi()
{
    return paymantdomainuser() + '/get/kyc';
}
export function addkycapi()
{
    return paymantdomainuser() + '/add/kyc';
}
export function getkycprofileapi()
{
    return paymantdomainuser() + '/get/kyc/profile';
}
export function updatekycprofileapi()
{
    return paymantdomainuser() + '/update/kyc/profile';
}
export function updatekycpasscodeapi()
{
    return paymantdomainuser() + '/update/kyc/passcode';
}
export function getkycpasscodeapi()
{
    return paymantdomainuser() + '/get/kyc/passcode';
}
export function getkyctermsapi()
{
    return paymantdomainuser() + '/get/kyc/terms/conditions';
}
export function updatekyctermsapi()
{
    return paymantdomainuser() + '/update/kyc/terms/conditions';
}
export function getkycemailmobileapi()
{
    return paymantdomainuser() + '/get/kyc/email/mobile';
}
export function mcclistapi()
{
    return domain() + '/api/v1.0/app/core/general/mcc/list/all';
}
export function kycsendotpapi()
{
    return paymantdomainuser() + '/kyc/send/otp';
}
export function kycverifyotpapi()
{
    return paymantdomainuser() + '/kyc/verify/otp';
}
export function updatekycemailmobileapi()
{
    return paymantdomainuser() + '/update/kyc/email/mobile';
}
export function getkycrecoveryapi()
{
    return paymantdomainuser() + '/get/kyc/recovery/email/mobile';
}
export function kycrecoverysendotpapi()
{
    return paymantdomainuser() + '/kyc/recovery/send/otp';
}
export function kycrecoveryverifyotpapi()
{
    return paymantdomainuser() + '/kyc/recovery/verify/otp';
}
export function updatekycrecoveryemailmobileapi()
{
    return paymantdomainuser() + '/update/kyc/recovery/email/mobile';
}
export function ipapi(){
    return domain() + "/api/v1.0/app/core/general/ip";
}
export function getkycemergencyapi()
{
    return paymantdomainuser() + "/get/kyc/emergency/contacts";
}
export function getemergencyrelationlistapi()
{
    return domain() + "/api/v1.0/app/core/user/emergency/relation";
}
export function updateemergencycontactsapi()
{
    return paymantdomainuser() + "/update/kyc/emergency/contact";
}
export function updatekycemergencycontacsapi()
{
    return paymantdomainuser() + "/update/kyc/emergency/contacts";
}
export function userupdatekycapi()
{
    return paymantdomainuser() + "/update/kyc";
}
export function deletekycemergencycontactapi()
{
    return paymantdomainuser() + "/delete/kyc/emergency/contact";
}
export function getkycnationaldocumentapi()
{
    return paymantdomainuser() + '/get/kyc/national/documents';
}
export function updatekycnationaldocumentapi()
{
    return paymantdomainuser() + '/update/kyc/national/document';
}
export function getkycgeneraldocumentlistapi()
{
    return paymantdomainuser() + '/get/kyc/general/documents';
}
export function updatekycgeneraldocumnetapi()
{
    return paymantdomainuser() + '/update/kyc/general/document';
}
export function deletegeneraldocumentapi()
{
    return paymantdomainuser() + '/delete/kyc/general/document';
}
export function deletenationaldocumentapi()
{
    return paymantdomainuser() + '/delete/kyc/national/document';
}
export function getCardsapi()
{
    return paymantdomainuser() + '/get/cards';
}
export function addcardsapi()
{
    return paymantdomainuser() + '/add/card';
}
export function updatecardsapi()
{
    return paymantdomainuser() + '/update/card';
}
export function deletecardsapi()
{
    return paymantdomainuser() + '/delete/card';
}
export function getmobilemoneysapi()
{
    return paymantdomainuser() + '/get/mobilemoneys';
}
export function getorganzationapi()
{
    return paymantdomainuser() + '/get/organizations';
}
export function addorganizationapi()
{
    return paymantdomainuser() + '/add/organization';
}
export function updateorganizationapi()
{
    return paymantdomainuser() + '/update/organization';
}
export function deleteorganizationapi()
{
    return paymantdomainuser() + '/delete/organization';
}
// export function getbankapi()
// {
//     return paymantdomainuser() + ''
// }