import React, { useState } from "react";
import Registerservices from "./registerservices";
import Navbars from "./navbar";
import Modal from 'react-bootstrap/Modal';
import closeicon from './images/times-circle-01.png';
import plus from './images/plus.png';
import trash  from './images/paypal/trash-01.png';
import edit from './images/paypal/pen-01.png';
function Mobilemoney(){
    const [show13 , setShow13] =useState(false);
    const [mobilemoney , setMobilemoney] = useState('');
    const closedialog13 = () => {
        setShow13(false);
        setMobilemoney('');
    }
    const handleCloses13 = () => {
        setShow13(false);
        setMobilemoney('');
    }
    const mobilemoneychange = (e) => {
        setMobilemoney(e.target.value)
    }
    const addnewmobilemoney = () => {
        setShow13(true);
    }
    const getButtonClass13 = () => {
        return mobilemoney > " " ? 'addnewsaveafter' : 'addnewsave'; 
    }
    const mobilemoneyootp = () => {
        setShow13(false);
        setShow14(true);
    }
    const [show14 , setShow14] = useState(false);
    const [mobileotp , setMobileotp] = useState('');
    const handleCloses14 = () => {
        setShow14(false);
        setMobilemoney('');
        setMobileotp('');
    }
    const closedialog14 = () => {
         setShow14(false);
         setMobilemoney('');
         setMobileotp('');
    }
    const mobileotpchange = (e) => {
        setMobileotp(e.target.value);
    }
    const getButtonClass14 = () => {
         return mobileotp > '' ? 'addnewsaveafter' : 'addnewsave'
    }
    const mobilemoneyotpsave = () => {
        setShow14(false);
        setMobileotp('');
        setMobilemoney('');
    } 

    return(
        <>
          {/* <Navbars/>
        <div className="container-fluid " style={{backgroundColor:'#F8F8F8',width:'100%',height:'100%'}}>
            <div className="row pt-4">
                <div className="col-lg-3 col_corre_2">
                    <Registerservices/>
                </div> */}
                <div className="col-lg-9 col_corr_1">
                    <main className="cardsmaindiv" style={{backgroundColor:'#FFFFFF',width:'100%',boxShadow:"0px 0px 5px 0px #0000001A",height:'100%',minHeight:'400px'}}> 
                    <h5 className="ps-4 pt-4 registerservicecard">Mobile Money</h5>
                       <p className="cardsp ps-4 mb-4 registerservicecard">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr" style={{width:'100%',margin:'0 2.5%',maxWidth:'95%',minHeight:'400px',border:'1px solid #7D7878', }}>
                            <div className="d-flex align-items-center justify-content-between p-4">
                         <h5 className="paypalh5" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#494949'}}>Mobile Money</h5>
                        <button className="addnewcards" onClick={addnewmobilemoney}><img src={plus} className="me-2 paypalplus" alt="plus" width='15px' height='15px'></img>Add mobile money</button>
                    </div>
                    <div className="d-flex radio align-items-center w-100 paypalradio" style={{padding:'0px 30px'}}>
                      <input type="radio"  id="myRadio" style={{marginRight:'20px',}}></input>
                      <div className="paybox">
                        <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                              <p className="paymobilename">Mobile Number</p>
                             <p className="paymobilenum">256*****1234</p>
                        </div>
                        <div className="d-flex align-items-center">
                        <img src={edit} className="me-3 paypaldelete paypaldelete1" alt="edit"></img>
                         <img src={trash} className="paypaldelete" alt="trash"></img>
                        </div>
                        </div>
                        </div>
                        </div>
                       </div>
                    </main>
                </div>
            {/* </div>
           </div> */}
           <Modal show={show13} dialogClassName="example-dialog13" contentClassName="example-content13" onHide={handleCloses13} centered>
                <Modal.Body style={{ margin: '0', padding: '0'}}>
                <div className="text-end">
                        <img src={closeicon} onClick={closedialog13} style={{cursor:'pointer'}} className="closeicon mx-1 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Mobile Money</h5>
                    </div>
                    <div className="mt-3 mb-4" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Mobile Number</label>
                        <input type="text" id="cardNumber" value={mobilemoney} onChange={mobilemoneychange} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                        <div className="mb-3" style={{margin:'0 7.7%'}}>
                        <button className={getButtonClass13()} onClick={mobilemoneyootp}>Submit & Save</button>
                    </div>
                </Modal.Body> 
            </Modal>

            <Modal show={show14} dialogClassName="example-dialog14" contentClassName="example-content14" onHide={handleCloses14} centered>
                <Modal.Body style={{ margin: '0', padding: '0'}}>
                <div className="text-end">
                        <img src={closeicon} onClick={closedialog14} style={{cursor:'pointer'}} className="closeicon mx-2 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add Mobile Money</h5>
                    </div>
                    <div className="mt-1 mb-2" style={{margin:'0 8%'}}>
                    <p className='resetpassp resetpassp1'>Enter the 6-didit code sent<br></br>
                                          to  <strong>+251 *************56.</strong> Did not receive<br></br>
                                          OTP? <strong style={{ cursor:'not-allowed',color:'#8F9394'}}>Resend OTP</strong></p>
                        {/* <label htmlFor="cardNumber" className="cardlabel">Mobile Number</label>
                        <input type="text" id="cardNumber" value={mobilemoney} onChange={mobilemoneychange} name="cardNumber" className="form-control cardinputbox" /> */}
                    </div>
                    <div className="mt-2 mb-1" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Enter OTP</label>
                        <input type="text" id="cardNumber" value={mobileotp} onChange={mobileotpchange} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    {/* <div className="text-end mt-0" style={{margin:'0 10%'}}>
                    <p className="resendmobileotp">Resend Code</p>
                    </div> */}
                    <div className='mt-3 mb-3 d-flex justify-content-center w-100'>
                                          <button type="button" className='resendotp'> {`120 S`}
                                          </button>
                                      </div>
                        <div className="mb-3" style={{margin:'0 8%'}}>
                        <button className={getButtonClass14()} onClick={mobilemoneyotpsave}>Submit & Save</button>
                    </div>
                </Modal.Body> 
            </Modal>
            
        </>
    );
}
export default Mobilemoney;