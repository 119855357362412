import { useParams } from "react-router-dom";
import { useState,useCallback,useEffect } from "react";
import dayjs from 'dayjs';
export const Error401 = () => {
  const lod4 = useRouteParams();
  window.location.href = `/refresh?X-Authuser=${encodeURIComponent(lod4)}`;
  return lod4;
}
export const convertImageToBase64 = (file, setImage, setFileSize, setFileType) => {
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setImage([reader.result]);
    };
  
    if (file) {
      reader.readAsDataURL(file);
      setFileSize(file.size);
      // const truncatedFileType = file.name.length > 10 ? file.name.substring(0, 10) + '...' : file.name;
      const fileExtension = file.name.split('.').pop();
  
      // Truncate file name to 7 characters and add ellipsis if it exceeds 7 characters
      const truncatedFileType = file.name.split('.').slice(0, -1).join('.').length > 7
        ? file.name.split('.').slice(0, -1).join('.').substring(0, 7) + '...'
        : file.name.split('.').slice(0, -1).join('.');
  
      setFileType(truncatedFileType);
    }
  };
  export const formatFileSize = (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;
  
    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else {
      return `${sizeInKB.toFixed(2)} KB`;
    }
  };
export const handleFileChange = (
    e,
    setImage,
    setFileSize,
    setFileType,
    setProgress,
    setProgressColor,
    setUploading,
    setFormat,
  ) => {
    const selectedFile = e.target.files[0];
    const fileName = selectedFile.name;
    const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1);
    setFormat(fileFormat);
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
    const updatedDocname = `${fileNameWithoutExtension}`;
    // setDocname(updatedDocname);
    convertImageToBase64(
      selectedFile,
      (image) => {
        // Callback function for setting the image state
        setImage(image);
      },
      (size) => setFileSize(size), // Callback function for setting file size
      (type) => setFileType(type), // Callback function for setting file type
    );
  
    const totalSteps = 100;
    const processingSteps = 70;
    const stepInterval = 100;
    let currentStep = 0;
    setUploading(true);
    const progressInterval = setInterval(() => {
      currentStep += 50;
      const currentProgress = (currentStep / totalSteps) * 100;
      setProgress(currentProgress);
      if (currentStep === totalSteps) {
        clearInterval(progressInterval);
        setTimeout(() => {
          setProgressColor("#28a745");
          setUploading(true);
          // setEditImage(true);
        }, 500);
      } else if (currentStep === processingSteps) {
        setUploading(true);
      }
    }, stepInterval);
  };
  
  export const handleDragOver = (e) => {
    e.preventDefault();
  };
  export const handleDrop = (e, handleFileDrop) => {
    e.preventDefault();
  
    const droppedFiles = e.dataTransfer.files;
  
    if (droppedFiles.length > 0) {
      const selectedFile = droppedFiles[0];
      handleFileDrop(selectedFile);
    }
  };
  export const truncateModel = (model) => {
    if (model && model.length) {
      return model.length > 4 ? model.substring(0, 4) + '...' : model;
    } else {
      return ''; // or any default value you want to return when model is null or undefined
    }
  };
  export const handleDragOver1 = (e) => {
    e.preventDefault();
  };
  export const handleDrop1 = (e, handleEditFileDrop) => {
    e.preventDefault();
  
    const droppedFiles = e.dataTransfer.files;
  
    if (droppedFiles.length > 0) {
      const selectedFile1 = droppedFiles[0];
      handleEditFileDrop(selectedFile1);  
    }
  };
  export const handleEditfilechange = (
    e,
    setEditImage,
    setFileSize,
    setFileType,
    setEditProgress,
    setEditProgressColor,
    setEditImageDetails,
    setFormat,
  ) => {
    const selectedFile1 = e.target.files[0];
    const fileName = selectedFile1.name;
    const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1);
    setFormat(fileFormat);
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
    const updatedDocname = `${fileNameWithoutExtension}`;
    // setEditDocName(updatedDocname);
  
    convertImageToBase64(
      selectedFile1,
      (image) => {
        setEditImage(image);
      },
      (size) => setFileSize(size),
      (type) => setFileType(type)
    );
    setEditProgressColor('#28a745');
      const totalSteps = 100;
      const processingSteps = 70;
      const stepInterval = 100;
      let currentStep = 0;
      setEditImageDetails(false);
      const progressInterval = setInterval(() => {
        currentStep += 50;
        const currentProgress = (currentStep / totalSteps) * 100;
        setEditProgress(currentProgress);
        if (currentStep === totalSteps) {
          clearInterval(progressInterval);
          setTimeout(() => {
            setEditProgressColor('#28a745');
            setEditImageDetails(false);
          }, 500);
        } else if (currentStep === processingSteps) {
          setEditImageDetails(false);
        }
      }, stepInterval);
  };
  export const getMaskedEmail = (email) => {
    if (!email) {
      return null;
    }
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 1);  
    return `${maskedUsername}@${domain}`;
  };

 export const getMaskedPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return null;
    }
  
    const lastTwoDigits = phoneNumber.slice(-2);
  
    return `**********${lastTwoDigits}`;
  };
  export const error401 = () => {
    localStorage.removeItem('access_token');
}

 export const useRouteParams = () => {
  const params = useParams();
  const user = params.user
  return user;
}

export const handleApiError = (error, setIsLoading=null, setError422 = null, handle422AsToast = true, showErrorToast) => {
  if (error.response) {
    if (error.response.status === 401) {
      const error_code = error.response.data.error_code;
      if (error_code === 'token_expired' || error_code === 'already_logged_out' ) {
        Error401();
      }else if(error_code === 'session_error'){
        window.location.href = 'https://accounts.dev.evzone.app/'
      }else {
        showErrorToast(error.response.data.mobile_error, '401Error');
      }
    } else if (error.response.status === 422) {
      if (handle422AsToast) {
        showErrorToast(error.response.data.mobile_error, '422Error');
      } else if (setError422) {
        setError422(error.response.data.mobile_error);
      }
    } else if (error.response.status === 500) {
      showErrorToast(error.response.data.mobile_error, '500Error');
    } else {
      showErrorToast('An unexpected error occurred.', 'UnexpectedError');
    }
  }
  if (typeof setIsLoading === 'function') {
    setIsLoading(false);
  }
};

export const customStyles1 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'45px',
    fontSize:'14px',
    borderRaius:'6px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),placeholder: (provided) => ({
    ...provided,
    color: '#ABABAB',
    width: '100%',
    fontSize:'14px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
export const customStyles2 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'40px',
    fontSize:'14px',
    borderRaius:'6px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),placeholder: (provided) => ({
    ...provided,
    color: '#ABABAB',
    width: '100%',
    fontSize:'13px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
export const CustomSuffixIcon = ({isActive}) => (
  <span className="ant-picker-suffix-icon">
    <svg viewBox="64 64 896 896" fill={isActive ? 'hsl(0, 0%, 20%)' : '#DCDCDC'} focusable="false" data-icon="calendar" width="1em" height="1em"  aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg>
  </span>
);

export const handleKeyDown = (e) => {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "/", "Tab"];
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};


export const calculateMenuHeight = (offset = 200, maxHeight = 400) => {
  const availableHeight = window.innerHeight - offset;
  return `${Math.min(availableHeight, maxHeight)}px`;
};

const useMenuHeight = (offset = 200, maxHeight = 400) => {
  const [menuHeight, setMenuHeight] = useState(calculateMenuHeight(offset, maxHeight));

  const updateMenuHeight = useCallback(() => {
    setMenuHeight(calculateMenuHeight(offset, maxHeight));
  }, [offset, maxHeight]);

  useEffect(() => {
    updateMenuHeight(); // Initial height calculation
    window.addEventListener('resize', updateMenuHeight); // Listen for resize events

    return () => {
      window.removeEventListener('resize', updateMenuHeight); // Cleanup
    };
  }, [updateMenuHeight]);

  return menuHeight;
};

export default useMenuHeight;

export const handleKeyPress = (e) => {
  // Allow only numeric characters (0-9) and some specific control keys
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
export const disableFutureDates = (current) => {
  return current && current > dayjs().endOf('day');
};

// Function to disable past dates based on the selected Date of Issue
export const disablePastDates = (referenceDate) => (current) => {
  return current && current <= dayjs(referenceDate).startOf('day');
};

export const allfunctions = (nextFunctions = []) => {
  return new Promise((resolve, reject) => {
    makeAjaxCall()
      .then(() => {
        // Execute the provided functions sequentially
        if (Array.isArray(nextFunctions)) {
          nextFunctions.forEach(func => {
            if (typeof func === 'function') {
              func(); // Call each function
            }
          });
        }
        resolve(); // Resolve after all functions are called
      })
      .catch(error => {
        handleApiError(error);
        reject(error); // Reject in case of an error
      });
  });
};

// export const allfunctions = (nextFunctions = []) => {
//   if (Array.isArray(nextFunctions)) {
//             nextFunctions.forEach(func => {
//               if (typeof func === 'function') {
//                 func(); // Call each function
//               }
//             });
//           }
//         } 
