import { createStore, applyMiddleware ,combineReducers } from 'redux';
import thunk from 'redux-thunk';
import userProfile from './userProfiles/reducers';
import userLocation from './userLocations/userlocationreducer';

const rootReducer = combineReducers({
  userProfile: userProfile,
  userLocation: userLocation,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

