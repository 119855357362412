import React from "react";
import Navbars from "./navbar";
import Manualsidebar from "./manualsidebar";
import folderexport from './images/folder-export-01.png';
import search01 from './images/search-01.png';
import doller from './images/wallet-iconsss/dollar-receive-circle.svg';
import paid from './images/wallet-iconsss/Paid.svg';
function Systemrefund(){
    return(
        <>
        {/* <Navbars/>
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row systemrefundrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Manualsidebar />
                    </div> */}
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1 collectionmanual"  >
                        <main className="mobile_height" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", height: '100%', }}>
                        <div className="collectdiv">
                            <h5 className="ps-4 pt-4">Refund</h5>
                            <p className="cardsp ps-4 mb-4">This is a system "D Transfer" that happens, when funds bounce back to the app user's wallet, after
                               initially participating in a "C Transfer" of the same amount</p>
                            </div>
                            <div className="massage_box_corr mobile_maxheight ">
                                <div className="purchaserow22 purchase23 after-one">
                                    <h5 className="refundhistory ps-4 pt-3">Refund History</h5>
                                    <div className="d-flex justify-content-between ps-4 pe-4 pb-4 dis-block-t">
                                        <div style={{position:"relative",width:'100%'}}>
                                        <input type="search" className="serchinputbox" placeholder="Search"></input>
                                        <p  style={{ position: 'absolute',marginLeft:'5px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={search01} alt="search" width='19.5px' height='19.5px'></img></p> 
                                        </div>
                                        <button className="export_pdf orange"><img src={folderexport} className="me-2 mb-1" alt="folderexport" width="15px" height='14px'></img>Export to PDF</button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table   nowrap custom-table custom-tablerefund"style={{width:'100%'}}>
                                            <colgroup>
                                                <col style={{ width: '12.5%' }} /> {/* Adjust the width as needed */}
                            <col style={{ width: '12.5%' }} />
                            <col style={{ width: '12.5%' }} />
                            <col style={{ width: '12.5%' }} />
                            <col style={{ width: '12.5%' }} />
                            <col style={{ width: '12.5%' }} />
                            <col style={{ width: '12.5%' }} />
                            <col style={{ width: '12.5%' }} />
                                                </colgroup>
                                                <thead style={{backgroundColor: '#FFEEDC'}}>
                                                    <tr style={{ backgroundColor: '#FFEEDC', border: 'none'}}>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Refund ID</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Refund Reason</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Request Date</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Form</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Processed Date</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Refund Amount</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Refund Status</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Paid in Error</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>-</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Pending</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><button className="refundaccept orange">Request</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Cancel</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><button className="refundaccept orange">Request</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr><tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr><tr>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>RFND_123</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} >Duplicate Payment</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>John@domain.com</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>18/08/2023</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>UGX 3000</td>
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}>Refunded</td>
                                                        <td style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px', color: '#0897FF',textAlign:'center'}}>Paid</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="massage_box_corrmobile1">
                            <div className="massage_box_corrmobile">
                            <div style={{position:'relative',width:'100%'}}>
                            <input type="search" className="serchinputbox" placeholder="Search"></input>  
                            <p  style={{ position: 'absolute',left:'10px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={search01} alt="search" width='16.5px' height='auto'></img></p> 
                            </div> 
                            <p className="massage_box_corrmobilep">Today</p>
                            <div className="collectbox-div">
                                            <div className="d-flex justify-content-between">
                                             <div>   
                                            <p className="descmobiletypep">Refund ID : <span className="descmobiletypep descmobiletypep1">RFND_123</span></p>
                                            <p className="descmobiletypep">Refund Reason : <span className="descmobiletypep descmobiletypep1">Paid in Error</span></p>
                                            <p className="descmobiletypep">Request Date : <span className="descmobiletypep descmobiletypep1">18/08/2023</span></p>
                                            <p className="descmobiletypep">From : <span className="descmobiletypep descmobiletypep1">John@domain.com</span></p>
                                            <p className="descmobiletypep">Processed Date : <span className="descmobiletypep descmobiletypep1">21/08/2023</span></p>
                                            <p className="descmobiletypep">Refund Status : <span className="descmobiletypep descmobiletypep1">Pending</span></p>
                                            </div>
                                            <div className="text-end">
                                            <p className="collectionmobileamoutp">Refund Amount</p>
                                                <p className="collectionmobileamoutgreen">UGX 3000</p>
                                            </div>
                    
                                          </div>
                                          <div className="collectionrefund"></div> 
                                          <button className="collectbox-footexport"><img src={doller} alt="export" className="me-2" width='16px' height='16px'/>Request</button>   
                            </div>
                            <div className="collectbox-div">
                                            <div className="d-flex justify-content-between">
                                             <div>   
                                            <p className="descmobiletypep">Refund ID : <span className="descmobiletypep descmobiletypep1">RFND_123</span></p>
                                            <p className="descmobiletypep">Refund Reason : <span className="descmobiletypep descmobiletypep1">Duplicate Payment</span></p>
                                            <p className="descmobiletypep">Request Date : <span className="descmobiletypep descmobiletypep1">18/08/2023</span></p>
                                            <p className="descmobiletypep">From : <span className="descmobiletypep descmobiletypep1">John@domain.com</span></p>
                                            <p className="descmobiletypep">Processed Date : <span className="descmobiletypep descmobiletypep1">21/08/2023</span></p>
                                            <p className="descmobiletypep">Refund Status : <span className="descmobiletypep descmobiletypep1">Pending</span></p>
                                            </div>
                                            <div className="text-end">
                                            <p className="collectionmobileamoutp">Refund Amount</p>
                                                <p className="collectionmobileamoutgreen">UGX 3000</p>
                                            </div>
                    
                                          </div>
                                          <div className="collectionrefund"></div> 
                                          <button className="collectbox-footexportblue"><img src={paid} alt="export" className="me-2" width='16px' height='16px'/>Paid</button>   
                            </div>
                            </div>    
                            <div className="collectbox-foot">
                                            <button className="collectbox-footexport"><img src={folderexport} alt="export" className="me-2" width='15px' height='14px'></img>Export to pdf</button>
                                        </div>
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}
        </>
    );
}
export default Systemrefund;