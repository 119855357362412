// import {
//   FETCH_USER_LOCATION_REQUEST,
//   FETCH_USER_LOCATION_SUCCESS,
//   FETCH_USER_LOCATION_FAILURE,
// } from './userlocationactions';

// const initialState = {
//   userLocation:{
//   name: '',
//   dob: '',
//   gender: '',
//   email: '',
//   mobile: '',
//   mcc_id: '',
//   mcc_countrycode: '',
//   },
//   loading: false,
//   error: null,
// };

// const userLocationReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case FETCH_USER_LOCATION_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case FETCH_USER_LOCATION_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         ...action.payload, // Update user location with fetched data
//       };
//     case FETCH_USER_LOCATION_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default userLocationReducer;

// userLocationReducer.js

import {
  FETCH_USER_LOCATION_REQUEST,
  FETCH_USER_LOCATION_SUCCESS,
  FETCH_USER_LOCATION_FAILURE,
  DELETE_ADDRESS_FAILURE,
  DELETE_ADDRESS_SUCCESS
} from './userlocationactions';

const initialState = {
  userLocation: {
     addressline_1 : ''
  },
  loading: false,
  error: null,
};

const userLocation = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        userLocation: action.payload, // Corrected payload assignment
      };
    case FETCH_USER_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case DELETE_ADDRESS_SUCCESS:
        // Remove the deleted address from the userLocation object
        const updatedAddresses = state.userLocation.addressline_1.filter(
          (address) => address.id !== action.payload
        );
        return {
          ...state,
          userLocation: {
            ...state.userLocation,
            addressline_1: updatedAddresses,
          },
        };
        case DELETE_ADDRESS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default userLocation;



