// Import axios and other necessary dependencies
import axios from 'axios';
import {  getuserlocationapi , getuserdeleteapi} from '../../url';
const getuserlocationapiurl = getuserlocationapi();
const getuserdeleteapiurl = getuserdeleteapi();
// Define action types
export const FETCH_USER_LOCATION_REQUEST = 'FETCH_USER_LOCATION_REQUEST';
export const FETCH_USER_LOCATION_SUCCESS = 'FETCH_USER_LOCATION_SUCCESS';
export const FETCH_USER_LOCATION_FAILURE = 'FETCH_USER_LOCATION_FAILURE';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE'; 

// Define action creators
export const fetchUserLocationRequest = () => ({
  type: FETCH_USER_LOCATION_REQUEST,
});

export const fetchUserLocationSuccess = (userLocation) => ({
  type: FETCH_USER_LOCATION_SUCCESS,
  payload: userLocation,
});

export const fetchUserLocationFailure = (error) => ({
  type: FETCH_USER_LOCATION_FAILURE,
  payload: error,
});

export const deleteAddressSuccess = (addressId) => ({
  type: DELETE_ADDRESS_SUCCESS,
  payload: addressId,
});


export const deleteAddressFailure = (error) => ({
  type: DELETE_ADDRESS_FAILURE,
  payload: error,
});


// Define the async action to fetch user location
export const fetchUserLocation = () => {
  return async (dispatch) => {
    dispatch(fetchUserLocationRequest());

    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.get(getuserlocationapiurl, { headers });

      if (response.status === 200) {
        const data = response.data.data;
        dispatch(fetchUserLocationSuccess({
          addressline_1 : data
        }));
      }
      
    } catch (error) {
      console.error('Error in fetchUserLocation:', error);
      if (error.response && error.response.status === 401) {
        window.location.href = ('/');
      }
      dispatch(fetchUserLocationFailure(error));
    }
  };
};


export const deleteAddress = (addressId) => {
  return async (dispatch) => {
    const token = localStorage.getItem('access_token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const data = JSON.stringify({
        id:addressId
      })
    try {
       await axios.post(getuserdeleteapiurl,data,{headers});
      dispatch(deleteAddressSuccess(addressId));
      
    } catch (error) {
      console.error('Error in fetchUserLocation:', error);
      if (error.response && error.response.status === 401) {
        // window.location.href = ('/');
      }
    }
  };
};