import React, { useState } from "react";
import Navbars from "./navbar";
import Manualsidebar from "./manualsidebar";
import wallettowallet from "./images/wallet_icons/gift_card-01.png";
import folderexport from "./images/folder-export-01.png";
import search01 from './images/search-01.png';
import Modal from 'react-bootstrap/Modal';
import process from './images/Payment_Process.gif';
import success from './images/Payment_Successful (1).gif';
import timecircle from './images/times-circle-01.png';
import paid from './images/wallet-iconsss/credit-card-check.svg';
import bankimg from './images/Group 943.png';
import layer from './images/wallet-iconsss/user-ban.svg';
function Manualgiftcard(){
    const [show3 , setShow3] = useState(false);
    const [amount , setAmount] = useState('');
    const [receiver , setReceiver] = useState('');
    const [expiry , setExpiry] = useState('');
    const [frezze , setFrezze] = useState('');
    const gift = () => {
        setShow3(true);
    }
    const close1 = () => {
     setShow3(false);
     setAmount('');
     setExpiry('');
     setFrezze('');
     setReceiver('');
    }
    const handleCloses3 = () => {
     setShow3(false);
     setAmount('');
     setExpiry('');
     setFrezze('');
     setReceiver('');
    }
    const getButtonClass1 = () => {
        return amount && receiver && expiry && frezze  > " " ? 'withdrawamounbutton active2 ' : 'withdrawamounbutton ';
    }
    const [apiStatus , setApiStatus] = useState('processing');
    const transfer = () => {
    setShow3(false);
    setShow4(true);
     setTimeout(() => {
        setApiStatus('processing');
        setTimeout(() => {
            setApiStatus('success');
        }, 2000)
    }, 10000);
    }
    const [show4 , setShow4] = useState(false);
    
    const handleCloses4 = () => {
      setShow4(false);
    }
    const paynowsuccess = () => {
      setShow4(false);
      setAmount('');
      setExpiry('');
      setFrezze('');
      setReceiver('');
    }
    
     return(
        <>
         {/* <Navbars/>
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row manualgiftrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Manualsidebar/>
                    </div> */}
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1 collectionmanual">
                        <main className="mobile_height" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", minHeight: '100vh', }}>
                        <div className="collectdiv">
                            <h5 className="ps-4 pt-4">Gift Card</h5>
                            <p className="cardsp ps-4 mb-4 collectionsh5">This id a system "C Transfer" that happens when an app user sends a gift to another app
                                user, through another service type but not within the wallet</p>
                            </div>
                            <div className="massage_box_corr mobile_maxheight ">
                                <div className="purchaserow22 purchase23 after-one">
                                    <h5 className="refundhistory ps-4 pt-3">All Transactions</h5>
                                    <div className="d-flex justify-content-between align-items-center ps-4 pb-3">
                                    <div style={{position:"relative",width:'100%'}}>
                                    <input type="search" className="serchinputbox" placeholder="Search"></input>
                                        <p  style={{ position: 'absolute',marginLeft:'5px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={search01} alt="search" width='19.5px' height='19.5px'></img></p> 
                                        </div>
                                 <div className="b__n">       
                                    <p className="walletinitiate">Issue Card</p>
                                    <div className="button_column">
                                        <button className="export_pdf export_pdf1 me-md-4" onClick={gift}>Ineligible</button>
                                        <button className="export_pdf orange me-4"><img src={folderexport} className="me-2 mb-1" alt="folderexport" width="15px" height='14px'></img>Export to PDF</button>
                                </div>
                            </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                            <table className="table customheadpad  nowrap custom-table">
                                            <colgroup>
                                                <col style={{ width: '16.66%' }} /> {/* Adjust the width as needed */}
                            <col style={{ width: '16.66%'}} />
                            <col style={{ width: '16.66%' }} />
                            <col style={{ width: '16.66%' }} />
                            <col style={{ width: '16.66%' }} />
                            <col style={{ width: '16.66%' }} />
                            {/* <col style={{ width: '14.28%' }} /> */}
                                                </colgroup>
                                                <thead style={{backgroundColor: '#FFEEDC'}}>
                                                    <tr style={{ backgroundColor: '#FFEEDC', border: 'none'}}>
                                                    {/* <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}></th> */}
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Description</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Date</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Amount</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Security Check</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}>Status</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000',textAlign:'center' }}></th>
                                                        {/* <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}></th> */}
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                <tr className="bord"> 
                                                    <td colSpan="7">
                                                  <p className="mar-25" style={{textAlign:'start'}}>Today</p></td>
                                                  </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><div className="collectionfromdiv"><img src={wallettowallet}  alt="collect" className="collectimg"></img>
                                                        {/* <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} > */}
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Floyd Miles</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>Floyd Miles@domain.com</p>
                                                        </div>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckorange">Passed</td>
                                                        <td className="desctabdate">Used</td>
                                                        <td className="desctabsucc desctabcheckorange">Card Used</td>
                                                    </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><div className="collectionfromdiv"><img src={wallettowallet}  alt="collect" className="collectimg"></img>
                                                        {/* <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} > */}
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Esther Howard</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>estherhoward@domain.com</p>
                                                        </div>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckred ">Blocked</td>
                                                        <td className="desctabdate">Cancelled</td>
                                                        <td className="desctabsucc desctabinsuff">Cancelled By Sender</td>
                                                    </tr>
                                                    <tr className="bord"> 
                                                    <td colSpan="7">
                                                  <p className="mar-25" style={{textAlign:'start'}}>Yesterday</p></td>
                                                  </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><div className="collectionfromdiv"><img src={wallettowallet}  alt="collect" className="collectimg"></img>
                                                        {/* <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} > */}
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Leslie Alexander</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>lesliealexander@domain.com</p>
                                                        </div>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckred ">Blocked</td>
                                                        <td className="desctabdate">Expired</td>
                                                        <td><button className="retrybuts">Use</button></td>
                                                    </tr>
                                                    <tr className="padtr">
                                                        <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }}><div className="collectionfromdiv"><img src={wallettowallet}  alt="collect" className="collectimg"></img>
                                                        {/* <td style={{ fontWeight: '400', fontSize: '12px', lineHeight: '18px', color: '#292929' }} > */}
                                                        {/* <div className="d-flex"> */}
                                                        {/* <img src={wallettowallet} className="me-2"  width='24px' height="24px"></img> */}
                                                        <div style={{textAlign:'left'}}>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Dianne Russell</p>
                                                        <p className="desctabgmail"  style={{marginBottom:'0px'}}>diannerussell@domain.com</p>
                                                        {/* </div> */}
                                                        </div>
                                                        </div>
                                                        </td>
                                                        <td className="desctabdate">01 Sep,2023</td>
                                                        <td className="descamount">UGX 3000</td>
                                                        <td className="desctabcheck desctabcheckorange ">Checking</td>
                                                        <td className="desctabdate">Pending</td>
                                                        <td><button className="retrybuts">Use</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="massage_box_corrmobile1">
                            <div className="massage_box_corrmobile">
                            <div style={{position:'relative',width:'100%'}}>
                            <input type="search" className="serchinputbox" placeholder="Search"></input>  
                            <p  style={{ position: 'absolute',left:'10px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={search01} alt="search" width='16.5px' height='auto'></img></p> 
                            </div> 
                            <p className="massage_box_corrmobilep">Today</p>
                            <div className="collectbox-div">
                                <div className="d-flex align-items-center">
                                    <img src={wallettowallet} alt="bank" className="collectmasterimg me-3"></img>
                                        <div>
                                           <p className="desctabname">Floyd Miles</p>
                                           <p className="desctabgmail">Floyd Miles@domain.com</p>
                                        </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3 align-items-center">
                                     <div>
                                     <p className="descmobiletypep">Date : <span className="descmobiletypep descmobiletypep1">01 Sep,2024</span></p>
                                     <p className="descmobiletypep">Security Check : <span className="descmobiletypep descmobiletypep1 desctabcheck">Passed</span></p>
                                     <p className="descmobiletypep">Status : <span className="descmobiletypep descmobiletypep1">Sent</span ></p>
                                     </div>
                                     <div className="text-end">
                                     <p className="collectionmobileamoutp">Amount</p>
                                     <p className="collectionmobileamoutgreen collectionmobileamoutorange">UGX 3000</p>
                                     </div>
                                </div>
                                <div className="collectionrefund"></div> 
                                <button className="collectbox-footexportblue collectbox-footexportblueorange "><img src={paid} alt="export" className="me-2" width='16px' height='16px'/>Card Used</button>
                            </div>
                            <div className="collectbox-div">
                                <div className="d-flex align-items-center">
                                    <img src={wallettowallet} alt="bank" className="collectmasterimg me-3"></img>
                                        <div>
                                           <p className="desctabname">Esther Howard</p>
                                           <p className="desctabgmail">*****1234</p>
                                        </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3 align-items-center">
                                     <div>
                                     <p className="descmobiletypep">Date : <span className="descmobiletypep descmobiletypep1">01 Sep,2024</span></p>
                                     <p className="descmobiletypep">Security Check : <span className="descmobiletypep descmobiletypep1 desctabcheckred">Blocked</span></p>
                                     <p className="descmobiletypep">Status : <span className="descmobiletypep descmobiletypep1">Sent</span ></p>
                                     </div>
                                     <div className="text-end">
                                     <p className="collectionmobileamoutp">Amount</p>
                                     <p className="collectionmobileamoutgreen collectionmobileamoutorange">UGX 3000</p>
                                     </div>
                                </div>
                                <div className="collectionrefund"></div> 
                                <button className="collectbox-footexportblue collectbox-footexportbluered "><img src={layer} alt="export" className="me-2" width='16px' height='16px'/>Cancelled by Sender</button>
                            </div>
                            </div>
                            <div className="collectbox-foot">
                            <button className="export_pdfinitiate" onClick={gift}>Inelligible</button>
                                    <button className="collectbox-footexport"><img src={folderexport} alt="export" className="me-2" width='15px' height='14px'></img>Export to pdf</button>
                                </div>   
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}

<Modal show={show3} dialogClassName="example-dialog3" contentClassName="example-content27" onHide={handleCloses3} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                <div className="text-end p-1">
                        <img onClick={close1} className="poptimes" src={timecircle} alt="times"></img>
                    </div>
                  <div style={{margin:'0 7%'}}>  
                 <label className="mb-3" style={{fontWeight:'600',fontSize:"16px",lineHeight:'24px',color:'#000000'}}>Gift Card</label>   
                <input type="text" value={receiver} onChange={(e)=>setReceiver(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Receiver" style={{ height: '40px', }} />
                <input type="text" value={amount} onChange={(e)=>setAmount(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Amount" style={{ height: '40px', }} />
                <input type="text" value={expiry} onChange={(e)=>setExpiry(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Expiry Time" style={{ height: '40px', }} />
                <input type="text" value={frezze} onChange={(e)=>setFrezze(e.target.value)} className="form-control withdrawamountinputs mb-3" placeholder="Freeze Time" style={{ height: '40px', }} />
                </div>
                <div className="text-center mb-4" style={{margin:'0 7%'}}>
                <button className={getButtonClass1()} onClick={transfer}>Transfer Now</button>
                        </div>
               
                </Modal.Body>   
             </Modal>

             <Modal show={show4} dialogClassName="example-dialog4" contentClassName="example-content4" onHide={handleCloses4} centered>
    <Modal.Body style={{ margin: '0', padding: '0',width:'100%',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
        {apiStatus === 'processing' && (
            <>
                <img src={process} alt="process" width="200px" height="200px" />
                <p className="processwithdraw">Processing Withdraw</p>
            </>
        )}
        {apiStatus === 'success' && (
            <>  
                <div>
                <img src={success}  alt="success" width="200px" height="200" />
                </div>
                <div style={{textAlign:'center'}}>
                <p className="withdrawsuceessname mb-0">Floyd Miles</p>
                <p className="withdrawagentsuccess mb-0">Floyd Miles@domain.com</p>
                <p className="withdrawsuccessamount withdrawsuccessamountorange mb-0">UGX 1000</p> </div>
                <div className="text-center mt-3 mb-3" style={{display:'flex',justifyContent:'center',width:'100%'}}>
                    <button className="withdrawdonebutton withdrawdonebuttonss " onClick={paynowsuccess}>Done</button>
                </div>
            </>
        )}
    </Modal.Body>
</Modal>
        </>
    );
}
export default Manualgiftcard;