import React from "react";
import file from '../images/Document_green-01.png';
import eye from '../images/eye_green-01.png';
import cancel from '../images/cancel-02.png';
import edit from '../images/edit_1-01.png';
function Walletnationaliid(){
    return(
        <>
          <div className="walletprofileblue mt-4 p-4">
            <div className="d-flex justify-content-end align-items-center">
              {/* <img src={edit} width='19.5px'className="me-2" height='19.5px'></img> */}
              <p className="wallletprofileeditp mb-0">+ Add Document</p>
            </div>
            <div  className="docbox">
                        <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="me-3">
                            <img src={file} alt="file" width='60px' height='auto' />
                          </div>
                          <div>
                            <p className="docviewp truncated-text ">National ID</p>
                            <p className="docviewp2 truncated-text ">National ID</p>
                          </div>
                          </div>
                          {/* <div>
                            <p className="docviewp truncated-text">Document name</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div>
                          <div>
                            <p className="docviewp truncated-text">Date of Issue</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div>
                          <div>
                            <p className="docviewp truncated-text">Expire Date</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div> */}
                          <div className="d-flex align-items-center">
                          <div className="me-3">
                            <button className="docedit" >
                              <img className="me-3" src={edit} alt="edit" width='18px' height='auto' />
                              Edit
                            </button>
                          </div>
                          <div className="d-flex flex-column">
                            <img className="mb-2 documenteyeview" src={eye}  alt="eye" />
                            <img className="documenteyeview" src={cancel}  alt="file" width='29.89px' height='auto' />
                          </div>
                          </div>
                        </div>
                      </div>
            </div>
        </>
    )
}
export default Walletnationaliid;