import {React,useState,useEffect} from "react";
import Transactionside from "./transactionside";
import Navbars from "./navbar";
import Agentrecord from "./agentrecord";
import Agentpay from "./agentpay";
import { useLocation } from "react-router-dom";
function Qrtransaction(){
    // const [source3 , setSource3] = useState('agendpay');
    const location = useLocation();
    const [source3, setSource3] = useState(() => {
        const storedActiveSection = localStorage.getItem('activeSection');
        return storedActiveSection || 'agentrecord'; // Set the default section if none is found in localStorage
      });
      useEffect(() => {
        localStorage.setItem('activeSection', source3);
      }, [location]);
    return(
        <>
        <Navbars/>
        <div className="container-fluid " style={{backgroundColor:'#F8F8F8',width:'100%',minHeight:'100vh'}}>
            <div className="row agentrecordrow">
                <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_3">
                    <Transactionside setSource3={setSource3} source3={source3}/>
                </div>
                {source3 === "agentrecord" && 
                <Agentrecord />}
                 {source3 === "agendpay" && 
                <Agentpay />}
            </div>
           </div>
        </>
    );
}
export default Qrtransaction;