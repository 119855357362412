import {React,useState} from "react";
import Modal from 'react-bootstrap/Modal';
import closeicon from './images/times-circle-01.png';
import plus from './images/plus.png';
import trash  from './images/paypal/trash-01.png';
import paypalmobile from './images/registered-services/paypal(2)-01.svg';
import edit from './images/paypal/pen-01.png';
import paypal from './images/paypal/PayPal.png';
function Applepay(){
    const [show12 , setShow12] = useState(false);
    const [paypalusername , setPaypalusername] = useState('');
    const addnewcard = () => {
        setShow12(true);
    }
    const handleCloses12 = () => {
        setShow12(false);
        setPaypalusername('');
    }
    const closedialog12 = () => {
        setShow12(false);
        setPaypalusername('');
    }
    const paypalusernamechange = (e) => {
        setPaypalusername(e.target.value)
    }
    const getButtonClass12 = () => {
        return paypalusername > " " ? 'addnewsaveafter' : 'addnewsave';
    }
    const save = () => {
        setShow12(false);
        setPaypalusername('');
    }
    return(
        <>
         <div className="col-lg-9 col_corr_1"  >
                    <main className="cardsmaindiv" style={{backgroundColor:'#FFFFFF',width:'100%',boxShadow:"0px 0px 5px 0px #0000001A",height:'100%',minHeight:'400px'}}> 
                    <h5 className="ps-4 pt-4 registerservicecard">Apple Pay</h5>
                       <p className="cardsp ps-4 registerservicecard mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr" style={{width:'100%',margin:'0 2.5%',maxWidth:'95%',minHeight:'400px',border:'1px solid #7D7878', }}>
                            <div className="d-flex align-items-center justify-content-between p-4 paypalhead">
                         <h5 className="paypalh5" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#494949'}}>Apple Pay Account</h5>
                        <button className="addnewcards" onClick={addnewcard}><img src={plus} className="me-2 paypalplus" alt="plus" width='15px' height='15px'></img>Add paypal account</button>
                    </div>
                    <div className="d-flex radio align-items-center w-100 paypalradio" style={{padding:'0px 30px'}}>
                      <input type="radio"  id="myRadio" style={{marginRight:'20px',}}></input>
                      <div className="paybox">
                        <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                             <img src={paypal} className="me-3 paypalimage"  alt="close"></img>
                             <img src={paypalmobile} className="me-3 paypalimagemobile"  alt="close"></img>
                             <p className="payemail">johndoe@paypal.com</p>
                        </div>
                        <div className="d-flex align-items-center">
                        <img src={edit} className="me-3 paypaldelete paypaldelete1"  alt="edit"></img>
                         <img src={trash} className="paypaldelete"  alt="trash"></img>
                        </div>
                        </div>
                      </div>
                    </div>
                       </div>
                    </main>
                </div>
            {/* </div>
           </div> */}
           <Modal show={show12} dialogClassName="example-dialog11" contentClassName="example-content11" onHide={handleCloses12} centered>
                <Modal.Body style={{ margin: '0', padding: '0'}}>
                <div className="text-end">
                        <img src={closeicon} onClick={closedialog12} style={{cursor:'pointer'}} className="closeicon mx-1 mt-2" alt="closeicon" width="32px" height="32px"></img>
                    </div>
                    <div className="p-2">
                        <h5 className="addnewwh5 ps-4">Add paypal account</h5>
                    </div>
                    <div className="mt-3 mb-3" style={{margin:'0 8%'}}>
                        <label htmlFor="cardNumber" className="cardlabel">Paypal Username</label>
                        <input type="text" id="cardNumber" value={paypalusername} onChange={paypalusernamechange} name="cardNumber" className="form-control cardinputbox" />
                    </div>
                    <div className="d-flex align-items-center mb-4 me-3" style={{margin:'0 8%'}}>
                            <input type="checkbox" id="checkbx" className='form-check-input paypalinput me-2' style={{cursor:'pointer'}} name="checkbxName" />
                            <label htmlFor="checkbx" className="me-2 paypallabel">Save this account for default</label>
                        </div>
                        <div style={{margin:'0 8%'}}>
                        <button className={getButtonClass12()} onClick={save}>Save</button>
                    </div>
                </Modal.Body> 
            </Modal>
        </>
    )
}
export default Applepay;