import React, { useCallback, useEffect,useState,useRef,memo } from 'react'
import { publicDomain, mcclistapi,getemergencyrelationlistapi, updateemergencycontactsapi } from '../../api/url';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import WithToast from '../../api/commonfunct.js/withtoast';
import Select, { components } from 'react-select';
import { useIpContext } from '../../api/commonfunct.js/ipContext';
import useMenuHeight, { handleApiError ,handleKeyPress,customStyles1} from '../../api/commonfunct.js/common';
import tick from "../../images/user-circle.png";
const publicDomainurl = publicDomain();
const getemergencyrelationlistapiurl = getemergencyrelationlistapi();
const mcclistapiurl = mcclistapi();
const updateemergencycontactsapiurl = updateemergencycontactsapi();
const Emergency = memo(({ setActiveStep, activeStep, setIsLoading4, showErrorToast,getemergency,getemergencydocid,name,setShowAddNew})=> {
    const [formValues, setFormValues] = useState({
      image: '',
      name: '',
      email:'',
      relation:null,
      relationoption:[],
      isnextofkin:false,
      responsephoneNumber:'',
      dial_code:'',
      selectOption: '',
      countryOptions: [],
      mcc_id: null,
      isLoading: false,
      isrelationloading:false,
      gender: '',
      dob: '',
      changeimage: false,
      id: getemergencydocid,
      Selectimage:null,
      isPreviewVisible1:true
    });
    useEffect(()=>{
      setFormValues((prev)=>({
        ...prev,id:getemergencydocid
      }));      
    },[getemergencydocid]);
    const [isenabled,setIsenbled] = useState(false);
    const [error422,setError422] = useState('');
    const [formValuesError, setFormValuesError] = useState({ imageError: '', nameError: '', genderError: '', dobError: '',mobileerror:'',emailerror:'' });
    const mobileInputRef = useRef(null);
    const { ipCountryCode, getUserIpCountryCode } = useIpContext();
    const getrelationlist = useCallback(async()=>{
       try{
          const response = await axiosInstance.get(getemergencyrelationlistapiurl);
          if(response.status === 200){
             const relationlist = response.data.data;
             setFormValues((prev)=>({
              ...prev,relationoption:relationlist.map(relat => ({value:relat.id,label:relat.name}))
             }))
          }
       }catch(error){
        handleApiError(error, null, null, true, showErrorToast);
       }
    },[showErrorToast]);
    useEffect(()=>{
      if(name === 'stalin'){
      getrelationlist();
      }
    },[getrelationlist,name]);
    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormValues((prev) => ({
            ...prev,
            image: reader.result,
            changeimage: true
          }));
        };
        reader.readAsDataURL(file);
      }
    };
    const updateExampleNumber = useCallback((iso2) => {
      if (window.intlTelInputUtils) {
        try {
          const exampleNumber = window.intlTelInputUtils.getExampleNumber(
            iso2,
            true,
            window.intlTelInputUtils.numberFormat.E164
          );
          const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
          const maxLength = cleanedNumber.length;
          return maxLength;
        } catch (error) {
          console.error('Error fetching example number for country code:', iso2, error);
          return 15; // Fallback to a default length
        }
      } else {
        console.error('intlTelInputUtils is not loaded');
        return 15; // Fallback to a default length
      }
    }, []);
      const fetchCountryCode = useCallback(async()=>{
        if(ipCountryCode){
        if (formValues.countryOptions.length > 0) {
          return; // Skip if already fetched
        }
        setFormValues((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        
        try {
          const response = await axiosInstance.get(mcclistapiurl);
          const data = response.data;
      
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
      
            setFormValues((prevState) => ({
              ...prevState,
              countryOptions: codes,
            }));
      
            let code = ipCountryCode;
            const selectedCountryData = codes.find(
              (country) => country.country_code === code
            );
      
            if (selectedCountryData) {
              setFormValues((prevState) => ({
                ...prevState,
                mcc_id: selectedCountryData.mcc_id,
                dial_code: `+${selectedCountryData.dial_code}`,
                country_code: selectedCountryData.country_code,
              }));
      
              const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        } finally {
          setFormValues((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        }
      }
      },[ipCountryCode,formValues.countryOptions.length,showErrorToast,updateExampleNumber]);
      useEffect(() => {
        // if(name){
        const fetchData = async () => {
          if (!ipCountryCode) {
            await getUserIpCountryCode();
          }
          await fetchCountryCode();
        };
        if(name === 'stalin'){
        fetchData();
        }else{
          return;
        }
      // }
      }, [ipCountryCode, fetchCountryCode, getUserIpCountryCode,name]);
    const triggerFileInput = () => {
      document.getElementById('fileInput').click();
      setError422('');
    };
    const empty = () => {
      setFormValues((prev)=>({
        ...prev,
        image: '',
        name: '',
        email:'',
        relation:null,
        relationoption:[],
        isnextofkin:false,
        responsephoneNumber:'',
        dial_code:'',
        selectOption: '',
        countryOptions: [],
        mcc_id: null,
        isLoading: false,
        isrelationloading:false,
        gender: '',
        dob: '',
        changeimage: false,
        id: getemergencydocid,
        Selectimage:null,
        isPreviewVisible1:true
      }));
    }
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      if(name==='name'){
        setError422('');
      }
      if(name==='relation'){
        setError422('');
      }
      if(name==='responsephonenumber'){
        setError422('');
      }if(name==='email'){
        setError422('');
      }
    };
    const handleCountryChange = (selectedOption) => {
      const selectedCountryData = formValues.countryOptions.find(
        country => country.value === selectedOption.value
      );
    
      if (selectedCountryData) {
        setFormValues(prevState => ({
          ...prevState,
          country_code: selectedCountryData.country_code,
          dial_code: `+${selectedCountryData.dial_code}`,
          mcc_id: selectedCountryData.mcc_id
        }));
    
        // Fetch maximum length based on selected country
        const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
        if (maxLength !== null && mobileInputRef.current) {
          mobileInputRef.current.setAttribute('maxlength', maxLength);
        }
    
        // Handle phone number truncation if necessary
        setFormValues(prevState => ({
          ...prevState,
          responsephoneNumber: prevState.responsephoneNumber.slice(0, maxLength)
        }));
      }
    
      setError422('');
    };
    const handlePhoneNumberChange = (value) => {
        if (value.startsWith('0')) {
          value = value.slice(1);
        }
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
        if (value.length > maxLength) {
          value = value.slice(0, maxLength);
        }
        setFormValues(prevState => ({
          ...prevState,
          responsephoneNumber: value
        }));
    };
    const handlecheckchange = (e) => {
      setFormValues((prev)=>({
        ...prev,
        isnextofkin:e.target.checked
      }));
    }
  
    const handleKeyDown1 = (e) => {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(e.key)) {
        e.preventDefault();
        setFormValuesError((prev) => ({
          ...prev,
          nameError: 'Only alphabets are allowed'
        }));
      } else {
        setFormValuesError((prev) => ({
          ...prev,
          nameError: ''
        }));
      }
    };
  
    const handledelete = () => {
      setFormValues((prev)=>({
          ...prev,changeimage:false,image:''
      }));
    }
    const customSingleValue = ({ data }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
          src={data.flag}
          alt=""
          style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
      </div>
    );
    const customOption = (props) => (
      <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
          src={props.data.flag}
          alt=""
          style={{ width: '25px', height: 'auto', marginRight: '10px' }}
          />
          {props.data.label}
      </div>
      </components.Option>
    );
    const handleNext = async() => {
      if(formValues.responsephoneNumber.length < 9 || formValues.responsephoneNumber.length > 15) {
        setFormValuesError((prevstate)=>({
          ...prevstate,mobileerror:'The mobile must be between 9 and 15 digits'
        }))
        // setError422('The mobile must be between 9 and 15 digits')
        return false;
      }else{
        setFormValuesError((prevstate)=>({
          ...prevstate,mobileerror:''
        }))
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formValues.email)) {
        setFormValuesError((prevstate)=>({
          ...prevstate,emailerror:'Please enter a valid email address.'
        }))
        return;
      }else{
        setFormValuesError((prevstate)=>({
          ...prevstate,emailerror:''
        }))
      }
      const data = {
        kyc_id : formValues.id,
        image:formValues.image,
        mcc_id:formValues.mcc_id,
        relation:formValues.relation.value,
        kin_status:formValues.isnextofkin ? 1 : 0,
        mobile:formValues.responsephoneNumber,
        email : formValues.email,
        name:formValues.name
      }
      try{
        setIsLoading4(true);
         const response = await axiosInstance.post(updateemergencycontactsapiurl,data);
         if(response.status === 200){
          await getemergency();
          empty();
          setShowAddNew(false);
          const nextStep = activeStep;
          setActiveStep(nextStep);
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          localStorage.setItem('activeStep', nextStep);
          setError422('');
          setIsLoading4(false);
         }
      }catch(error){
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
      }
    }
    const handleBack = () => {
      const nextStep = activeStep - 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
    }
    const check = useCallback(()=>{
      const valid = (
        formValues.name !== '' && formValues.relation !== '' &&  formValues.image !== '' && formValues.changeimage !== false && formValues.responsephoneNumber && formValues.email
       );
       setIsenbled(valid);
    },[formValues.name,formValues.relation,formValues.image,formValues.changeimage,formValues.responsephoneNumber,formValues.email]);
    useEffect(()=>{
      check();
    },[check]);
    
    const calculateWidth = (label) => {
      const tempDiv = document.createElement('p');
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.fontSize = '14px';
      tempDiv.style.fontWeight = '400';
      tempDiv.style.whiteSpace = 'nowrap';
      tempDiv.style.margin = '0';
      document.body.appendChild(tempDiv);
  
      tempDiv.innerText = label;
      const newWidth = tempDiv.offsetWidth;
      document.body.removeChild(tempDiv);
  
      return newWidth;
    };
  
    const [selwidth, setSelwidth] = useState(0);
  
    const selectWidth = useCallback(() => {
      const label = formValues.dial_code || '';
      const newWidth = Math.round(calculateWidth(label));
      setSelwidth(newWidth + 82);
    }, [formValues.dial_code]);
  
    useEffect(() => {
      selectWidth();
    }, [selectWidth]);
  
    useEffect(() => {
      const handleResize = () => {
        selectWidth();
      };
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [selectWidth]);
  
    const menuHeight = useMenuHeight(200, 400);
  return (
    <>
       <div className="wallet-profilepad" style={{padding:'0px 40px 10px 55px'}}>
       {formValues.changeimage ? (
        <div className="wallet-profilediv">
          <div className="profilediv profilediv1">
            <div className="header-profile-user border-dark user" style={{ width: "70px", height: "70px", borderRadius: "50%", marginRight: "10px", position: 'relative' }}>
              <img id="login_img_for_profile" src={formValues.image.startsWith('data:') ? `${formValues.image}`: `${publicDomainurl}/${formValues.image}`} className="rounded-circle border-dark" alt='profille' style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} />
              {/* <img src={eye2} onClick={()=>imageview(formValues.image)} alt='eye2' style={{ width: "28px", height: "auto", position: "absolute", top: "63%", right: "-5px", objectFit: "cover" }} /> */}
            </div>
            <div>
              <p className="walletmaximagep">Upload Your Picture</p>
              <p className='walletmaximage'>Max image size - 2mb</p>
            </div>
          </div>
          <div className="profile-uploadbuttondiv profile-uploadbuttondiv1">
            <button className='profile-upload profile-uploadreplace' onClick={triggerFileInput}>Replace</button>
            <input type="file" accept="image/png, image/jpg, image/jpeg" id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload} />
            <button className="profile-upload profile-uploaddelete" onClick={handledelete}>Delete</button>
          </div>
        </div>
      ) : (
        <div className="wallet-profilediv">
          <div className="profilediv">
            <div className="header-profile-user border-dark user" style={{ width: "70px", height: "70px", borderRadius: "50%", marginRight: "10px", position: 'relative' }}>
              <img id="login_img_for_profile" src={tick} alt='proile' className="rounded-circle border-dark" style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} />
              {/* <img src={eye2} onClick={()=>imageview(formValues.image)} alt='eye2' style={{ width: "28px", height: "auto", position: "absolute", top: "63%", right: "-5px", objectFit: "cover" }} /> */}
            </div>
            <div>
              <p className=" walletmaximagep">Upload Your Picture</p>
              <p className='walletmaximage'>Max image size - 2mb</p>
            </div>
          </div>
          <div className="profile-uploadbuttondiv">
            <input type="file" accept="image/png, image/jpg, image/jpeg" id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload} />
            <button className="profile-upload" onClick={triggerFileInput}>Upload</button>
          </div>
        </div>
      )}
      <div className="mt-3">
        <label className="walletprofile-label">Name<span className="docpopstar">*</span></label>
        <input type="text" name='name' value={formValues.name} onKeyDown={handleKeyDown1} onChange={handleChange} className={`form-control mt-1 walletprofile-input ${formValues.name ? 'walletprofile-input-active' : ''}`} placeholder="Enter your name" />
        {formValuesError.nameError && <p className="error-message">{formValuesError.nameError}</p>}
      </div>
      <div className="mt-3">
        <label className="walletprofile-label">Mobile Number<span className="docpopstar">*</span></label>
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
              <Select
                options={formValues.countryOptions || []}
                onChange={handleCountryChange}
                value={formValues.countryOptions.find(option => option.value === formValues.country_code)}
                components={{ SingleValue: customSingleValue, Option: customOption }}
                placeholder=""
                isLoading={formValues.isLoading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: formValues.isLoading ? '80px' : '80px',
                    height: '45px',
                    border: 'none',
                    boxShadow: 'none',
                    background: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  input: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    minWidth: '300px',
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    padding: 0,
                    height: menuHeight,
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
            <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
            <p style={{ position: 'absolute', left: '80px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'hsl(0, 0%, 20%)', fontSize: '14px', lineHeight: '1.5', marginBottom: '0px', fontWeight: '400' }}>{formValues.dial_code}</p>
            <input type="text" name="responsephoneNumber" value={formValues.responsephoneNumber} ref={mobileInputRef} onKeyPress={handleKeyPress} onChange={(e) => handlePhoneNumberChange(e.target.value)} className={`form-control mt-1 walletprofile-input ${formValues.responsephoneNumber ? 'walletprofile-input-active' : ''}`} style={{ paddingLeft: `${selwidth + 18}px` }} placeholder="Enter your Mobile number"/>
          </div>
        {formValuesError.mobileerror && <p className="error-message">{formValuesError.mobileerror}</p>}
      </div>
      <div className="mt-3">
        <label className="walletprofile-label">Email Address<span className="docpopstar">*</span></label>
        <input type="text" name='email' value={formValues.email}  onChange={handleChange} className={`form-control mt-1 walletprofile-input ${formValues.email ? 'walletprofile-input-active' : ''}`} placeholder="Enter your email" />
        {formValuesError.emailerror && <p className="error-message">{formValuesError.emailerror}</p>}
      </div>
      <div className='mt-3'>
      <label className="walletprofile-label">Relation<span className="docpopstar">*</span></label> 
       <Select name="relation" options={formValues.relationoption} onChange={(selectOption)=>setFormValues((prev)=>({...prev,relation:selectOption}))} className='mt-1' placeholder="Select Relation"  styles={customStyles1(Boolean(formValues.relation))}  />
      </div>
      <div className="d-flex align-items-center mt-3" style={{gap:'10px'}}>
                  <input type="checkbox" checked={formValues.isnextofkin} onChange={handlecheckchange} className="form-check-input emer-check"></input>
                  <p className="emercheckp">Next of Kin</p>
                </div>
                <hr className="walletprofile-mar" />
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isenabled ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            disabled={!isenabled}
            onClick={handleNext}
          >
            Next
          </button>
          <button
            className="walletprofile-cancel"
            // disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
       </div>
      
    </>
  )
})

export default WithToast(Emergency)