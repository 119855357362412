import { React, useState, useEffect } from "react";
import { useLocation, } from "react-router-dom";
function Manualsidebar({ setSource1, source1 }) {
    const location = useLocation();
    const [activeCategory, setActiveCategory] = useState("Manual");
    const [activeMenuItem, setActiveMenuItem] = useState("");
    const [initialLoad, setInitialLoad] = useState(true);
    // const handleCategoryChange = (category) => {
    //     setActiveCategory(category);
    // };
    const handleCategoryChange = (category) => {
        setActiveCategory(category);
        setSource1(category === "Manual" ? "cardtowallet" : "collection"); // Set the active section based on the selected category
        localStorage.setItem('activeSection', category === "Manual" ? "cardtowallet" : "collection"); // Update the active section in localStorage
    };
    const handleSource1Change = (source1) => {
        setSource1(source1);
        localStorage.setItem('activeSection', source1);
    }
    const sideMenu = [
        { "title": 'Card To Wallet', to: "cardtowallet" },
        { 'title': 'Bank To Wallet', to: 'banktowallet' },
        { 'title': 'Mobile Money to Wallet', to: 'mobilemoneytowallet' },
        { 'title': 'Paypal to Wallet', to: 'paypaltowallet' },
        { 'title': 'Gift Cards', to: 'manualgiftcard' },
    ];
    const systemMenu = [
        { "title": 'Collections', to: "collection" },
        { 'title': 'Refund', to: 'systemrefund' },
    ]
    useEffect(() => {
        const storedActiveSection = localStorage.getItem('activeSection'); // Retrieve the active section from localStorage
    
        if (storedActiveSection) {
          setSource1(storedActiveSection); // Set the active section based on localStorage
        }
        const pathname = source1;
        const activeItem = [...sideMenu, ...systemMenu].find(item => item.to === pathname);
    
        if (activeItem) {
            setActiveMenuItem(activeItem);
            if (sideMenu.some(item => item.to === pathname)) {
                setActiveCategory("Manual");
            } else if (systemMenu.some(item => item.to === pathname)) {
                setActiveCategory("System");
            }
        } else {
            setActiveMenuItem(null);
        }
    }, [location]);
    return (
        <>
            <div className="aside">
                <aside>
                    <div className="pt-4 ps-5 pe-5 manual manual-sidepad  d-flex justify-content-between">
                        <div onClick={() => handleCategoryChange('System')} className={` ${activeCategory === "System" ? "manualsidedivactive" : "manualsidedivinactive"}`}>
                            <h6 className={`  ${activeCategory === "System" ? "registerserviceh6" : "registerserviceh6inactive"}`}>System</h6>
                        </div>
                        <div onClick={() => handleCategoryChange('Manual')}  className={` ${activeCategory === "Manual" ? "manualsidedivactive" : "manualsidedivinactive"}`}>
                            <h6 className={` ${activeCategory === "Manual" ? "registerserviceh6" : "registerserviceh6inactive"}`}>Manual</h6>
                        </div>
                    </div>
                    <hr className="manualsidehr" style={{ width: '100%', maxWidth: '90%', color: '#ADA9A9', margin: '5% 5%', border: 'none' }}></hr>
                    <div className="registerul registerulmanual  mt-3">
                        <ul>
                            {activeCategory === "System" && systemMenu.map((menuItem) => (
                                <li key={menuItem.to} className={`${source1 === menuItem.to ? 'manualsideliactive' : 'manualsideliinactive'}`} onClick={() => handleSource1Change(menuItem.to)} style={{ backgroundColor: source1 === menuItem.to ? '#FFEEDC' : 'transparent', borderLeft: source1 === menuItem.to ? '6px solid #F77F00' : 'none', }}>
                                    <p className={`mb-1 ${source1 === menuItem.to ? 'manualsideliactivep' : 'manualsideliinactivep'}`}>{menuItem.title}</p>
                                </li>
                            ))}
                        </ul>
                        <ul>
                            {activeCategory === "Manual" && sideMenu.map((menuItem) => (
                                <li key={menuItem.to} className={`${source1 === menuItem.to ? 'manualsideliactive' : 'manualsideliinactive'}`} onClick={() => handleSource1Change(menuItem.to)} style={{ backgroundColor:source1 === menuItem.to ? '#FFEEDC' : 'transparent',borderLeft: source1 === menuItem.to ? '6px solid #F77F00' : 'none',}}>
                                    <p className={`mb-1 ${source1 === menuItem.to ? 'manualsideliactivep' : 'manualsideliinactivep'}`}>{menuItem.title}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </aside>
            </div>
        </>
    );
}
export default Manualsidebar;