import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import logo from "../images/GPay.png";
import close from "../images/times-circle-01.png";
import schoolgreen from "../images/school_green-01.png";
import eye from "../images/eye-01.png";
import eyeslash from "../images/eye_slash-01.png";
import info from "../images/info_icon/info-01.png";
import market from "../images/imagess2.png";
import down from "../images/down_arrow-01.png";
import user from '../images/user-3.jpg';
import code from '../images/Rectangle 1212.png';
import copy from '../images/corporate-pay&group-pay/Group Pay/copy-01.png';
import share from '../images/corporate-pay&group-pay/Group Pay/share-icon-01.png';
import share1 from '../images/corporate-pay&group-pay/Group Pay/Share-01.png';
import loading from '../images/corporate-pay&group-pay/Corporate Pay/loading-01.png';
import tick from '../images/tick-01.png';
import warning from '../images/corporate-pay&group-pay/Corporate Pay/warning-01.png';
function Corporatesec() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const [transactiondetail, setTransactiondetail] = useState(false);
  const [grouptransactiondetails, setGrouptransactiondetails] = useState(false);
  const [passcode, setPasscode] = useState("");
  const walletpasscodeclose = () => {
    setShow(false);
  };
  const handleClose = () => {
    setShow(false);
  };
  const back = () => {
    setShow(true);
  };

  const togglePasswordVisibility = () => {
    setPasscode(!passcode);
  };
  const back1 = () => {
    setShow1(true);
  };
  const walletpasscodeclose1 = () => {
    setShow1(false);
  };
  const handleClose1 = () => {
    setShow1(false);
  };
  const back2 = () => {
    setShow2(true);
  };
  const walletpasscodeclose2 = () => {
    setShow2(false);
  };
  const handleClose2 = () => {
    setShow2(false);
  };
  const back3 = () => {
    setShow3(true);
  };
  const walletpasscodeclose3 = () => {
    setShow3(false);
  };
  const handleClose3 = () => {
    setShow3(false);
  };
  const back4 = () => {
    setShow4(true);
  }
  const handleClose4 = () => {
    setShow4(false);
  }
  const back5 = () => {
    setShow5(true);
  }
  const handleClose5 = () => {
    setShow5(false);
  }
  const back6 = () => {
    setShow6(true);
  }
  const handleClose6 = () => {
    setShow6(false);
  }
  const back7 = () => {
    setShow7(true);
  }
  const handleClose7 = () => {
    setShow7(false);
  }
  const back8 = () => {
    setShow8(true);
  }
  const handleClose8 = () => {
    setShow8(false);
  }
  const back9 = () => {
    setShow9(true);
  }
  const handleClose9 = () => {
    setShow9(false);
  }
  const back10 = () => {
    setShow10(true);
  }
  const handleClose10 = () => {
    setShow10(false);
  }
  const detail = [
    { title: "type", to: "Subscription" },
    { title: "To", to: "W-2565664668" },
    { title: "Particulars", to: "Student Bus Fees" },
    { title: "Billed Currency", to: "UGX" },
    { title: "Billed Amount", to: "UGX 31,120" },
    { title: "Total Billing", to: "UGX  31,400" },
  ];

  const transactiondetails = () => {
    setTransactiondetail(!transactiondetail);
  };
  const groupbytransactiondetails = () => {
    setGrouptransactiondetails(!grouptransactiondetails);
  };
  return (
    <>
      <div>
        <button onClick={back}>modal</button>
        <button onClick={back1}>modal</button>
        <button onClick={back2}>modal</button>
        <button onClick={back7}>modal</button>
        <button onClick={back8}>modal</button>
        <button onClick={back9}>modal</button>
        <button onClick={back10}>modal</button>
      </div>
      {/* group by */}
      <div className="mt-2">
        <button onClick={back3}>modal</button>
        <button onClick={back4}>modal</button>
        <button onClick={back5}>Modal</button>
        <button onClick={back6}>Modal</button>
      </div>

      <Modal
        show={show}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="mt-3">
            <h5 className="corporategreenh5">Merchant Info :</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img
                src={schoolgreen}
                alt="logo"
                className="schoolgreenlogo me-3"
              ></img>
              <div>
                <h6 className="corporateproductname">Airbnb</h6>
                <p className="corporateprofileid">W-123456789</p>
              </div>
            </div>
            <div className="text-end">
              <p className="corporateprofileid">Amount</p>
              <h6 className="corporateproductname">UGX 31,400</h6>
            </div>
          </div>
          <div className="mt-3">
            <button className="corporatepayutton">Corporate Pay</button>
          </div>
          <div className="mt-3">
            <label className="mb-2 corporate-label">Enter Passcode</label>
            {/* <input type="text" className="form-control corporate-input"></input> */}
            <div style={{ position: "relative" }}>
              <input
                type={passcode ? "text" : "password"}
                className="corporate-input form-control"
              ></input>
              <p
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {passcode ? (
                  <img src={eye} className="eyeslash" alt="Show Password" />
                ) : (
                  <img
                    src={eyeslash}
                    className="eyeslash"
                    alt="Hide Password"
                  />
                )}
              </p>
            </div>
          </div>
          <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p">
                You are making a payment to{" "}
                <strong>Acorn International School</strong>and amount{" "}
                <strong>UGX 31,400 </strong>will be deducted off your
                organisation's wallet, including 0.5% tax (UGX280) and 0.5%
                wallet fee (UGX 500)
              </p>
            </div>
          </div>
          {/* <div className="mt-3">
                    <label className="walletprofile-label">
                    Confirm Passcode
                    </label>
                    <input
                      type="text"
                      className="form-control mt-1 walletprofile-input"
                      // placeholder="Enter your name"
                    ></input>
                  </div> */}
          <div className="corporate-buttons mt-3">
            {/* <button className="walletprofile-save me-3">Save</button> */}
            <button
              className=" corporate-cancel me-3"
              onClick={walletpasscodeclose}
            >
              Confirm
            </button>
            <button
              className="corporate-back"
              // disabled={activeStep === 0}
              onClick={walletpasscodeclose}
            >
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose1}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="mt-3">
            <h5 className="corporategreenh5">Merchant Info :</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img
                src={schoolgreen}
                alt="logo"
                className="schoolgreenlogo me-3"
              ></img>
              <div>
                <h6 className="corporateproductname">Airbnb</h6>
                <p className="corporateprofileid">W-123456789</p>
              </div>
            </div>
            <div className="text-end">
              <p className="corporateprofileid">Amount</p>
              <h6 className="corporateproductname">UGX 31,400</h6>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center">
            <button className="corporatepayutton corporatepayuttons me-3">
              Corporate Pay
            </button>
            <button className="corporategrouppaybutton">Group Pay</button>
          </div>
          <div className="mt-3">
            <label className="mb-2 corporate-label">Enter Passcode</label>
            {/* <input type="text" className="form-control corporate-input"></input> */}
            <div style={{ position: "relative" }}>
              <input
                type={passcode ? "text" : "password"}
                className="corporate-input form-control"
              ></input>
              <p
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {passcode ? (
                  <img src={eye} className="eyeslash" alt="Show Password" />
                ) : (
                  <img
                    src={eyeslash}
                    className="eyeslash"
                    alt="Hide Password"
                  />
                )}
              </p>
            </div>
          </div>
          <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p">
                You are making a payment to{" "}
                <strong>Acorn International School</strong>and amount{" "}
                <strong>UGX 31,400 </strong>will be deducted off your
                organisation's wallet, including 0.5% tax (UGX280) and 0.5%
                wallet fee (UGX 500)
              </p>
            </div>
          </div>
          {/* <div className="mt-3">
                    <label className="walletprofile-label">
                    Confirm Passcode
                    </label>
                    <input
                      type="text"
                      className="form-control mt-1 walletprofile-input"
                      // placeholder="Enter your name"
                    ></input>
                  </div> */}
          <div className="corporate-buttons mt-3">
            {/* <button className="walletprofile-save me-3">Save</button> */}
            <button
              className=" corporate-cancel me-3"
              onClick={walletpasscodeclose1}
            >
              Confirm
            </button>
            <button
              className="corporate-back"
              // disabled={activeStep === 0}
              onClick={walletpasscodeclose1}
            >
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose2}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="mt-3">
            <h5 className="corporategreenh5">Merchant Info :</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img
                src={schoolgreen}
                alt="logo"
                className="schoolgreenlogo me-3"
              ></img>
              <div>
                <h6 className="corporateproductname">Airbnb</h6>
                <p className="corporateprofileid">W-123456789</p>
              </div>
            </div>
            <div className="text-end">
              <p className="corporateprofileid">Amount</p>
              <h6 className="corporateproductname">UGX 31,400</h6>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center">
            <button className="corporatedismissbutton">Dismiss</button>
            {/* <button className="corporatepayutton corporatepayuttons me-3">Corporate Pay</button>
                    <button className="corporategrouppaybutton">Group Pay</button> */}
          </div>
          <div className="mt-3">
            <h5 className="corporateh5">Corporate Pay :</h5>
            <div>
              <label className="corporateorganizationlabel mt-2 mb-2">
                Organization Name
              </label>
              <select className="form-select walletcountryselect">
                <option>Select Organization Name</option>
              </select>
            </div>
            <div>
              <label className="corporateorganizationlabel mt-2 mb-2">
                Organization ID
              </label>
              <input
                className="form-control corporate-input"
                placeholder="Enter Organization ID"
              ></input>
            </div>
          </div>
          <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p">
                You are making a payment to{" "}
                <strong>Acorn International School</strong>and amount{" "}
                <strong>UGX 31,400 </strong>will be deducted off your
                organisation's wallet, including 0.5% tax (UGX280) and 0.5%
                wallet fee (UGX 500)
              </p>
            </div>
          </div>
          {/* <div className="mt-3">
                    <label className="walletprofile-label">
                    Confirm Passcode
                    </label>
                    <input
                      type="text"
                      className="form-control mt-1 walletprofile-input"
                      // placeholder="Enter your name"
                    ></input>
                  </div> */}
          <div className="corporate-buttons mt-3">
            {/* <button className="walletprofile-save me-3">Save</button> */}
            <button
              className=" corporate-cancel me-3"
              onClick={walletpasscodeclose2}
            >
              Confirm
            </button>
            <button
              className="corporate-back"
              // disabled={activeStep === 0}
              onClick={walletpasscodeclose2}
            >
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>
      
      <Modal
        show={show7}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose7}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column mt-4">
            <img src={loading} alt="load" className="corporateloadimage"></img>
            <h6 className="corporatewaitingh6 mt-2">Waiting for approval</h6>
            <p className="corporatewaitingp">Your Supervisor has been notified</p>
          </div>
          
        </Modal.Body>
      </Modal>
      
      <Modal
        show={show8}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose8}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column mt-4">
            <img src={close} alt="load" className="corporateloadimage"></img>
            <h6 className="corporatewaitingh6 red mt-2">Transaction Declined</h6>
            <p className="corporatewaitingp">Please contact your Supervisor</p>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <button className="corporateredbutton">Back</button>
          </div> 
        </Modal.Body>
      </Modal>

      <Modal
        show={show9}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose9}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column mt-4">
            <img src={tick} alt="load" className="corporateloadimage"></img>
            <h6 className="corporatewaitingh6 textgreen mt-2">Transaction Declined</h6>
            <p className="corporatewaitingp mt-2">Transaction has been approved</p>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center mt-2">
            <button className="corporateredbutton">Back</button>
          </div>  */}
        </Modal.Body>
      </Modal>

      <Modal
        show={show10}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose10}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column mt-4">
            <img src={warning} alt="load" className="corporateloadimage"></img>
            <h6 className="corporatewaitingh6 textorange mt-2">Warning!</h6>
            <p className="corporatewaitingp corporatewaitingp1 mt-2">Sorry, This Organization does not accept corporate payments at the moment, please contact Supervisor</p>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <button className="corporateredbutton">Back</button>
          </div> 
        </Modal.Body>
      </Modal>

      {/* // group byy */}

      <Modal
        show={show3}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose3}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <img src={market} alt="market"></img>
            <h5 className="corporateh5">EV MarketPlace</h5>
            <p className="corporatebillingp">Total Billing</p>
            <p className="corporatebillingpgreen">UGX 31,400</p>
          </div>
          <div className="groupbytransactiondiv mt-2" onClick={transactiondetails}>
            <div className="d-flex justify-content-between align-items-center w-100 p-2">
              <p className="corporatetransactiondetail">Transaction Details</p>
              <img
                src={down}
                onClick={transactiondetails}
                alt="down"
                className={` angle ${
                  transactiondetail ? "angle-expanded" : "angle-collapsed"
                }`}
                width="10px"
                height="auto"
              ></img>
            </div>
          </div>
          {transactiondetail && (
            <>
              {detail.map((item) => (
                <div key={item.title} className="transactiondetailhide p-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      className={`cortransactionp ${
                        item.title === "Total Billing" ? "cortransactionp1" : ""
                      }`}
                    >
                      {item.title}
                    </p>
                    <p
                      className={`cortransactionp cortransactionp1 ${
                        item.title === "Total Billing"
                          ? "cortransactionp1green"
                          : ""
                      }`}
                    >
                      {item.to}
                    </p>
                  </div>
                </div>
              ))}
            </>
          )}
          <div className="groupbytransactiondiv  mt-3" onClick={groupbytransactiondetails}>
            <div className="d-flex justify-content-between align-items-center w-100 p-2">
              <p className="corporatetransactiondetail">Group Pay Details</p>
              <img
                src={down}
                onClick={groupbytransactiondetails}
                alt="down"
                className={` angle ${
                  grouptransactiondetails ? "angle-expanded" : "angle-collapsed"
                }`}
                width="10px"
                height="auto"
              ></img>
            </div>
          </div>
          {grouptransactiondetails && (
            <>
              <div className="transactiondetailhide p-2">
                <div className="progress progress2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="groupmarketdetails mt-2">Click here for order details</p>
                </div>
                <p className="productowner">Owner</p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename green">UGX  31,400</p>
                </div>
                <p className="productowner productowner1 mt-2">Participants</p>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename green">UGX  31,400</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename blue-color">Hidden</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename blue-color">Hidden</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename green">UGX  31,400</p>
                </div>
              </div>
            </>
          )}
          <div className="corporate-buttons mt-3">
           <button className="corporategrouppayutton">Proceed to Pay</button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show4}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose4}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="mt-3">
            <h5 className="corporategreenh5">Merchant Info :</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img
                src={schoolgreen}
                alt="logo"
                className="schoolgreenlogo me-3"
              ></img>
              <div>
                <h6 className="corporateproductname">Airbnb</h6>
                <p className="corporateprofileid">W-123456789</p>
              </div>
            </div>
            <div className="text-end">
              <p className="corporateprofileid">Amount</p>
              <h6 className="corporateproductname">UGX 31,400</h6>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center">
            <button className="corporatepayutton corporatepayuttons me-3">
              Corporate Pay
            </button>
            <button className="corporatedismissbutton">Dismiss</button>
          </div>
          <div className="mt-3">
            <h5 className="corporateh5">Group Pay :</h5>
            <div className="mt-3 grouppayboxdiv">
              <div className="d-flex justify-content-between align-items-center">
              <img src={code} alt="qr" className="grouppaycode me-3"></img>
              <div className="d-flex w-100 flex-column">
                <div className="d-flex w-100 ">
                <button className="grouppayshare me-3"><img src={share} alt="share" className="me-2" width='12px' height='auto'></img>Share Info</button>
                <button className="grouppaycopy"><img src={copy} alt="copy" className="me-2" width='12px' height='auto'></img>Copy info</button>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="bluecopybox me-2">
                    <p className="bluecopyboxp">https://wallet.evzone.app/grouppay/12</p>
                  </div>
                  <div className="bluecopybox bluecopybox1">
                    <img src={share1} alt='share' className="share1"></img>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="progress progress2 mt-3">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "80%" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-2">
                <p className="groupaytotalamountp">Amount Paid :</p>
                <p className="groupaytotalamountp groupaytotalamountporange">UGX 29,300</p>
              </div>
              <div className="mt-2 text-end">                
              <p className="groupaytotalamountp">Amount Paid :</p>
                <p className="groupaytotalamountp groupaytotalamountpgreen">UGX 29,300</p>
              </div>
            </div>
            <div>
              <label className="corporateorganizationlabel mb-2">Amount</label>
              <input className='form-control grouppay-input' placeholder="Enter Amount"></input>
            </div>
            <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p">
              You won’t be charged until total amount is collected
              </p>
            </div>
          </div>
          <div className="mt-3">
            <label className="mb-2 corporate-label">Enter Passcode</label>
            {/* <input type="text" className="form-control corporate-input"></input> */}
            <div style={{ position: "relative" }}>
              <input
                type={passcode ? "text" : "password"}
                className="corporate-input form-control"
              ></input>
              <p
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {passcode ? (
                  <img src={eye} className="eyeslash" alt="Show Password" />
                ) : (
                  <img
                    src={eyeslash}
                    className="eyeslash"
                    alt="Hide Password"
                  />
                )}
              </p>
            </div>
          </div>
          <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p corporateinfo-p1">
              You are making a payment to Acorn International School and amount <strong>UGX 1000</strong> will be deducted off your wallet, including 0.5% tax <strong>(UGX 280)</strong> and 0.5% wallet fee <strong>(UGX 500)</strong>
              </p>
            </div>
          </div>
          <div className=" mt-3">
            {/* <button className="walletprofile-save me-3">Save</button> */}
            <button
              className=" corporate-cancel me-3 mb-3"
              // onClick={walletpasscodeclose2}
            >
              Confirm
            </button>
            <button
              className="corporate-back"
              // disabled={activeStep === 0}
              // onClick={walletpasscodeclose2}
            >
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show5}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose5}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="mt-3">
            <h5 className="corporategreenh5">Merchant Info :</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img
                src={schoolgreen}
                alt="logo"
                className="schoolgreenlogo me-3"
              ></img>
              <div>
                <h6 className="corporateproductname">Airbnb</h6>
                <p className="corporateprofileid">W-123456789</p>
              </div>
            </div>
            <div className="text-end">
              <p className="corporateprofileid">Amount</p>
              <h6 className="corporateproductname">UGX 31,400</h6>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center">
            {/* <button className="corporatepayutton corporatepayuttons me-3">
              Corporate Pay
            </button> */}
            <button className="corporatedismissbutton">Dismiss</button>
          </div>
          <div className="mt-3">
            <h5 className="corporateh5">Group Pay :</h5>
            <div className="mt-3 grouppayboxdiv">
              <div className="d-flex justify-content-between align-items-center">
              <img src={code} alt="qr" className="grouppaycode me-3"></img>
              <div className="d-flex w-100 flex-column">
                <div className="d-flex w-100 ">
                <button className="grouppayshare me-3"><img src={share} alt="share" className="me-2" width='12px' height='auto'></img>Share Info</button>
                <button className="grouppaycopy"><img src={copy} alt="copy" className="me-2" width='12px' height='auto'></img>Copy info</button>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="bluecopybox me-2">
                    <p className="bluecopyboxp">https://wallet.evzone.app/grouppay/12</p>
                  </div>
                  <div className="bluecopybox bluecopybox1">
                    <img src={share1} alt='share' className="share1"></img>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="progress progress2 mt-3">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "80%" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-2">
                <p className="groupaytotalamountp">Amount Paid :</p>
                <p className="groupaytotalamountp groupaytotalamountporange">UGX 29,300</p>
              </div>
              <div className="mt-2 text-end">                
              <p className="groupaytotalamountp">Amount Paid :</p>
                <p className="groupaytotalamountp groupaytotalamountpgreen">UGX 29,300</p>
              </div>
            </div>
            <div>
              <label className="corporateorganizationlabel mb-2">Amount</label>
              <input className='form-control grouppay-input' placeholder="Enter Amount"></input>
            </div>
            <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p">
              You won’t be charged until total amount is collected
              </p>
            </div>
          </div>
          <div className="mt-3">
            <label className="mb-2 corporate-label">Enter Passcode</label>
            {/* <input type="text" className="form-control corporate-input"></input> */}
            <div style={{ position: "relative" }}>
              <input
                type={passcode ? "text" : "password"}
                className="corporate-input form-control"
              ></input>
              <p
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {passcode ? (
                  <img src={eye} className="eyeslash" alt="Show Password" />
                ) : (
                  <img
                    src={eyeslash}
                    className="eyeslash"
                    alt="Hide Password"
                  />
                )}
              </p>
            </div>
          </div>
          <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p corporateinfo-p1">
              You are making a payment to Acorn International School and amount <strong>UGX 1000</strong> will be deducted off your wallet, including 0.5% tax <strong>(UGX 280)</strong> and 0.5% wallet fee <strong>(UGX 500)</strong>
              </p>
            </div>
          </div>
          <div className=" mt-3">
            {/* <button className="walletprofile-save me-3">Save</button> */}
            <button
              className=" corporate-cancel me-3 mb-3"
              // onClick={walletpasscodeclose2}
            >
              Confirm
            </button>
            <button
              className="corporate-back"
              // disabled={activeStep === 0}
              // onClick={walletpasscodeclose2}
            >
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show6}
        dialogClassName="example-corporatedialog"
        contentClassName="example-corporatecontent1"
        onHide={handleClose6}
        centered
      >
        <Modal.Body style={{ margin: "0", padding: "0" }}>
          <div className="d-flex justify-content-between align-items-center corrporatehr">
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" className="corporatelogo me-2"></img>
              <h5 className="corporateh5">EVzone Pay</h5>
            </div>
            <div>
              <img src={close} alt="close-icon" className="close-circle"></img>
            </div>
          </div>
          <div className="mt-3">
            <h5 className="corporategreenh5">Merchant Info :</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img
                src={schoolgreen}
                alt="logo"
                className="schoolgreenlogo me-3"
              ></img>
              <div>
                <h6 className="corporateproductname">Airbnb</h6>
                <p className="corporateprofileid">W-123456789</p>
              </div>
            </div>
            <div className="text-end">
              <p className="corporateprofileid">Amount</p>
              <h6 className="corporateproductname">UGX 31,400</h6>
            </div>
          </div>

           {/* <div className="mt-3">
            <h5 className="corporateh5">Group Pay :</h5>
            <div className="mt-3 grouppayboxdiv">
              <div className="d-flex justify-content-between align-items-center">
              <img src={code} alt="qr" className="grouppaycode me-3"></img>
              <div className="d-flex w-100 flex-column">
                <div className="d-flex w-100 ">
                <button className="grouppayshare me-3"><img src={share} alt="share" className="me-2" width='12px' height='auto'></img>Share Info</button>
                <button className="grouppaycopy"><img src={copy} alt="copy" className="me-2" width='12px' height='auto'></img>Copy info</button>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="bluecopybox me-2">
                    <p className="bluecopyboxp">https://wallet.evzone.app/grouppay/12</p>
                  </div>
                  <div className="bluecopybox bluecopybox1">
                    <img src={share1} alt='share' className="share1"></img>
                  </div>
                </div>
              </div>
              </div>
            </div> */}
            {/* <div className="progress progress2 mt-3">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "80%" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div> */}
            {/* </div>  */}
            <div className="groupbytransactiondiv  mt-3" onClick={groupbytransactiondetails}>
            <div className="d-flex justify-content-between align-items-center w-100 p-2">
              <p className="corporatetransactiondetail">Group Pay Details</p>
              <img
                src={down}
                onClick={groupbytransactiondetails}
                alt="down"
                className={` angle ${
                  grouptransactiondetails ? "angle-expanded" : "angle-collapsed"
                }`}
                width="10px"
                height="auto"
              ></img>
            </div>
          </div>
          {grouptransactiondetails && (
            <>
              <div className="transactiondetailhide p-2">
                <div className="progress progress2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="groupmarketdetails mt-2">Click here for order details</p>
                </div>
                <p className="productowner">Owner</p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename green">UGX  31,400</p>
                </div>
                <p className="productowner productowner1 mt-2">Participants</p>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename green">UGX  31,400</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename blue-color">Hidden</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename blue-color">Hidden</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <img src={user} alt="user" className="groupbuprofile me-2"></img>
                    <p className="groupbuprofilename ">Edgar Clerk</p>
                  </div>
                  <p className="groupbuprofilename green">UGX  31,400</p>
                </div>
              </div>
            </>
          )}
            {/* <div className="d-flex justify-content-between align-items-center">
              <div className="mt-2">
                <p className="groupaytotalamountp">Amount Paid :</p>
                <p className="groupaytotalamountp groupaytotalamountporange">UGX 29,300</p>
              </div>
              <div className="mt-2 text-end">                
              <p className="groupaytotalamountp">Amount Paid :</p>
                <p className="groupaytotalamountp groupaytotalamountpgreen">UGX 29,300</p>
              </div>
            </div> */}
            <div>
              <label className="corporateorganizationlabel mb-2">Amount</label>
              <input className='form-control grouppay-input' placeholder="Enter Amount"></input>
            </div>
            <div className="d-flex align-items-center justify-content-between">
            <label className="form-check-label corporate-label">Hide my amount</label>
  <div className="form-check form-switch">
    <input className="form-check-input" type="checkbox" id="toggleSwitch" />
    <label className="form-check-label" htmlFor="toggleSwitch"style={{ backgroundColor: 'white' }}></label>
  </div>
            </div>
            <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p">
              You won’t be charged until total amount is collected
              </p>
            </div>
          </div>
          <div className="mt-3">
            <label className="mb-2 corporate-label">Enter Passcode</label>
            {/* <input type="text" className="form-control corporate-input"></input> */}
            <div style={{ position: "relative" }}>
              <input
                type={passcode ? "text" : "password"}
                className="corporate-input form-control"
              ></input>
              <p
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {passcode ? (
                  <img src={eye} className="eyeslash" alt="Show Password" />
                ) : (
                  <img
                    src={eyeslash}
                    className="eyeslash"
                    alt="Hide Password"
                  />
                )}
              </p>
            </div>
          </div>
          {/* <div className="mt-3 corporate-blue p-2">
            <div className="d-flex align-items-center">
              <img
                src={info}
                alt="info"
                className="me-3"
                width="20px"
                height="auto"
              ></img>
              <p className="corporateinfo-p corporateinfo-p1">
              You are making a payment to Acorn International School and amount <strong>UGX 1000</strong> will be deducted off your wallet, including 0.5% tax <strong>(UGX 280)</strong> and 0.5% wallet fee <strong>(UGX 500)</strong>
              </p>
            </div>
          </div> */}
          <div className=" mt-3">
            {/* <button className="walletprofile-save me-3">Save</button> */}
            <button
              className=" corporate-cancel me-3 mb-3"
              // onClick={walletpasscodeclose2}
            >
              Confirm
            </button>
            <button
              className="corporate-back"
              // disabled={activeStep === 0}
              // onClick={walletpasscodeclose2}
            >
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Corporatesec;
