import React,{useState} from 'react'
import { disableFutureDates, handleKeyDown,CustomSuffixIcon, disablePastDates } from '../../api/commonfunct.js/common';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import fileopen from '../../images/folder-open-01.png';
function Nationaldocument({setActiveStep, activeStep,showErrortoast}) {
    const [error422,setError422] =useState('');
    const [isenabled,setIsenbled] = useState(true);
    const [formValues, setFormValues] = useState({
        firstName: '',
        otherNames: '',
        nationalId: '',
        dateOfIssue: '',
        expiryDate: '',
      });
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      };
      const handleBack = () => {
        const nextStep = activeStep - 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
      }
      const handleNext = () => {
        const nextStep = activeStep + 1;
          setActiveStep(nextStep);
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          localStorage.setItem('activeStep', nextStep);
      }
  return (
    <>
    <div className="wallet-profilepad">
        <div className='row'>
            <div className='col-6'>
            <label className="walletprofile-label">First Name<span className="docpopstar">*</span></label>
             <input type="text" value={formValues.firstName} onChange={handleInputChange} name='firstName' className={`form-control mt-1 walletprofile-input ${formValues.firstName ? 'walletprofile-input-active' : ''}`} placeholder="Type as it appears on ID" /> 
            </div>
            <div className='col-6'>
            <label className="walletprofile-label">Other Name(S)<span className="docpopstar">*</span></label>
            <input type="text" name='otherNames' value={formValues.otherNames} onChange={handleInputChange} className={`form-control mt-1 walletprofile-input ${formValues.otherNames ? 'walletprofile-input-active' : ''}`} placeholder="Enter other name" /> 
            </div>
        </div>
        <div className='mt-3'>
        <label className="walletprofile-label">National ID number<span className="docpopstar">*</span></label>
        <input type="text" name='nationalId' value={formValues.nationalId} onChange={handleInputChange} className={`form-control mt-1 walletprofile-input ${formValues.nationalId ? 'walletprofile-input-active' : ''}`} placeholder="Enter national ID number" /> 
        </div>
        <div className='mt-3'>
        <div className='row'>  
        <div className='col-6'>      
        <label className="walletprofile-label">Date Of Issue<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} className='mt-1 custom-pick' disabledDate={disableFutureDates} value={formValues.dateOfIssue ? dayjs(formValues.dateOfIssue) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, dateOfIssue: date ? dayjs(date).format('YYYY-MM-DD') : '', })); }} placeholder='Select your DOB' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.dob ? '1px solid black' : '1px solid #DCDCDC', color: formValues.dateOfIssue ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.dateOfIssue} />} />
        </div>
        <div className='col-6'>      
        <label className="walletprofile-label">Expiry Date<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} className='mt-1 custom-pick' disabledDate={disablePastDates(formValues.dateOfIssue)} value={formValues.expiryDate ? dayjs(formValues.expiryDate) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, expiryDate: date ? dayjs(date).format('YYYY-MM-DD') : '', })); }} placeholder='Select your DOB' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.expiryDate ? '1px solid black' : '1px solid #DCDCDC', color: formValues.expiryDate ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.expiryDate} />} />
        </div>
        </div>
        </div>
        <div className='mt-3'>
            <div className='row'>
           <div className='col-6'>
        <label className="walletprofile-label">Front<span className="docpopstar">*</span></label>
           <div className="popinputbox mt-3" >
                <div className="d-flex align-items-center justify-content-center flex-column p-2">
                  <img src={fileopen} className="popuploadflle" alt="file" width='48px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                {/* <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, setImage, setFileSize, setFileType, setProgress, setProgressColor, setUploading,setFormat)} /> */}
              </div>
            </div> 
           <div className='col-6'>
        <label className="walletprofile-label">Back<span className="docpopstar">*</span></label>
           <div className="popinputbox mt-3" >
                <div className="d-flex align-items-center justify-content-center flex-column p-2">
                  <img src={fileopen} className="popuploadflle" alt="file" width='48px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                {/* <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, setImage, setFileSize, setFileType, setProgress, setProgressColor, setUploading,setFormat)} /> */}
              </div>
              </div>
              </div> 
        </div>
        <hr className="walletprofile-mar" />
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isenabled ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            // disabled={!isenabled}
            onClick={handleNext}
          >
            Next
          </button>
          <button
            className="walletprofile-cancel"
            // disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
    </div>
    </>
  )
}

export default Nationaldocument;