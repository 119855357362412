import React,{useState,useCallback,useEffect} from 'react'
import WithToast from '../../api/commonfunct.js/withtoast';
import registerservicce from '../../images/registered_services-01.png';
import plus from '../../images/plus.png';
import { allfunctions, handleApiError } from '../../api/commonfunct.js/common';
import { getmobilemoney } from '../addsourcegetapi';
function Mobilemoney({setIsLoading4,showErrorToast}) {
    const [mobilemoney,setMobilemoney] = useState([]);
    const [show,setShow] = useState(false);
    const getmobilemoneyall = useCallback(() => {
        getmobilemoney()
          .then(res => setMobilemoney(res.data.data))
          .catch(error => handleApiError(error, null, null, true, showErrorToast));
      }, [showErrorToast]); // Empty array means `getcardall` doesn't depend on anything
      
      useEffect(() => {
        if (typeof allfunctions === 'function') {
          allfunctions([getmobilemoneyall]);
        }
      }, [getmobilemoneyall]);
      const addmobilemoney = () => {

      }
  return (
    <>
      <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" >
                    <main className="cardsmaindiv"> 
                    <h5 className="ps-4 pt-4 registerservicecard">Mobile Money</h5>
                       <p className="cardsp registerservicecard ps-4 mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr">
                        {mobilemoney.length > 0 ? (
                            <></>
                        ) : (
                            <>
                            <div className="d-flex justify-content-between align-items-center">
                             <h5 className="credith5">Mobile Money</h5></div>
                            <div className="d-flex flex-column align-items-center regdiv justify-content-center">
                                <img src={registerservicce} alt="registerservice" className="registerimage" ></img>
                                <p className="registerpcard">Please add your first Mobile Money to start making Mobile Money transfers</p>
                                <button className="addnewcard1 mb-3" onClick={addmobilemoney}><img src={plus} className="me-2 plus" alt="plus"></img>Add Mobile money</button>
                            </div>
                            </>
                        )}
                       </div>
                      </main>
                      </div> 
    </>
  )
}

export default WithToast(Mobilemoney);