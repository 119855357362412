import React, { useState } from "react";
import wallettowallet from "./images/user-3.jpg";
import graph from "./images/Graph.png";
import Transactionside from "./transactionside";
import Navbars from "./navbar";
import Modal from 'react-bootstrap/Modal';
import search01 from './images/search-01.png';
function Agentpay(){
    const [show , setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const details = () => {
        setShow(true)
    }
    return(
        <>
            {/* <Navbars/>
            <div className="container-fluid ">
                <div className="row agentrecordrow">
                    <div className="sd  col_corre_3">
                        <Transactionside/>
                    </div> */}
                    <div className="main  col_corre_4"  >
                         <div className="agentrec">
                            <img src={graph} alt="graph" ></img>
                         </div>
                        </div>
                        <div className="sd  col_corre_3"></div>
                    <div className="main  col_corre_4" >
                         <div className="agentrectable" style={{marginTop:'20px'}}>
                            <div className="d-flex agenttranss align-items-center justify-content-between p-4">
                                <h6>Recent Transactions</h6>
                                <div className="d-flex ">
                                    <div className="me-3" style={{position:'relative',width:'150px'}}>
                                        <input type="search" className="form-control agentsearch me-3" placeholder="Search"></input>
                                        <p  style={{ position: 'absolute',marginLeft:'5px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={search01} alt="search" width='19.5px' height='19.5px'></img></p>
                                    </div>
                                    <div style={{width:'150px'}} className="me-3">
                                        <select className="agentselect " >
                                            <option>Filter</option>
                                        </select>
                                    </div>
                                    <div style={{width:'150px'}}>
                                        <button className="agentexport">Export</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                        <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table   nowrap custom-table custom-tables" style={{width:'100%'}}>
                                            <colgroup>
                                                <col style={{ width: '11.11%' }} /> {/* Adjust the width as needed */}
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                                <col style={{ width: '11.11%' }} />
                                            </colgroup>
                                                <thead style={{backgroundColor: '#E2FFF6'}}>
                                                    <tr style={{ border: 'none'}}>
                                                    <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' ,textAlign:'center'}}>Business Name</th>
                                                        <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000',textAlign:'center' }}>Transaction ID</th>
                                                        <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' ,textAlign:'center'}}>Method</th>
                                                        <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' ,textAlign:'center'}}>Amount</th>
                                                        <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000',textAlign:'center' }}>Charge</th>
                                                        <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000',textAlign:'center' }}>Date</th>
                                                        <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000' ,textAlign:'center'}}>Security check</th>
                                                        <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000',textAlign:'center' }}>Status</th>
                                                        <th style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#000000',textAlign:'center' }}></th>
                                                    </tr>
                                                </thead>
                                                 <tbody>
                                                 <p className="mar-25 padd-left right">Today</p>
                                                 <tr>
                                                    <td>
                                                    <div className="d-flex">
                                                        <img src={wallettowallet} className="me-2 rounded-circle"  width='24px' height="24px"></img>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Zane Barber</p>
                                                        </div>
                                                    </td>
                                                    <td className="agendtrans">TRAN2563563</td>
                                                    <td className="agendtrans">QR Code</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agenddate">01 Sep,2023</td>
                                                    <td className="desctabcheck">Passed</td>
                                                    <td className="agenddate">Sent</td>
                                                    <td ><button className="agenddetails" onClick={details}>Details</button></td>
                                                 </tr> 
                                                 <tr>
                                                    <td>
                                                    <div className="d-flex">
                                                        <img src={wallettowallet} className="me-2 rounded-circle"  width='24px' height="24px"></img>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Zane Barber</p>
                                                        </div>
                                                    </td>
                                                    <td className="agendtrans">TRAN2563563</td>
                                                    <td className="agendtrans">QR Code</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agenddate">01 Sep,2023</td>
                                                    <td className="desctabcheck desctabcheckred">Blocked</td>
                                                    <td className="agenddate">Cancelled</td>
                                                    <td ><button className="agenddetails">Details</button></td>
                                                 </tr> 
                                                 <tr>
                                                    <td>
                                                    <div className="d-flex">
                                                        <img src={wallettowallet} className="me-2 rounded-circle"  width='24px' height="24px"></img>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Zane Barber</p>
                                                        </div>
                                                    </td>
                                                    <td className="agendtrans">TRAN2563563</td>
                                                    <td className="agendtrans">QR Code</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agenddate">01 Sep,2023</td>
                                                    <td className="desctabcheck">Checking</td>
                                                    <td className="agenddate">Sent</td>
                                                    <td ><button className="agenddetails">Details</button></td>
                                                 </tr> 
                                                 <tr>
                                                    <td>
                                                    <div className="d-flex">
                                                        <img src={wallettowallet} className="me-2 rounded-circle"  width='24px' height="24px"></img>
                                                        <p className="desctabname" style={{marginBottom:'0px'}}>Zane Barber</p>
                                                        </div>
                                                    </td>
                                                    <td className="agendtrans">TRAN2563563</td>
                                                    <td className="agendtrans">QR Code</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agendtrans">UGX1000</td>
                                                    <td className="agenddate">01 Sep,2023</td>
                                                    <td className="desctabcheck desctabcheckred">Blocked</td>
                                                    <td className="agenddate">Sent</td>
                                                    <td ><button className="agenddetails">Details</button></td>
                                                 </tr>  
                                                 </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                         </div>
                        </div>
                        {/* </div>
                        </div> */}

                        <Modal show={show} dialogClassName="example-dialog23" contentClassName="example-content23" onHide={handleClose} >
                        <Modal.Header style={{backgroundColor:'#04D08E'}} >
                   
                   <Modal.Title style={{fontWeight:'600',fontSize:'20px',lineHeight:'30px',color:'#FFFFFF'}}>Details</Modal.Title>
                   <button className="custom-close-button" onClick={handleClose}>X</button>
                   </Modal.Header>
                <Modal.Body style={{ margin: '0', padding: '20px' }}>
                    
                    <div className="___row ">
                        <div className="col col_50">
                        <p className="agentpopname">Merchant Name</p>
                        <h6 className="agentpoph6"><img src={wallettowallet} className="me-3" alt="profile" width="28px" height='28px'></img>Agency</h6>
                         </div>
                         <div className="col col_50">
                         <p className="agentpopname">Merchant Name</p>
                         <h6 className="agentpoph6">W-356968996</h6>
                         </div>
                    </div>
                    <div className="row">
                                        <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table   nowrap custom-table custom-tables custom-tabless " style={{width:'100%'}}>
                                                <thead style={{backgroundColor: '#292929'}}>
                                                    <tr style={{ border: 'none'}}>
                                                    <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#FFFFFF' }}>No</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#FFFFFF' }}>Product name</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#FFFFFF' }}>Unit Cost</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#FFFFFF' }}>Qty</th>
                                                        <th style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#FFFFFF' }}>Total Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="agentpopno">1</td>
                                                    <td className="agentpopno">The Pink Villa, Gulu</td>
                                                    <td className="agentpopno">UGX 2500</td>
                                                    <td className="agentpopno">2</td>
                                                    <td className="agentpoptotal">UGX 5000</td>
                                                </tr>
                                                <tr>
                                                    <td className="agentpopno">2</td>
                                                    <td className="agentpopno">Cabin, Naminya</td>
                                                    <td className="agentpopno">UGX 1000</td>
                                                    <td className="agentpopno">3</td>
                                                    <td className="agentpoptotal">UGX 3000</td>
                                                </tr>
                                                <tr>
                                                    <td className="agentpopno">3</td>
                                                    <td className="agentpopno">Cabin, Naminya</td>
                                                    <td className="agentpopno">UGX 500</td>
                                                    <td className="agentpopno">5</td>
                                                    <td className="agentpoptotal">UGX 3000</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <hr></hr>
                                            <div className="d-flex justify-content-end text-end">
                                             <div className="text-end">
                                                <p className="agentpopend agentpopends">Sub total : </p>
                                                <p className="agentpopend agentpopends">Added tax : </p>
                                                <p className="agentpopend agentpopends">Added Charge : </p>
                                                <p className="agentpopendblack">Grand Total : </p>
                                             </div>
                                             <div className="text-end pe-5">
                                                <p className="agentpopend">10000 UGX</p>
                                                <p className="agentpopend">300 UGX</p>
                                                <p  className="agentpopend">200 UGX</p>
                                                <p className="agentpopendblue">10500 UGX</p>
                                             </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div> 
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Agentpay;