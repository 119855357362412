import React, { useCallback, useEffect, useState,memo } from 'react';
import { allfunctions, handleApiError } from '../../api/commonfunct.js/common';
import WithToast from '../../api/commonfunct.js/withtoast';
import Emergency from './emergency';
import Emergencylist from './emergencylist';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import { getkycemergencyapi } from '../../api/url';

const getkycemergencyapiurl = getkycemergencyapi();

const Emergencyseprate = memo(({ setActiveStep, activeStep, showErrorToast, setIsLoading4 })=> {
    const [getemergencydoc, setGetemergencydoc] = useState([]);
    const [showAddNew, setShowAddNew] = useState(false); // New state for adding emergency contact
    
    const getemergency = useCallback(async () => {
        try {
            const response = await axiosInstance.get(getkycemergencyapiurl);
            if (response.status === 200) {
                const emer = response.data.data;                
                setGetemergencydoc(emer);
            }
        } catch (error) {
            handleApiError(error, null, null, true, showErrorToast);
        }
    }, [showErrorToast]);
    
    useEffect(() => {
        allfunctions([getemergency])
        // getemergency();
    }, [allfunctions]);
    
    const handleAddNewClick = () => {
        setShowAddNew(true); // Show the Emergency component when adding new
    };
    
    const getNameProp = () => {
      if (showAddNew) {
          return 'stalin';
      }
      if (getemergencydoc.emergency_contacts && getemergencydoc.emergency_contacts.length === 0) {
        
          return 'stalin'; // Or any other logic to set name if needed
      }
      return ''; // Empty string when no emergency contacts are present
  };
  
    return (
        <>
            {showAddNew ? (
                <Emergency
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    setIsLoading4={setIsLoading4}
                    getemergencydocid={getemergencydoc.kyc_id}
                    getemergency={getemergency}
                    setShowAddNew={setShowAddNew}
                    name={getNameProp()}
                />
            ) : getemergencydoc.emergency_contacts && getemergencydoc.emergency_contacts.length > 0 ? (
                <Emergencylist
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    setIsLoading4={setIsLoading4}
                    getemergencydoc={getemergencydoc}
                    getemergencydocid={getemergencydoc.kyc_id}
                    getemergency={getemergency}
                    setShowAddNew={setShowAddNew}
                    onAddNewClick={handleAddNewClick} // Pass the handler to Emergencylist
                />
            ) : (
                <Emergency
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    setIsLoading4={setIsLoading4}
                    getemergencydocid={getemergencydoc.kyc_id}
                    getemergency={getemergency}
                    setShowAddNew={setShowAddNew}
                    name={getNameProp()}
                />
            )}
        </>
    );
})

export default WithToast(Emergencyseprate);
