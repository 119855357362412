// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import { createStore } from 'redux';
// import rootReducer from './api/reducers';
// const store = createStore(rootReducer);
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider, } from 'react-redux';
// import store from './api/store';


// ReactDOM.render(
//   <Provider store={store}>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </Provider>,
//   document.getElementById('root')
// );
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './api/redux/store';
import { ToastProvider } from './api/commonfunct.js/toastcontext';
import IpProvider from './api/commonfunct.js/ipContext';
document.addEventListener('DOMContentLoaded', () => {
  if (window.env) {
    window.env.BASE_URL = process.env.REACT_APP_BASE_URL;
  }
});
// Use createRoot instead of ReactDOM.render
const root = document.getElementById('root');
const rootInstance = createRoot(root);
rootInstance.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastProvider>
       <IpProvider> 
      <App />
      </IpProvider>
      </ToastProvider>
    </React.StrictMode>
   </Provider> 
);
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
