import {React,useState,useEffect} from "react";
import Navbars from "./navbar";
import Registerservices from "./registerservices";
import plus from './images/plus.png';
import Cards from "./cards";
import Paypal from "./paypal";
import Mobilemoney from "./mobilemoney";
import Bank from "./bank";
import Organization from "./organization";
import Applepay from "./applepay";
import Googlepay from "./googlepay";
function Addsources (){
    const [show10 , setShow10] = useState(false);
    // const [source, setSource] = useState('cards');
    const [source, setSource] = useState(() => {
        const storedActiveSection = localStorage.getItem('activeSection');
        return storedActiveSection || 'cards'; // Set the default section if none is found in localStorage
      });
      useEffect(() => {
        localStorage.setItem('activeSection', source);
      }, [source]);
    const addnewcard = () => {
        setShow10(true);
    }
    const handleCloses10 = () => {
        setShow10(false);
    }
    const closedialog10 = () => {
        setShow10(false);
    }
    const [show11 , setShow11] = useState(false);
    const handleCloses11 = () => {
        setShow11(false);
    }
    const closedialog11 = () => {
        setShow11(false);
    }
    return(
        <>
         <Navbars/>
        <div className="container-fluid addsourcesdiv" style={{backgroundColor:'#F8F8F8',width:'100%',minHeight:'100vh'}}>
            <div className="row collectionrow">
                <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                    <Registerservices setSource={setSource} source={source}/>
                </div>
                {source === "cards" && 
                <Cards />}
                {source === "Google Pay" &&
                 <Googlepay/>
                }
                {source === "Apple Pay" &&
                 <Applepay/>
                }
                {source === "paypal" &&
                <Paypal />}
                {source === "mobilemoney" &&
                <Mobilemoney />   
                }
                {source === "bank" &&
                <Bank />   
                }
                {source === "organization" &&
                <Organization />   
                }
            </div>
           </div>

        </>
    );
}
export default Addsources;