import React from "react";
import Navbars from "./navbar";
import Systemsidebar from "./systemsidebar";
import plus from './images/ApplePay.png';
import plumbermask from './images/Mask group.svg';
import product from './images/Mask group (1).svg';
import purchase from './images/Wallet-icons/Purchase.svg';
import masked from './images/Wallet-icons/Mask group.svg';
import masked1 from './images/Wallet-icons/Mask group (1).svg';
import { useNavigate } from "react-router-dom";
function Purchase() {
    const navigate = useNavigate();
    const orderdet = () => {
       navigate('/orderdetail')
    }
    
    return (
        <>
            {/* <Navbars />
            <div className="container-fluid " style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%' }}>
                <div className="row collectionrow">
                    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                        <Systemsidebar />
                    </div> */}
                    <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1 collectionmanual">
                        <main className="mobile_height" style={{ backgroundColor: '#FFFFFF', width: '100%', boxShadow: "0px 0px 5px 0px #0000001A", minHeight: '100vh',}}>
                        <div className="collectdiv">
                            <h5 className="ps-4 pt-4 collectionsh5">Purchases</h5>
                            <p className="cardsp ps-4 mb-4">This is where all your Purchased Products from online e commerce platforms like Marketplace, ShopNow,<br className="brhide" />
                                and Library are billed are recorded</p>
                                </div>
                            <div className="massage_box_corr massage_box_corr2 mobile_maxheight ">
                                <div className="purchaserow22">
                                    <div className=" border-btm1  purchaserow2">
                                        <div className="border-btm">
                                        <div className="d-flex align-items-center">
                                            <div className="col_70 col_70mobile">
                                                <ul className="d-flex pad-0">
                                                    <li className="hidepurchase1">
                                                        <div className="d-flex align-items-center">
                                                        <img src={purchase} alt="asda" className="me-3" width='32px' height='32px'></img>
                                                         <div className=" text-start" style={{ wordBreak: 'break-all' }}>
                                                         <p className="purchasep">Merchant:</p>
                                                        <h5 className="purchaseh5">EVzone Marketplace</h5></div> 
                                                        </div>
                                                    </li>
                                                    {/* <li  >
                                                    </li> */}
                                                    <li className="hidepurchase2">
                                                        <div className="d-flex align-items-center">
                                                        <img src={purchase} alt="asda" className="me-3" width='32px' height='32px'></img>
                                                         <div className=" text-start" style={{ wordBreak: 'break-all' }}>
                                                         <p className="purchasep ">Order Number:</p>
                                                        <h5 className="purchaseh5">63294645586 </h5></div> 
                                                        </div>
                                                    </li>
                                                    <li className=" text-start hidepurchase1" style={{ wordBreak: 'break-all' }}>
                                                        <p className="purchasep ">Order Number:</p>
                                                        <h5 className="purchaseh5">63294645586 </h5>
                                                    </li>
                                                    <li className=" text-start">
                                                        <p className="purchasep">Billed on:</p>
                                                        <h5 className="purchaseh5">29 Aug, 2023</h5>
                                                    </li>
                                                    <li className="text-start ">
                                                        <p className="purchasep">Paid on:</p>
                                                        <h5 className="purchaseh5">31 Aug, 2023</h5>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                            <div className="col_30 col_70mobile">
                                                <div className="pad-0">
                                                    <p className="purchasep ">Total Amount</p>
                                                    <h5 className="purchaseh5">UGX 3000</h5>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" mar-30">
                                    <div className="d-flex">
                                        <div className=" col_70">
                                            <div className="d-flex align-items-center">
                                                <div className="mobile_co_img">
                                                    <img src={masked} alt="plus" width='93px' height="70.67px"></img>
                                                </div>
                                                <div className="se-corr">
                                                    <h5 className="productnameh5">Tesla</h5>
                                                    <p className="productnamep mobile_co">Model Y 3 Energy High Performance
                                                        Long Range Electric Vehicle charger</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <div className="mobile_co_img">
                                                    <img src={masked1} alt="plus" width='93px' height="70.67px"></img>
                                                </div>
                                                <div className="se-corr">
                                                    <h5 className="productnameh5">Tesla</h5>
                                                    <p className="productnamep mobile_co">Model Y 3 Energy High Performance
                                                        Long Range Electric Vehicle charger</p>
                                                </div>
                                            </div>
                                            <div className="mar-30 col_302">
                                                <div className="d-flex align-items-center">
                                                <button className="_order me-3" onClick={orderdet}>Order Details</button>
                                                <button className="_grouppay">Group pay</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col_30 col_301" >
                                            <div className="text-end align-items-end d-flex flex-column">
                                            <button className="_order" onClick={orderdet}>Order Details</button>
                                            <button className="_download">Download Receipt</button>
                                            <button className="_grouppay">Group pay</button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="purchaserow22">
                                    <div className=" border-btm1  purchaserow2">
                                        <div className="border-btm">
                                        <div className="d-flex align-items-center">
                                            <div className=" col_70 ">
                                                <ul className="d-flex pad-0">
                                                <li className="hidepurchase1">
                                                        <div className="d-flex align-items-center">
                                                        <img src={purchase} alt="asda" className="me-3" width='32px' height='32px'></img>
                                                         <div className=" text-start" style={{ wordBreak: 'break-all' }}>
                                                         <p className="purchasep">Merchant:</p>
                                                        <h5 className="purchaseh5">EVzone Marketplace</h5></div> 
                                                        </div>
                                                    </li>
                                                    {/* <li  >
                                                    </li> */}
                                                    <li className="hidepurchase2">
                                                        <div className="d-flex align-items-center">
                                                        <img src={purchase} alt="asda" className="me-3" width='32px' height='32px'></img>
                                                         <div className=" text-start" style={{ wordBreak: 'break-all' }}>
                                                         <p className="purchasep ">Order Number:</p>
                                                        <h5 className="purchaseh5">63294645586 </h5></div> 
                                                        </div>
                                                    </li>
                                                    <li className=" text-start hidepurchase1" style={{ wordBreak: 'break-all' }}>
                                                        <p className="purchasep ">Order Number:</p>
                                                        <h5 className="purchaseh5">63294645586 </h5>
                                                    </li>
                                                    <li className=" text-start">
                                                        <p className="purchasep">Billed on:</p>
                                                        <h5 className="purchaseh5">29 Aug, 2023</h5>
                                                    </li>
                                                    <li className="text-start ">
                                                        <p className="purchasep">Paid on:</p>
                                                        <h5 className="purchaseh5">31 Aug, 2023</h5>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                            <div className="col_30 ">
                                                <div className="pad-0">
                                                    <p className="purchasep ">Total Amount</p>
                                                    <h5 className="purchaseh5">UGX 3000</h5>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" mar-30">
                                    <div className="d-flex">
                                        <div className=" col_70">
                                            <div className="d-flex align-items-center">
                                                <div className="mobile_co_img">
                                                    <img src={masked} alt="plus" width='93px' height="70.67px"></img>
                                                </div>
                                                <div className="se-corr">
                                                    <h5 className="productnameh5">Tesla</h5>
                                                    <p className="productnamep mobile_co">Model Y 3 Energy High Performance
                                                        Long Range Electric Vehicle charger</p>
                                                </div>
                                            </div>
                                            <div className="mar-30 col_302">
                                                <div className="d-flex align-items-center">
                                                <button className="_order me-3" onClick={orderdet}>Order Details</button>
                                                <button className="_grouppay">Group pay</button>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col_30 col_301" >
                                            <div className="text-end align-items-end d-flex flex-column">
                                            <button className="_order" onClick={orderdet}>Order Details</button>
                                            <button className="_download">Download Receipt</button>
                                            <button className="_grouppay">Group pay</button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="purchaserow22 purchaserow221">
                                    <div className=" border-btm1  purchaserow2">
                                        <div className="border-btm">
                                        <div className="d-flex align-items-center">
                                            <div className=" col_70 ">
                                                <ul className="d-flex pad-0">
                                                <li className="hidepurchase1">
                                                        <div className="d-flex align-items-center">
                                                        <img src={purchase} alt="asda" className="me-3" width='32px' height='32px'></img>
                                                         <div className=" text-start" style={{ wordBreak: 'break-all' }}>
                                                         <p className="purchasep">Merchant:</p>
                                                        <h5 className="purchaseh5">EVzone Marketplace</h5></div> 
                                                        </div>
                                                    </li>
                                                    {/* <li  >
                                                    </li> */}
                                                    <li className="hidepurchase2">
                                                        <div className="d-flex align-items-center">
                                                        <img src={purchase} alt="asda" className="me-3" width='32px' height='32px'></img>
                                                         <div className=" text-start" style={{ wordBreak: 'break-all' }}>
                                                         <p className="purchasep ">Order Number:</p>
                                                        <h5 className="purchaseh5">63294645586 </h5></div> 
                                                        </div>
                                                    </li>
                                                    <li className=" text-start hidepurchase1" style={{ wordBreak: 'break-all' }}>
                                                        <p className="purchasep ">Order Number:</p>
                                                        <h5 className="purchaseh5">63294645586 </h5>
                                                    </li>
                                                    <li className=" text-start">
                                                        <p className="purchasep">Billed on:</p>
                                                        <h5 className="purchaseh5">29 Aug, 2023</h5>
                                                    </li>
                                                    <li className="text-start ">
                                                        <p className="purchasep">Paid on:</p>
                                                        <h5 className="purchaseh5 red">Not Purchased</h5>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                            <div className="col_30 ">
                                                <div className="pad-0">
                                                    <p className="purchasep ">Total Amount</p>
                                                    <h5 className="purchaseh5">UGX 3000</h5>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" mar-30">
                                    <div className="d-flex">
                                        <div className=" col_70">
                                            <div className="d-flex align-items-center">
                                                <div className="mobile_co_img">
                                                    <img src={masked} alt="plus" width='93px' height="70.67px"></img>
                                                </div>
                                                <div className="se-corr">
                                                    <h5 className="productnameh5">Tesla</h5>
                                                    <p className="productnamep mobile_co">Model Y 3 Energy High Performance
                                                        Long Range Electric Vehicle charger</p>
                                                </div>
                                            </div>
                                            <div className="mar-30 col_302">
                                                <div className="d-flex align-items-center">
                                                <button className="_order redborder" onClick={orderdet}>Order Details</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col_30 col_301" >
                                            <div className=" d-flex flex-column align-items-end w-100">
                                            <button className="_order redborder" onClick={orderdet}>Order Details</button>
                                            {/* <button className="_download">Download Receipt</button>
                                            <button className="_grouppay">Group pay</button> */}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                {/* </div>
            </div> */}

        </>
    );
}
export default Purchase;