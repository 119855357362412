import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import close from "../images/times-circle-01.png";
import eye from '../images/eye-01 (1).png';
import eyeslash from '../images/Profile Setup/eye-slash-01.png';
import arrowleft from '../images/arrow-left-01.png';
import Navbars from "../navbar";
import { useNavigate } from "react-router-dom";
function Walletpassword(){
    const [show,setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [changepassshow,setChangepassshow] = useState(true);
    const [resetchangepassshow,setResetchangepassshow] = useState(false);
    const navigate = useNavigate();
  const [password,setPassword] = useState('');
    const handleClose = () => {
        setShow(false);
    } 
    const walletpasscodeclose = () => {
        setShow(false);
    }
    const handleInputChanges = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
    }
      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const back = () => {
        setShow(true);
    }
    const back1 = () => {
       navigate('/Wallet/profile');
    }
    const resetpasscode = () => {
      setChangepassshow(false);
      setResetchangepassshow(true);
    }
    const back2 = () => {
      setChangepassshow(true);
      setResetchangepassshow(false);
    }
    return(
        <>
        <Navbars/> 
        <div className="container-fluid walletpassword-div">
            <div className="wallet-passswordsdiv">
                  <div className="wallet-passswordsdiv1">
                    {changepassshow &&(
                      <>
                       <div className="d-flex align-items-center p-4">
                      <img src={arrowleft} onClick={back1} alt="arrowleft" className="passswordsdiv1-arrow me-4"></img>
                      <h5 className="wallet-passwordh5">Change Passcode</h5>
                    </div>
                    <div className="wallet-passwordinputpad">
                    <div className="mt-0">
                      <label className="walletprofile-label">
                      Old Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                    </div>
                    <div className="mt-3">
                      <label className="walletprofile-label">
                    Set Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                    </div>
                    <div className="mt-3 mb-4">
                      <label className="walletprofile-label">
                      Confirm Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <button className="wallet-passcode-button me-3">Cancel</button>
                      <button className="wallet-passcode-button-sub">Submit</button>
                    </div>
                    <hr></hr>
                    <p className="wallet-forgotpasscode">Forgot Passcode? <strong style={{textDecoration:'underline',color:'#EA4335',cursor:'pointer'}} onClick={resetpasscode}>Reset</strong></p>
                    </div>
                      </>
                    )}
                    {resetchangepassshow &&(
                     <> 
                    <div className="d-flex align-items-center p-4">
                      <img src={arrowleft} onClick={back2} alt="arrowleft" className="passswordsdiv1-arrow me-4"></img>
                      <h5 className="wallet-passwordh5">Reset Passcode</h5>
                    </div>
                    <div className="wallet-passwordinputpad">
                    <div className="mt-0">
                      <label className="walletprofile-label">
                      Enter app password
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                    </div>
                    <div className="mt-3">
                      <label className="walletprofile-label">
                      Confirm Wallet MFA
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                    </div>
                    <div className="mt-3">
                      <label className="walletprofile-label">
                    Set Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                    </div>
                    <div className="mt-3 mb-4">
                      <label className="walletprofile-label">
                      Confirm Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <button className="wallet-passcode-button me-3">Cancel</button>
                      <button className="wallet-passcode-button-sub">Submit</button>
                    </div>
                    </div>
                    </>
                    )}
                  </div>
            </div>
        </div>
            <Modal show={show} dialogClassName="example-walletprofiledialog" contentClassName="example-walletcontent1" onHide={handleClose} centered>
                <Modal.Body style={{ margin: '0', padding: '0' }}>
                      <h5 className="walletpasscodeh5">Passcode</h5>
                      <div className="mt-3">
                      <label className="walletprofile-label">
                    Set Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                                </div>
                                <div className="mt-3">
                      <label className="walletprofile-label">
                    Confirm Passcode
                    </label>
                      <div style={{ position: "relative" }}>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control mt-1 walletprofile-input" id="userpassword" style={{ position: "relative", border: '1px solid #838383', height: '46px' }}
                                         name="password" value={password} onChange={handleInputChanges}  />
                                    <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                        {showPassword ? (
                                            <img src={eye} className='eyeslash' alt="Hide Password" />
                                        ) : (
                                            <img src={eyeslash} className='eyeslash' alt="Show Password" />
                                        )}
                                    </p>
                                </div>
                                </div>
                     
                  <div className="walletprofile-buttons mt-3">
                    {/* <button className="walletprofile-save me-3">Save</button> */}
                    <button
                      className=" walletprofile-cancel me-3"
                      onClick={walletpasscodeclose}
                    >
                      Cancel
                    </button>
                    <button
                      className="walletprofile-next"
                      // disabled={activeStep === 0}
                      onClick={walletpasscodeclose}
                    >
                      Submit
                    </button>
                  </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Walletpassword;